/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { bool } from 'macro-decorators';

import { CARD_FLOWS_TRACKING_ORIGINS } from 'qonto/constants/cards';
import { INVITATION_TYPES, ROLES } from 'qonto/constants/membership';
import { REQUEST_ORIGINS } from 'qonto/constants/requests';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

const UPSELL_TRIGGERS = {
  [ROLES.MANAGER]: 'managerRole',
  [ROLES.REPORTING]: 'accountantAccess',
};

export class MemberFlowDataContext {
  isEditingMember = false;
  isGbRPartner = false;
  isGSAInviteTeamMember = false;
  invitationType = INVITATION_TYPES.STAFF;
  isReimbursementsOrigin = false;
  shouldDisplayCardCreationSuccess = false;

  @tracked member;
  @tracked role;
  @tracked showComparison = true;

  get upsellTrigger() {
    return UPSELL_TRIGGERS[this.member.role];
  }
}

export default class MemberInviteFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service store;
  @service subscriptionManager;
  @service toastFlashMessages;
  @service segment;
  @service flow;

  @tracked dataContext;

  @bool('organizationManager.organization.isGbR') isGbR;

  constructor() {
    super(...arguments);

    this.dataContext = new MemberFlowDataContext();
  }

  async loadMember() {
    let { memberId, suggested } = this.router.currentRoute.queryParams;
    let { organization } = this.organizationManager;

    if (memberId) {
      let schema = suggested ? 'suggested-invitation' : 'membership';
      let membership = await this.store.findRecord(schema, memberId);
      return this.store.createRecord('invite', {
        organization,
        team: this.team,
        firstName: membership.firstName,
        lastName: membership.lastName,
        email: membership.email,
        birthdate: membership.birthdate,
        recipientId: membership.get('id'),
        suggested: true,
      });
    }

    return this.store.createRecord('invite', {
      organization,
      team: this.team,
    });
  }

  get isManager() {
    return this.organizationManager.membership.role === ROLES.MANAGER;
  }

  get hasReachedUserLimit() {
    return this.subscriptionManager.hasReachedUserLimit;
  }

  get defaultMemberRoleCode() {
    if (this.dataContext.invitationType === INVITATION_TYPES.GUEST) {
      return ROLES.REPORTING;
    }

    if (this.isGbR) {
      return ROLES.ADMIN;
    }

    if (this.isManager) {
      return ROLES.EMPLOYEE;
    }

    if (this.hasReachedUserLimit && this.abilities.can('access accountant-access')) {
      return ROLES.REPORTING;
    }
  }

  get team() {
    return this.abilities.cannot('update team membership')
      ? this.organizationManager.membership.team
      : null;
  }

  get initialStepId() {
    if (
      (this.subscriptionManager.hasReachedUserLimit &&
        this.abilities.cannot('create paid members members') &&
        !this.isManager) ||
      this.subscriptionManager.planUpgradeIsNeeded
    ) {
      return 'plan-upgrade';
    }

    if (this.isManager) {
      return 'personal-info';
    }

    return 'role';
  }

  async beforeFlow({ stepId }) {
    let { organization, membership } = this.organizationManager;

    if (membership.shouldSubmitKyc && organization.kybPending) {
      return this.router.replaceWith('kyc.intro', {
        queryParams: {
          redirectRoute: stepId === 'role' ? 'members.active' : 'guests',
        },
      });
    }
    if (this.abilities.cannot('create invite')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (
      this.router.currentRoute.queryParams.invitationType === INVITATION_TYPES.GUEST ||
      this.flow.dataContext.isAccountingDiscoveryFlow
    ) {
      if (this.abilities.cannot('access accountant-access')) {
        return this.router.replaceWith('guests.upgrade-plan');
      }

      this.dataContext.invitationType = INVITATION_TYPES.GUEST;
    }

    switch (this.router.currentRoute.queryParams.origin) {
      case 'gsa-invite-team-member':
        this.dataContext.isGSAInviteTeamMember = true;
        break;
      case REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE:
        this.dataContext.isReimbursementsOrigin = true;
        break;
    }

    let { isGSAInviteTeamMember, invitationType } = this.dataContext;
    let hasCreateCardPermission = this.abilities.can('access creation flow card');

    if (
      !isGSAInviteTeamMember &&
      hasCreateCardPermission &&
      invitationType !== INVITATION_TYPES.GUEST
    ) {
      this.dataContext.shouldDisplayCardCreationSuccess = true;
    }

    this.dataContext.member = await this.loadMember();
    this.dataContext.member.role = this.defaultMemberRoleCode;

    if (this.router.currentRoute.queryParams.role) {
      let roles = {
        [ROLES.EMPLOYEE]: { key: ROLES.EMPLOYEE, name: this.intl.t('roles.employee') },
        [ROLES.REPORTING]: { key: ROLES.REPORTING, name: this.intl.t('roles.reporting') },
        [ROLES.MANAGER]: { key: ROLES.MANAGER, name: this.intl.t('roles.manager') },
        [ROLES.ADMIN]: { key: ROLES.ADMIN, name: this.intl.t('roles.admin') },
      };

      let role = roles[this.router.currentRoute.queryParams.role] || roles[ROLES.EMPLOYEE];
      this.dataContext.member.role = role.key;
      this.dataContext.role = role;
    }

    Sentry.getCurrentScope().setTag('CFT', 'spend-management');
  }

  @action
  onComplete(dataContext) {
    if (dataContext.invitationType === INVITATION_TYPES.GUEST) {
      this.router.transitionTo('guests.guest', dataContext.member.recipientId);
    } else {
      this.router.transitionTo('members.active.member', dataContext.member.recipientId);
    }
  }

  onAbortTask = dropTask(async ({ member }) => {
    let { firstName, lastName, email, team } = member;

    if (!(firstName || lastName || email || team.content)) {
      this.transitionToInitiatorPage();
      return true;
    }
    let result = await this.openAbortModalTask.perform();
    return result === 'confirm';
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('members.invite.modals.abort.title'),
      description: this.intl.t('members.invite.modals.abort.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('invitation-flow.button-cancel-confirm'),
      confirmTask: this.modalConfirmTask,
    });
  });

  modalConfirmTask = dropTask(async close => {
    this.segment.track('cancel-modal_cancel_clicked', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION,
    });

    this.transitionToInitiatorPage();
    await close();
  });

  beforeRestoreTask = dropTask(async ({ member }) => {
    let { id: memberId } = member;

    if (memberId) {
      await this.store.findRecord('invite', memberId);
    }
  });

  transitionToInitiatorPage() {
    if (this.dataContext.isReimbursementsOrigin) {
      return this.router.transitionTo('reimbursements');
    } else if (this.dataContext.isGSAInviteTeamMember) {
      return this.homePage.replaceWithDefaultPage();
    } else if (this.dataContext.invitationType === INVITATION_TYPES.GUEST) {
      return this.router.transitionTo('guests');
    } else {
      return this.router.transitionTo('members');
    }
  }

  @action
  onSuccessToast() {
    this.toastFlashMessages.toastSuccess(
      this.intl.t('team-budgets.creation.assign-manager.toast.success')
    );
  }
}
