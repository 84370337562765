import { accountingDiscovery } from 'qonto/routes/flows/descriptions/accounting-discovery';
import { externalAccountImport } from 'qonto/routes/flows/descriptions/accounts/external/import';
import { remuneratedAccountCreation } from 'qonto/routes/flows/descriptions/accounts/remunerated/creation';
import { remuneratedAccountFunding } from 'qonto/routes/flows/descriptions/accounts/remunerated/funding';
import { approvalWorkflows } from 'qonto/routes/flows/descriptions/approval-workflows/approval-workflows';
import { createBudget } from 'qonto/routes/flows/descriptions/budgets/create-budget';
import { editBudget } from 'qonto/routes/flows/descriptions/budgets/edit-budget';
import { cardSetPinKYC } from 'qonto/routes/flows/descriptions/cards/kyc/set-pin';
import { virtualCardProvideKYC } from 'qonto/routes/flows/descriptions/cards/kyc/virtual-card';
import { cardNewDigital } from 'qonto/routes/flows/descriptions/cards/new/digital';
import { cardNewAdvertising } from 'qonto/routes/flows/descriptions/cards/new/digital-secondary/advertising';
import { cardNewFlash } from 'qonto/routes/flows/descriptions/cards/new/digital-secondary/flash';
import { cardNewVirtual } from 'qonto/routes/flows/descriptions/cards/new/digital-secondary/virtual';
import { cardNewInvitee } from 'qonto/routes/flows/descriptions/cards/new/invitee';
import { cardNewInviteePhysicalOne } from 'qonto/routes/flows/descriptions/cards/new/invitee-physical-one';
import { cardNewInviteeVirtual } from 'qonto/routes/flows/descriptions/cards/new/invitee-virtual';
import { cardNewPhysical } from 'qonto/routes/flows/descriptions/cards/new/physical';
import { cardOnboarding } from 'qonto/routes/flows/descriptions/cards/onboarding';
import { cardResetPin } from 'qonto/routes/flows/descriptions/cards/pin/reset';
import { cardSetPin } from 'qonto/routes/flows/descriptions/cards/pin/set';
import { cardRenewPhysical } from 'qonto/routes/flows/descriptions/cards/renew/physical';
import { cardUpsell } from 'qonto/routes/flows/descriptions/cards/upsell';
import { chargebackClaim } from 'qonto/routes/flows/descriptions/chargeback-claim';
import { check } from 'qonto/routes/flows/descriptions/check';
import { concierge } from 'qonto/routes/flows/descriptions/concierge';
import { connectApplicationsActivation } from 'qonto/routes/flows/descriptions/connect/applications/activation';
import { createTeam } from 'qonto/routes/flows/descriptions/create-team';
import { directDebitCollectionClient } from 'qonto/routes/flows/descriptions/direct-debit-collections/client';
import { directDebitSubscription } from 'qonto/routes/flows/descriptions/direct-debit-collections/subscription';
import { f24ManualDeclaration } from 'qonto/routes/flows/descriptions/f24-manual-declaration';
import { payLaterApplication } from 'qonto/routes/flows/descriptions/financing/pay-later/application';
import { invoice } from 'qonto/routes/flows/descriptions/invoice';
import { managementKyc } from 'qonto/routes/flows/descriptions/management-kyc';
import { mandate } from 'qonto/routes/flows/descriptions/mandate';
import { matchInvoice } from 'qonto/routes/flows/descriptions/match-invoice';
import { memberEdit } from 'qonto/routes/flows/descriptions/member/edit';
import { memberEditAccountPermissions } from 'qonto/routes/flows/descriptions/member/edit-account-permissions';
import { memberEditExpensePermissions } from 'qonto/routes/flows/descriptions/member/edit-expense-permissions';
import { memberEditOtherPermissions } from 'qonto/routes/flows/descriptions/member/edit-other-permissions';
import { memberEditRole } from 'qonto/routes/flows/descriptions/member/edit-role';
import { memberEditScope } from 'qonto/routes/flows/descriptions/member/edit-scope';
import { memberInvite } from 'qonto/routes/flows/descriptions/member/invite';
import { pagopa } from 'qonto/routes/flows/descriptions/pagopa';
import { paymentActivation } from 'qonto/routes/flows/descriptions/payment-activation';
import { profileKYC } from 'qonto/routes/flows/descriptions/profile-kyc';
import { requestExpenseReport } from 'qonto/routes/flows/descriptions/request/expense-report';
import { requestMileage } from 'qonto/routes/flows/descriptions/request/mileage';
import { requestsCards } from 'qonto/routes/flows/descriptions/requests/cards';
import { virtualCardKyc } from 'qonto/routes/flows/descriptions/requests/virtual-card-kyc';
import { sddActivation } from 'qonto/routes/flows/descriptions/sdd-activation';
import { subscription } from 'qonto/routes/flows/descriptions/subscription';
import { addon } from 'qonto/routes/flows/descriptions/subscription-addon';
import { subscriptionClose } from 'qonto/routes/flows/descriptions/subscription/close';
import { fxTransferBeneficiary } from 'qonto/routes/flows/descriptions/transfers/fx/beneficiary';
import { fxTransfer } from 'qonto/routes/flows/descriptions/transfers/fx/new';
import { internationalOutBeneficiary } from 'qonto/routes/flows/descriptions/transfers/international-out/beneficiary';
import { internationalOut } from 'qonto/routes/flows/descriptions/transfers/international-out/new';
import { sepaTransferBeneficiary } from 'qonto/routes/flows/descriptions/transfers/sepa/beneficiary';
import { editSepaTransfer } from 'qonto/routes/flows/descriptions/transfers/sepa/edit';
import { sepaTransfer } from 'qonto/routes/flows/descriptions/transfers/sepa/new';
import { payLaterTransfer } from 'qonto/routes/flows/descriptions/transfers/sepa/pay-later';

export const flowDescription = {
  'addon-change': addon,
  'card-new-advertising': cardNewAdvertising,
  'card-new-digital': cardNewDigital,
  'card-new-flash': cardNewFlash,
  'card-new-invitee-physical-one': cardNewInviteePhysicalOne,
  'card-new-invitee-virtual': cardNewInviteeVirtual,
  'card-new-invitee': cardNewInvitee,
  'card-new-physical': cardNewPhysical,
  'card-new-virtual': cardNewVirtual,
  'card-reset-pin': cardResetPin,
  'card-renew-physical': cardRenewPhysical,
  'card-onboarding': cardOnboarding,
  'card-set-pin': cardSetPin,
  'card-set-pin-kyc': cardSetPinKYC,
  'card-upsell': cardUpsell,
  check,
  'chargeback-claim': chargebackClaim,
  concierge,
  'remunerated-account-creation': remuneratedAccountCreation,
  'remunerated-account-funding': remuneratedAccountFunding,
  'member-invite': memberInvite,
  'member-edit': memberEdit,
  'member-edit-account-permissions': memberEditAccountPermissions,
  'member-edit-expense-permissions': memberEditExpensePermissions,
  'member-edit-role': memberEditRole,
  'member-edit-other-permissions': memberEditOtherPermissions,
  'member-edit-scope': memberEditScope,
  'requests-cards': requestsCards,
  'requests-virtual-card-kyc': virtualCardKyc,
  'invoice-onboarding': invoice,
  'management-kyc': managementKyc,
  'subscription-change': subscription,
  'subscription-close': subscriptionClose,
  pagopa,
  'f24-manual-declaration': f24ManualDeclaration,
  mandate,
  'match-invoice': matchInvoice,
  'request-expense-report': requestExpenseReport,
  'request-mileage': requestMileage,
  'profile-kyc': profileKYC,
  'virtual-card-provide-kyc': virtualCardProvideKYC,
  'international-out': internationalOut,
  'international-out-beneficiary': internationalOutBeneficiary,
  'fx-transfer': fxTransfer,
  'fx-transfer-beneficiary': fxTransferBeneficiary,
  'sepa-transfer': sepaTransfer,
  'sepa-transfer-beneficiary': sepaTransferBeneficiary,
  'create-team': createTeam,
  'edit-sepa-transfer': editSepaTransfer,
  'create-budget': createBudget,
  'edit-budget': editBudget,
  'pay-later-application': payLaterApplication,
  'payment-activation': paymentActivation,
  'sdd-activation': sddActivation,
  'external-account-import': externalAccountImport,
  'direct-debit-subscription': directDebitSubscription,
  'direct-debit-collection-client': directDebitCollectionClient,
  'pay-later-transfer': payLaterTransfer,
  'approval-workflows': approvalWorkflows,
  'connect-applications-activation': connectApplicationsActivation,
  'accounting-discovery': accountingDiscovery,
};
