export default {
  "mt-16": "qGR",
  "title": "qGQ",
  "avatar": "qGe",
  "edit-organization-form": "qGB",
  "dropdown-content": "qGE",
  "city-block": "qGd",
  "zip-code-input": "qGI",
  "cancel-button": "qGt",
  "error-message": "qGM",
  "nature-of-operations": "qGP",
  "error": "qGl",
  "legal-code": "qGX",
  "activity-type": "qGC"
};
