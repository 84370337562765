export default {
  "page-wrapper": "qOv",
  "container": "qOo",
  "sidebar": "qOn",
  "search-input": "qOi",
  "organizations-list": "qOA",
  "content": "qOY",
  "no-result": "qOh",
  "lottie-illustration": "qOf",
  "accounting-header": "qOK",
  "logo": "qOG",
  "accounting-title": "qOr"
};
