import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { LottiePlayer } from 'design-system-kit';

export default class KycIntroController extends Controller {
  lottiePlayer = LottiePlayer;

  @service router;

  queryParams = ['redirectRoute'];

  @action
  handleOnClose() {
    return this.router.transitionTo(this.model.redirectRoute);
  }
}
