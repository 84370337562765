import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class MandatesListRoute extends Route {
  @service abilities;
  @service homePage;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;

  queryParams = {
    bankAccounts: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
    per_page: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (this.abilities.cannot('view mandate')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  model(params) {
    this.fetchDataTask.perform(params, false).catch(ignoreCancelation);
    let { fetchAllMandatesTask } = this.modelFor('mandates');

    return {
      fetchMandatesTask: this.fetchDataTask,
      fetchAllMandatesTask,
    };
  }

  fetchDataTask = restartableTask(async (params, debounced = true) => {
    if (debounced) {
      await timeout(DEBOUNCE_MS);
    }
    let data = await this.fetchTask.perform(params);

    this._afterModel(data);
    return data;
  });

  fetchTask = dropTask(async ({ bankAccounts, ...params }) => {
    let filters;
    if (bankAccounts) {
      filters = { bank_account_ids: bankAccounts.split(',') };
    }

    try {
      let mandates = await this.store.query('mandate', {
        organization_id: this.organizationManager.organization.id,
        filters,
        ...filterParams(params),
      });

      return mandates;
    } catch (error) {
      // no reason to show a toast since we're redirecting in the afterModel hook
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(
          new Error('failed to load all mandates on the mandate.index route', {
            cause: error,
          })
        );
      }
    }
  });

  /**
   * Select the first mandate by default
   * @param {*} mandates
   */
  _afterModel(mandates) {
    let { query, bankAccounts } = this.paramsFor('mandates.list');
    let { mandate_id } = this.paramsFor('mandates.list.mandate');
    if (mandates?.length > 0) {
      if (!mandate_id || !mandates.some(({ id }) => id === mandate_id)) {
        let mandatesSortedByStatus = [...mandates].sort((a, b) => b.isActive - a.isActive);
        this.router.replaceWith('mandates.list.mandate', mandatesSortedByStatus[0].id);
      }
    } else if (!query && !variation('feature--boolean-empty-state-revamp')) {
      this.router.replaceWith('mandates.landing', {
        queryParams: { 'bank-accounts': bankAccounts },
      });
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetQueryParams();
    }
  }
}
