import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class BankAccountsDetailsRoute extends Route {
  @service router;
  @service segment;

  redirect(model, transition) {
    this.segment.track('redirected_from_bank-accounts.details_to_account.details', {
      message:
        'Members: User get redirected from old url /bank-accounts/details to new /accounts/details one',
    });

    let { params } = transition.to;
    this.router.replaceWith('accounts.details', params.slug);
  }
}
