import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { LottiePlayer } from 'design-system-kit';

export default class PasswordsForgotController extends Controller {
  lottiePlayer = LottiePlayer;

  @service userManager;
  @service deviceManager;

  animationReady = false;

  @action
  handleForgotPassword(email) {
    return this.userManager.forgotPassword(email);
  }
}
