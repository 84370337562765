export default {
  "member-details": "qvF",
  "header": "qvg",
  "header-img": "qvD",
  "invited-persona": "qvV",
  "revoked-persona": "qvL",
  "invited-icon": "qvT",
  "revoked-icon": "qvz",
  "dropdown": "qva",
  "header-body": "qvH",
  "name": "qvO title-3",
  "email": "qvm caption-bold",
  "actions": "qvp body-2",
  "actions-cards": "qvx",
  "actions-transactions": "qvw",
  "body": "qvN",
  "expense-permissions-section": "qvv",
  "body-title": "qvo title-4",
  "body-attr": "qvn",
  "body-label": "qvi",
  "attr-buttons": "qvA",
  "body-role": "qvY small",
  "details-actions": "qvh",
  "actions-item": "qvf",
  "disclaimer": "qvK",
  "permissions": "qvG",
  "permission": "qvr",
  "permission-label": "qvb",
  "permission-check": "qvy",
  "permission-missing": "qvU",
  "editable-section": "qvj"
};
