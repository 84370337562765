export default {
  "container": "qNi",
  "page-wrapper": "qNA",
  "header": "qNY",
  "back-button-wrapper": "qNh",
  "header-inner": "qNf",
  "header-main-wrapper": "qNK",
  "header-main": "qNG",
  "header-right": "qNr",
  "content-wrapper": "qNb",
  "content": "qNy",
  "content-body": "qNU",
  "sidebar": "qNj"
};
