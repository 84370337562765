export default {
  "page-wrapper": "qxl",
  "back-button": "qxX",
  "abort-button": "qxC",
  "container": "qxk",
  "subtitle": "qxJ",
  "button": "qxs",
  "panel-list": "qxF",
  "panel-list-item": "qxg",
  "card-illustration": "qxD"
};
