export default {
  "wrapper": "qbQ",
  "integrations": "qbe",
  "description": "qbB body-2",
  "api": "qbE flex flex-column",
  "api-container": "qbd flex large",
  "api-input": "qbI large",
  "api-btn": "qbt",
  "key-input": "qbM"
};
