import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/reimbursements';
import { REQUEST_ORIGINS } from 'qonto/constants/requests';
import { ErrorInfo } from 'qonto/utils/error-info';

const DEFAULT_SORT_BY = 'created_at:desc';

export default class ReimbursementsRequestsController extends Controller {
  @service intl;
  @service sentry;
  @service organizationManager;
  @service router;
  @service abilities;
  @service segment;
  @service flowLinkManager;
  @service emptyStates;
  @service subscriptionManager;

  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked sort_by = DEFAULT_SORT_BY;

  get currentParams() {
    return {
      page: this.page,
      per_page: this.per_page,
      meta: this.meta,
      sort_by: this.sort_by,
    };
  }

  REIMBURSEMENTS_ORIGIN = REQUEST_ORIGINS.reimbursements;

  get emptyStateOptions() {
    if (
      this.model.fetchDataTask.isRunning ||
      this.model.fetchDataTask.last?.isError ||
      !variation('feature--boolean-empty-state-revamp')
    ) {
      return;
    }

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isTableEmpty,
      isEmptyLocally: this.isTableEmpty,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl),
      customInputs: {
        hasTeamManagement: this.subscriptionManager.hasFeature('team_management'),
      },
      abilities: {
        canCreateExpenseReport: this.abilities.can('create expense report request'),
      },
    });
  }

  get requestsData() {
    return this.model.requests ?? [];
  }

  get isTableEmpty() {
    return this.requestsData.length === 0;
  }

  get displayRequestLandingButton() {
    return this.abilities.can('create mileage requests') && !this.isTableEmpty;
  }

  get displayRequestExpenseReportButton() {
    return this.abilities.can('create expense report requests') && !this.isTableEmpty;
  }

  get localState() {
    let { isRunning, last } = this.model.fetchDataTask;

    if (isRunning) {
      return {
        isLoading: true,
        error: false,
        empty: false,
      };
    }

    if (last.isError) {
      return {
        isLoading: false,
        error: true,
        empty: false,
      };
    }

    if (this.isTableEmpty) {
      return {
        isLoading: false,
        error: false,
        empty: true,
      };
    }
  }

  get emptyRequesterStateButtonCallback() {
    let queryParams = {
      organizationSlug: this.organizationManager.organization.slug,
      origin: this.REIMBURSEMENTS_ORIGIN,
    };

    let transitionToLanding = () => {
      this.router.transitionTo('requests.landing', { queryParams });
    };

    let transitionToExpenseReportsFlow = () => {
      this.flowLinkManager.transitionTo({
        name: 'request-expense-report',
        stepId: 'receipt',
        queryParams,
      });
    };

    return this.abilities.can('create mileage request')
      ? transitionToLanding
      : transitionToExpenseReportsFlow;
  }

  resetQueryParams() {
    this.highlight = '';
    this.page = 1;
    this.per_page = 25;
    this.sort_by = DEFAULT_SORT_BY;
  }

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  @action
  trackCTA(origin) {
    this.emptyStates.trackCta(this.emptyStateOptions, origin);
  }

  handleSelectRequestTask = restartableTask(async requestId => {
    let { role } = this.organizationManager.membership;

    let request = this.requestsData.find(({ id }) => id === requestId);

    this.segment.track('request_details_opened', {
      request_type: request.requestType,
      request_id: request.id,
      request_status: request.status,
      origin: 'reimbursements',
      role,
    });

    await this.model.fetchAllocatedBudgetTask.perform(request);

    this.highlight = request.id;
  });

  @action
  handleCloseSidePanel() {
    this.resetQueryParams(['highlight']);
    return this.model.fetchDataTask
      .perform({})
      .then(() => this.notifierCounterManager.updateCounter())
      .catch(() => {
        // ignore error
      });
  }

  handleSortBy = restartableTask(async sortDefinition => {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;

    await this.model.fetchDataTask.perform(this.sort_by).catch(error => {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });
  });
}
