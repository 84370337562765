export const fxTransfer = {
  steps: {
    beneficiaries: {
      componentName: 'transfers/fx/new/beneficiaries',
      nextStepId: 'details',
    },
    details: {
      componentName: 'transfers/fx/new/details',
      nextStepId: ({ isMarketClosed }) =>
        isMarketClosed ? 'market-closed' : 'additional-settings',
    },
    'additional-settings': {
      componentName: 'transfers/fx/new/additional-settings',
      nextStepId: 'summary',
    },
    summary: {
      componentName: 'transfers/fx/new/summary',
      nextStepId: ({ kycSubmitted }) => {
        if (!kycSubmitted) {
          return 'kyc-intro';
        }
        return 'success';
      },
    },
    'kyc-intro': {
      componentName: 'transfers/kyc/transition',
    },
    success: {
      componentName: 'transfers/fx/new/success',
    },
    'market-closed': {
      componentName: 'transfers/fx/new/market-closed',
    },
  },
  options: {
    enablePersistence: true,
  },
};
