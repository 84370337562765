export default {
  "header": "qOg",
  "btn-preset": "qOD btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qOV",
  "statements-table": "qOL",
  "no-result": "qOT",
  "lottie-illustration": "qOz",
  "wrapper": "qOa",
  "access-denied": "qOH",
  "access-denied-illustration": "qOO",
  "application-card": "qOm",
  "presets-wrapper": "qOp",
  "error-wrapper": "qOx",
  "error-container": "qOw",
  "error-illustration": "qON mb-32"
};
