import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { Spinner } from 'design-system-kit';
import { dropTask, task } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { apiBaseURL } from 'qonto/constants/hosts';
import { STATUS } from 'qonto/constants/invoice-subscriptions';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class InvoiceSubscriptionsSddSetupController extends Controller {
  spinner = Spinner;

  @service modals;
  @service intl;
  @service router;
  @service sentry;
  @service networkManager;
  @service toastFlashMessages;
  @service store;
  @service segment;

  queryParams = ['reactivate'];

  reactivate = false;
  @tracked reference = '';
  @tracked mandateOptionSelected = this.hasMandates;
  @tracked selectedMandate = this.mandates?.[0];
  @tracked referenceError = '';

  get invoiceSubscription() {
    return this.model.invoiceSubscription;
  }

  get isLoading() {
    return this.model.mandatesTask?.isRunning;
  }

  get amount() {
    return {
      value: this.invoiceSubscription?.amountDue || '0.00',
      currency: this.invoiceSubscription?.currency || 'EUR',
    };
  }

  get dueDate() {
    return dayjs(this.invoiceSubscription?.startDate || new Date())
      .add(this.invoiceSubscription?.paymentTermsDays || 0, 'day')
      .format(DATE_PICKER_FIELD_FORMAT);
  }

  get mandates() {
    return this.model.mandatesTask?.lastSuccessful?.value;
  }

  get hasMandates() {
    return this.mandates?.length > 0;
  }

  onSubmitFormTask = dropTask(async () => {
    let { reference } = this;
    if (!reference) {
      this.referenceError = this.intl.t('validations.errors.blank');
      return;
    }

    this.referenceError = '';

    try {
      let body = {
        mandate: null,
        reference,
      };
      if (this.mandateOptionSelected) {
        body.mandate = {
          id: this.selectedMandate.id,
          unique_mandate_reference: this.selectedMandate.uniqueMandateReference,
          creditor_identifier: this.selectedMandate.clientSnapshot.id,
        };
      }

      let updatedSubscription = await this.networkManager.request(
        `${apiBaseURL}/v4/receivable_invoices/subscriptions/${this.invoiceSubscription.id}/setup_direct_debit`,
        {
          method: 'POST',
          data: JSON.stringify(body),
        }
      );

      this.segment.track('recurring-invoices_sdd-subscription-creation_confirmed');
      this.store.pushPayload('invoice-subscription', updatedSubscription);

      if (this.reactivate && this.invoiceSubscription.status === STATUS.SUSPENDED) {
        let subscription = this.store.peekRecord(
          'invoice-subscription',
          this.invoiceSubscription.id
        );
        subscription.reactivate();
      }

      if (body.mandate) {
        this.toastFlashMessages.toastSuccess(
          this.intl.t('recurring-invoices.sdd-setup.success-toast')
        );
        return this.router.transitionTo('invoice-subscriptions.index');
      }
      return this.router.transitionTo(
        'invoice-subscriptions.sdd.payment-link',
        this.invoiceSubscription.id
      );
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
    }
  });

  @action selectMandate(mandate) {
    this.selectedMandate = mandate;
  }

  @action onChangeMandateSelect(value) {
    this.mandateOptionSelected = value;
  }

  @action onCloseBtnClick() {
    this.modals.open('popup/confirmation', {
      title: this.intl.t('recurring-invoices.sdd-setup.abort-popup.title'),
      description: this.intl.t('recurring-invoices.sdd-setup.abort-popup.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
      confirmTask: this.abortTask,
    });
  }

  abortTask = task(async close => {
    close();
    this.segment.track('recurring-invoices_sdd-subscription-creation_canceled');
    await this.router.transitionTo('invoice-subscriptions.index');
  });
}
