export default {
  "signin": "qUR",
  "form": "qUQ",
  "illustration": "qUe",
  "purple": "qUB",
  "mint": "qUE",
  "mustard": "qUd",
  "peach": "qUI",
  "form-wrapper": "qUt",
  "header-logo": "qUM",
  "links": "qUP",
  "no-account-label": "qUl",
  "spinner-container": "qUX",
  "main-image-container": "qUC",
  "main-image": "qUk",
  "wise-logo-container": "qUJ",
  "wise-logo": "qUs"
};
