export default {
  "container": "qov",
  "compact-header": "qoo",
  "visible": "qon",
  "page-container": "qoi",
  "main": "qoA",
  "tabs": "qoY",
  "tabs-nav": "qoh",
  "tabs-panels": "qof",
  "tabs-panel": "qoK body-2",
  "sidebar": "qoG",
  "prismic-content": "qor"
};
