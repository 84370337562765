import Controller from '@ember/controller';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from 'design-system-kit';
import { task } from 'ember-concurrency';
import window from 'ember-window-mock';

import { apiBaseURL, oauthNamespace } from 'qonto/constants/hosts';
import { LOCAL_STORAGE_ORGANIZATION_KEY } from 'qonto/constants/oauth';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class OauthIndexController extends Controller {
  lottiePlayer = LottiePlayer;

  queryParams = ['login_challenge'];

  @service toastFlashMessages;
  @service intl;
  @service networkManager;
  @service organizationManager;
  @service sentry;

  @tracked organization;
  @tracked error;

  get clientLogo() {
    return this.model?.login?.client?.['logo_uri'];
  }

  get clientName() {
    return capitalize(this.model?.login?.client?.['name'] || '');
  }

  get showError() {
    return !this.organization && this.error;
  }

  acceptLoginRequestTask = task(async () => {
    if (!this.organization) {
      this.error = true;

      return;
    }

    try {
      let { redirect_to: redirectTo } = await this.networkManager.request(
        `${apiBaseURL}/${oauthNamespace}/oauth/login_requests/accept`,
        {
          method: 'POST',
          data: JSON.stringify({
            login_challenge: this.login_challenge,
            organization_id: this.organization.id,
          }),
        }
      );

      safeLocalStorage.setItem(LOCAL_STORAGE_ORGANIZATION_KEY, this.organization.id);

      window.location.replace(redirectTo);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  rejectLoginRequestTask = task(async () => {
    try {
      let { redirect_to: redirectTo } = await this.networkManager.request(
        `${apiBaseURL}/${oauthNamespace}/oauth/login_requests/reject`,
        {
          method: 'POST',
          data: JSON.stringify({
            login_challenge: this.login_challenge,
          }),
        }
      );

      window.location.replace(redirectTo);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}
