export default {
  "page-container": "qng",
  "form": "qnD",
  "close-button": "qnV btn btn--icon-only btn--tertiary btn--large",
  "preview": "qnL",
  "preview-padding": "qnT",
  "title": "qnz",
  "header": "qna",
  "without-tabs": "qnH",
  "form-section": "qnO",
  "form-footer": "qnm",
  "disclaimer-text": "qnp"
};
