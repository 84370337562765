export default {
  "body": "qAd",
  "members": "qAI",
  "members-results": "qAt",
  "members-list": "qAM",
  "members-title": "qAP caption-bold",
  "member": "qAl",
  "empty-illustration": "qAX",
  "empty-title": "qAC title-3",
  "empty-description": "qAk body-2",
  "invitable-members": "qAJ",
  "details": "qAs",
  "pagination-footer": "qAF"
};
