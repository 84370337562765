export default {
  "container": "qrN",
  "content": "qrv",
  "setup-box": "qro",
  "setup-wizard": "qrn",
  "setup-success": "qri",
  "setup-success-lottie": "qrA",
  "setup-close-btn": "qrY",
  "tray-connection": "qrh",
  "close-button": "qrf"
};
