import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CashFlowRoute extends Route {
  @service featuresManager;
  @service abilities;
  @service homePage;

  queryParams = {
    bankAccounts: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (
      !this.featuresManager.isEnabled('displayCashflowV1') ||
      this.abilities.cannot('view overview')
    ) {
      return this.homePage.visitDefaultPage();
    }
  }

  model({ bankAccounts }) {
    return { bankAccounts };
  }
}
