import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { Spinner } from 'design-system-kit';
import { restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/outgoing-direct-debits';
import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { DEBOUNCE_MS } from 'qonto/constants/timers';

export default class MandatesListController extends Controller {
  spinner = Spinner;

  queryParams = [{ bankAccounts: 'bank-accounts' }, 'page', 'query'];

  @service organizationManager;
  @service router;
  @service intl;
  @service abilities;
  @service subscriptionManager;
  @service emptyStates;
  @service segment;

  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  @tracked page = 1;
  @tracked query = '';
  @tracked bankAccounts = '';
  perPage = 25;

  resetQueryParams() {
    this.page = 1;
    this.query = '';
    this.bankAccounts = '';
  }

  get selectedMandateId() {
    let { currentRoute } = this.router;

    if (currentRoute.name.startsWith('mandates.list.mandate')) {
      return currentRoute.parent.params['mandate_id'];
    }
    return null;
  }
  get isLoading() {
    return this.model.fetchMandatesTask.isRunning;
  }

  get mandates() {
    return this.model.fetchMandatesTask.lastSuccessful?.value || [];
  }

  get totalCount() {
    return this.mandates.meta?.total_count;
  }

  get totalPages() {
    return this.mandates.meta?.total_pages;
  }

  get activeMandates() {
    return this.mandates.filter(({ isActive }) => isActive);
  }

  get suspendedMandates() {
    return this.mandates.filter(({ isSuspended }) => isSuspended);
  }

  get hasSearch() {
    return Boolean(this.query);
  }

  get hasResults() {
    return Boolean(this.mandates.length);
  }

  get selectedBankAccount() {
    let { accounts } = this.organizationManager;
    return (
      accounts.find(({ id }) => id === this.bankAccounts) || this.organizationManager.currentAccount
    );
  }

  get shouldShowDiscoverEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get shouldShowInformEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get isBankAccountClosed() {
    return Boolean(this.selectedBankAccount?.isClosed);
  }

  get shouldShowSearchInput() {
    if (this.shouldShowDiscoverEmptyState) {
      return false;
    }
    if (this.hasSearch) {
      return true;
    }
    return this.mandates.length !== 0;
  }

  @action
  trackCtaEvent(origin) {
    if (variation('feature--boolean-empty-state-revamp')) {
      if (this.emptyStateRevampOptions) {
        this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
      } else {
        let trackingData = getTrackingNameAndProperties({
          type: TYPES.ACTIVATE,
          name: 'outgoing-direct-debits',
        })({
          isClickEvent: true,
          isEmptyState: false,
          origin: TRACKING_ORIGINS.HEADER,
        });
        if (trackingData?.name && trackingData.properties) {
          this.segment.track(trackingData.name, trackingData.properties);
        }
      }
    }
  }

  get emptyStateRevampOptions() {
    if (
      !variation('feature--boolean-empty-state-revamp') ||
      this.model.fetchAllMandatesTask.isRunning ||
      this.model.fetchAllMandatesTask.last?.isError
    ) {
      return;
    }

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.model.fetchAllMandatesTask.last?.value?.meta.total_count === 0,
      isEmptyLocally: this.mandates.length === 0,
      hasActiveFilterOrSearch: this.hasSearch,
      config: getEmptyStateConfig(this.intl),
      customInputs: {
        isSubAccountIsClosed: this.isBankAccountClosed,
      },
      abilities: {
        canManageMandates: this.abilities.can('create mandates'),
        canReadBankAccounts: this.abilities.can('read bank-account'),
        hasMultiAccounts: this.subscriptionManager.features.multiAccounts,
      },
    });
  }

  searchMandateTask = restartableTask(async query => {
    await timeout(DEBOUNCE_MS);
    this.query = query;
  });
}
