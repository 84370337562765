import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class SupplierInvoicesUpgradePlanRoute extends Route {
  @service abilities;
  @service router;
  @service segment;

  beforeModel() {
    if (
      this.abilities.can('access supplierInvoice') ||
      variation('feature--boolean-empty-state-revamp')
    ) {
      return this.router.replaceWith('supplier-invoices');
    }
    this.segment.track('supplier-invoices');
  }
}
