export default {
  "container": "qrX",
  "wrapper": "qrC",
  "grey-header-block": "qrk",
  "connect-app-header": "qrJ",
  "connect-app-header-link": "qrs",
  "connect-app-header-content": "qrF",
  "connect-app-header-content-left": "qrg",
  "application-avatar": "qrD",
  "connect-app-header-cta": "qrV",
  "connect-app-header-meta": "qrL",
  "connect-app-content": "qrT",
  "connect-app-description": "qrz",
  "title-wrapper": "qra",
  "title": "qrH title-1",
  "tag-line": "qrO body-2",
  "connect-app-body": "qrm"
};
