export default {
  "main-container": "qpu",
  "main": "qxS",
  "main-wrapper": "qxc",
  "close-button": "qxq",
  "active-block": "qxZ",
  "title": "qxR",
  "list-options": "qxQ",
  "card": "qxe",
  "card-container": "qxB",
  "card-image": "qxE",
  "card-tiles": "qxd"
};
