export default {
  "mandates": "qiY",
  "mandates-empty": "qih",
  "header": "qif",
  "header-empty": "qiK",
  "header-content": "qiG",
  "search": "qir",
  "search-bar": "qib",
  "search-spinner": "qiy",
  "body": "qiU",
  "isEmpty": "qij",
  "left-section": "qiW",
  "mandates-list": "qiu",
  "mandate-suspended": "qAS",
  "list-title": "qAc caption-bold",
  "list-empty": "qAq",
  "empty-illustration": "qAZ",
  "empty-title": "qAR title-3",
  "empty-description": "qAQ body-2",
  "body-details": "qAe",
  "l-app-content__page-header": "qAB",
  "pagination-footer": "qAE"
};
