import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

export default class InsuranceHubRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'connect');

    if (
      this.abilities.cannot('read insurance-contract') ||
      !variation('feature--boolean-insurance-hub-beta')
    ) {
      this.homePage.replaceWithDefaultPage();
    }
  }
}
