import Controller from '@ember/controller';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { underscore } from '@ember/string';
import { tracked } from '@glimmer/tracking';

import { Spinner } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';

import { OPERATOR } from 'qonto/constants/reminders-configuration';
import { NetworkManagerError } from 'qonto/services/network-manager';
import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class ClientRemindersConfigurationController extends Controller {
  spinner = Spinner;

  @service toastFlashMessages;
  @service intl;
  @service networkManager;
  @service sentry;
  @service router;
  @service modals;
  @service segment;

  @tracked displayErrors = false;
  @tracked collapsedRules = [];
  @tracked focusedRuleIndex = 0;

  get remindersConfiguration() {
    return this.model.fetchRemindersConfigurationTask.lastSuccessful?.value?.model;
  }

  get emailTemplates() {
    return this.model.fetchEmailTemplatesTask.lastSuccessful?.value ?? [];
  }

  get isLoading() {
    return (
      this.model.fetchRemindersConfigurationTask.isRunning ||
      this.model.fetchEmailTemplatesTask.isRunning
    );
  }

  get isDirty() {
    let initialState =
      this.model.fetchRemindersConfigurationTask.lastSuccessful?.value?.initialState;
    let newState = this.remindersConfiguration.serialize();

    return JSON.stringify(initialState) !== JSON.stringify(newState);
  }

  get visibleRules() {
    return this.remindersConfiguration.visibleRules;
  }

  get focusedRule() {
    return this.visibleRules[this.focusedRuleIndex];
  }

  get previewHeader() {
    let { operator, offsetDays } = this.focusedRule;
    return [
      this.intl.t('payment-reminders.reminder-item.title', {
        index: this.focusedRuleIndex + 1,
      }),
      operator === OPERATOR.ON || offsetDays
        ? this.intl.t('payment-reminders.reminder-item.rule', {
            operator,
            offset: offsetDays,
          })
        : null,
    ]
      .filter(Boolean)
      .join(' ');
  }

  indexToNumber = index => index + 1;

  @action
  setFocusedRule(index) {
    if (this.focusedRuleIndex !== index) {
      this.focusedRuleIndex = index;
    }
  }

  @action
  toggleRule(rule) {
    if (this.collapsedRules.includes(rule)) {
      this.collapsedRules = this.collapsedRules.filter(r => r !== rule);
      let index = this.visibleRules.indexOf(rule);
      this.setFocusedRule(index);
    } else {
      this.collapsedRules = [...this.collapsedRules, rule];
    }
  }

  @action
  expandInvalidRules() {
    this.visibleRules.forEach(rule => {
      if (rule.validations.isInvalid && this.collapsedRules.includes(rule)) {
        this.toggleRule(rule);
      }
    });
  }

  @action
  handleValidationErrors() {
    this.displayErrors = true;
    this.expandInvalidRules();
    next(scrollIntoView, '[data-invalid="true"], [data-has-error]');
  }

  @action
  trackSaveButtonClick() {
    if (this.remindersConfiguration.isNew) {
      this.segment.track({
        event: 'reminder_creation_confirmed',
        properties: {
          number_of_rules: this.visibleRules.length,
        },
      });
    } else {
      this.segment.track('reminder_edit_confirmed');
    }
  }

  deleteTask = dropTask(async () => {
    let count = this.visibleRules.length;
    let clientId = this.remindersConfiguration.client.id;
    let params;

    if (count > 1) {
      params = {
        title: this.intl.t('payment-reminders.reminders-deletion.confirmation-popup.title'),
        description: this.intl.t(
          'payment-reminders.reminders-deletion.confirmation-popup.subtitle'
        ),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('payment-reminders.reminders-deletion.confirmation-popup.delete-cta'),
      };
    } else {
      params = {
        title: this.intl.t('payment-reminders.last-reminder-deletion.confirmation-popup.title', {
          index: 1,
        }),
        description: this.intl.t(
          'payment-reminders.last-reminder-deletion.confirmation-popup.subtitle',
          { operator: this.visibleRules[0].operator, offset: this.visibleRules[0].offsetDays }
        ),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t(
          'payment-reminders.last-reminder-deletion.confirmation-popup.delete-cta'
        ),
      };
    }

    let result = await this.modals.open('popup/destructive', params);
    if (result === 'confirm') {
      this.segment.track('reminder_delete_confirmed');
    } else {
      return;
    }

    await this.remindersConfiguration.destroyRecord();
    this._redirectToOrigin({
      queryParams: { hasNoReminders: clientId, hasReminders: null },
    });

    this.toastFlashMessages.toastSuccess(
      this.intl.t('payment-reminders.toasts.success.delete-reminders', { count })
    );
    this._redirectToOrigin();
  });

  submitTask = dropTask(async () => {
    if (!this.remindersConfiguration.validations.isValid) {
      this.handleValidationErrors();
      return;
    }

    let isNew = this.remindersConfiguration.isNew;
    let clientId = this.remindersConfiguration.client.id;

    try {
      let count = this.visibleRules.length;

      await this.remindersConfiguration.save();

      let message = isNew
        ? this.intl.t('payment-reminders.toasts.success.setup-reminders', { count })
        : this.intl.t('payment-reminders.toasts.success.edit-reminders', { count });
      this.toastFlashMessages.toastSuccess(message);
      this._redirectToOrigin({
        queryParams: { hasReminders: clientId, hasNoReminders: null },
      });
    } catch (error) {
      this._handleError(error);
    }
  });

  closePopupConfirmTask = dropTask(async close => {
    await this.submitTask.perform();

    close();
  });

  closeTask = dropTask(async () => {
    let { isNew } = this.remindersConfiguration;

    if (!this.isDirty) {
      if (isNew) {
        this._rollback();
      }
      this._redirectToOrigin();
      return;
    }

    let params = isNew
      ? {
          title: this.intl.t('payment-reminders.reminders-setup.abort-popup.title'),
          description: this.intl.t('payment-reminders.reminders-setup.abort-popup.subtitle'),
          cancel: this.intl.t('btn.cancel'),
          confirm: this.intl.t('btn.leave'),
        }
      : {
          title: this.intl.t('payment-reminders.reminders-edition.abort-popup.title'),
          description: this.intl.t('payment-reminders.reminders-edition.abort-popup.subtitle'),
          cancel: this.intl.t('payment-reminders.reminders-edition.abort-popup.cancel-cta'),
          confirm: this.intl.t('payment-reminders.reminders-edition.abort-popup.save-cta'),
          confirmTask: this.closePopupConfirmTask,
        };

    let result = await this.modals.open('popup/confirmation', params);
    let hasConfirmed = result === 'confirm';

    if (isNew === hasConfirmed) {
      if (isNew) {
        this.segment.track('reminder_creation_canceled');
      }

      this._rollback();
      this._redirectToOrigin();
    }
  });

  _redirectToOrigin({ queryParams = {} } = {}) {
    queryParams = { ...this.origin.queryParams, ...queryParams };
    this.router.transitionTo(this.origin.name, { queryParams });
  }

  _rollback() {
    this.remindersConfiguration.rules.forEach(rule => rule.rollbackAttributes());
    this.remindersConfiguration.rollbackAttributes();
  }

  _handleError(error) {
    error.errors?.forEach(error => {
      if (error.source?.pointer) {
        error.source.pointer = underscore(error.source.pointer);
      }
    });

    let hasValidationErrors = error.errors?.some(error =>
      error.source?.pointer?.match(/(offset_days|operator|send_to)/)
    );

    if ((error.isAdapterError || error instanceof NetworkManagerError) && hasValidationErrors) {
      this._assignConfigurationErrors(error);
      this._assignRuleErrors(error);
      this.handleValidationErrors();
    } else {
      this.toastFlashMessages.toastError(
        this.intl.t('payment-reminders.toasts.error.save-failed', {
          count: this.visibleRules.length,
        })
      );

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  _assignConfigurationErrors(error) {
    let parsedErrors = error.errors.reduce((parsed, error) => {
      if (error.source?.pointer?.match('data/attributes/send_to')) {
        parsed.sendTo = [this.intl.t('receivable-invoices.share-email.field-send-to.error')];
      }
      return parsed;
    }, {});

    this.networkManager.errorModelInjector(this.remindersConfiguration, parsedErrors, error);
  }

  _assignRuleErrors(error) {
    let parsedErrors = error.errors.reduce((parsed, error) => {
      let matches = error.source?.pointer?.match(/\/rules\/([0-9]+)\/(.*)/);
      if (matches) {
        let index = Number(matches[1]);
        let attribute = matches[2];
        if (attribute === 'offset_days') {
          attribute = 'operator';
        }
        parsed[index] = parsed[index] || {};
        parsed[index][attribute] = parsed[index][attribute] || [];
        parsed[index][attribute].push(
          this.intl.t('payment-reminders.form.validations.offset.already-exists')
        );
      }
      return parsed;
    }, []);

    parsedErrors.forEach((ruleErrors, index) => {
      this.networkManager.errorModelInjector(
        this.remindersConfiguration.visibleRules[index],
        ruleErrors,
        error
      );
    });
  }
}
