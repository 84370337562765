export default {
  "bank-accounts": "qKi l-app-content__wrapper",
  "header-wrapper": "qKA",
  "scrolled-top": "qKY",
  "header": "qKh l-app-content__page-header",
  "create-account-button": "qKf btn btn--primary",
  "main": "qKK",
  "accounts": "qKG",
  "subtitle-container": "qKr",
  "subtitle": "qKb title-3 mb-16",
  "amount": "qKy body-2",
  "list--cashbeeSavings": "qKU"
};
