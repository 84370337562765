export default {
  "supplier-invoices-page-header": "qUH",
  "filters-container": "qUO",
  "bulk-actions": "qUm",
  "item-count": "qUp",
  "higher-index": "qUx",
  "title-wrapper": "qUw",
  "title": "qUN",
  "subtitle": "qUv",
  "table-container": "qUo",
  "file-dropzone": "qUn",
  "dropzone-visible": "qUi",
  "header-actions": "qUA",
  "header-wrapper": "qUY"
};
