import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { attachmentCollectorNamespace, attachmentCollectorURL } from 'qonto/constants/hosts';

const ATTACHMENT_COLLECTOR_URL = `${attachmentCollectorURL}/${attachmentCollectorNamespace}`;

export default class ReceiptsForwardRoute extends Route {
  @service organizationManager;
  @service networkManager;
  @service abilities;
  @service store;

  rootRouteName = 'settings.receipts-forward';

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'bookkeeping');
    if (this.abilities.cannot('access email forward attachment')) {
      return this.replaceWith(`${this.rootRouteName}.upgrade-plan`);
    }
  }

  async model() {
    let { organization } = this.organizationManager;
    let { email_address } = await this.networkManager.request(
      `${ATTACHMENT_COLLECTOR_URL}/email_addresses?organization_id=${organization.id}`
    );

    let allowedEmail;
    let bulkEmail = { email: email_address };

    if (this.abilities.can('read whitelist email attachment')) {
      let allowedEmails = await this.store.query('allowed-email', {
        organization_id: organization.id,
      });

      if (allowedEmails.length) {
        allowedEmail = allowedEmails[0];
      } else {
        allowedEmail = this.store.createRecord('allowed-email', { organization });
      }
    }

    return { allowedEmail, bulkEmail };
  }

  resetController(controller, isExiting) {
    controller.openEmailFormModalTask.cancelAll();
    controller.openDeleteEmailModalTask.cancelAll();

    if (isExiting) {
      controller._allowedEmail = null;
    }
  }
}
