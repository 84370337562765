import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { variation } from 'ember-launch-darkly';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/receivable-invoice';
import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { FULLPAGE_EMPTY_STATE_FF, STATUS } from 'qonto/constants/receivable-invoice';

export default class ReceivableInvoicesIndexController extends Controller {
  @service intl;
  @service router;
  @service segment;
  @service subscriptionManager;
  @service abilities;
  @service organizationManager;
  @service modals;
  @service emptyStates;
  @service receivableInvoicesUploadManager;

  queryParams = ['status', 'page', 'perPage', 'sortBy'];
  @tracked status = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = this.defaultSortBy;

  get currentParams() {
    return {
      status: this.status,
      sortBy: this.sortBy,
      page: this.page,
      perPage: this.perPage,
    };
  }

  get sideDrawerId() {
    return this.abilities.can('request financing')
      ? 'receivable-invoices-with-financing'
      : 'receivable-invoices';
  }

  get invoiceStats() {
    return this.model.invoicesTask.lastSuccessful?.value.invoiceStats;
  }

  get isEmptyGlobally() {
    return this.invoiceStats?.created?.total === 0;
  }

  get isEmptyLocally() {
    let createdInvoices = this.invoiceStats?.created;
    switch (this.status) {
      case STATUS.DRAFT:
        return createdInvoices?.draft === 0;
      case STATUS.UNPAID:
        return createdInvoices?.unpaid === 0;
      case STATUS.CANCELED:
        return createdInvoices?.canceled === 0;
      case STATUS.PAID:
        return createdInvoices?.paid === 0;
    }

    if (this.isCompletedTabActive) {
      return createdInvoices?.paid === 0 && createdInvoices?.canceled === 0;
    }
  }

  get tabName() {
    switch (this.status) {
      case STATUS.DRAFT:
        return 'draft';
      case STATUS.UNPAID:
        return 'pending';
      default:
        return 'completed';
    }
  }

  get isExportButtonEnabled() {
    if (this.model.invoicesTask.isRunning) {
      return true;
    }

    let createdInvoices = this.invoiceStats?.created;
    if (!createdInvoices) {
      return false;
    }

    return [
      createdInvoices[STATUS.UNPAID],
      createdInvoices[STATUS.CANCELED],
      createdInvoices[STATUS.PAID],
    ].some(status => status > 0);
  }

  trackCtaEvent(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'client-invoices',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });

      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  get isInformEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get displayEmptyStateRevamp() {
    return variation('feature--boolean-empty-state-revamp');
  }

  get emptyStateRevampOptions() {
    if (this.displayEmptyStateRevamp && !this.model.invoicesTask.isRunning) {
      return this.emptyStates.getEmptyStateOptions({
        isOrgEligibleForFeature: true,
        isEmptyGlobally: this.isEmptyGlobally,
        isEmptyLocally: this.isEmptyLocally,
        hasActiveFilterOrSearch: Boolean(this.filterValue),
        config: getEmptyStateConfig(this.intl),
        customInputs: {
          tab: this.tabName,
        },
      });
    }
  }

  get hasReachedLimitOfInvoices() {
    return this.invoiceStats?.quotasRemaining?.total === 0;
  }

  get totalCount() {
    return {
      draft: this.invoiceStats?.created?.draft || 0,
      unpaid: this.invoiceStats?.created?.unpaid || 0,
      completed: this.invoiceStats?.created?.paid + this.invoiceStats?.created?.canceled || 0,
    };
  }

  get isCompletedTabActive() {
    let statuses = this.status?.split(',') || [];

    return statuses.includes(STATUS.PAID) || statuses.includes(STATUS.CANCELED);
  }

  get filterValue() {
    if (this.status?.split(',').length > 1) {
      return;
    }

    return this.filterOptions.find(el => el.code === this.status);
  }

  get filterOptions() {
    return [
      {
        code: STATUS.PAID,
        value: this.intl.t('receivable-invoices.status.paid'),
      },
      {
        code: STATUS.CANCELED,
        value: this.intl.t('receivable-invoices.status.canceled'),
      },
    ];
  }

  @action
  updateFilter(selected) {
    if (!selected) {
      this.status = this.filterOptions.map(el => el.code).join(',');
      return;
    }
    this.status = selected.code;
  }

  get defaultSortBy() {
    if (this.status === STATUS.UNPAID) {
      return 'due_date,-number';
    }
    return '-issue_date,-number';
  }

  get canFullyAccess() {
    return this.abilities.can('fully access receivableInvoice');
  }

  get isCreateInvoiceCTADisplayed() {
    return !this.displayEmptyState && !this.hasReachedLimitOfInvoices;
  }

  @action
  trackHeaderClick() {
    if (this.displayEmptyStateRevamp) {
      this.trackCtaEvent(TRACKING_ORIGINS.HEADER);
    } else {
      this.segment.track('invoice_creation_started', {
        origin: 'invoice_list',
        price_plan: this.subscriptionManager.currentPricePlan.code,
      });
    }
  }

  @action
  trackPrimaryClick() {
    this.trackCtaEvent(TRACKING_ORIGINS.PRIMARY);
  }

  @action
  trackSecondaryClick() {
    // specific tracker for import ar feature
    this.segment.track('invoice_imported_get-started_clicked');

    //tracker for empty states feature
    this.trackCtaEvent(TRACKING_ORIGINS.SECONDARY);
  }

  get isFilterActive() {
    return this.status !== null;
  }

  get canDisplayBanner() {
    if (this.model.invoicesTask.isRunning) {
      return false;
    }
    return this.hasReachedLimitOfInvoices;
  }

  get displayEmptyState() {
    let { invoicesTask, canReadInvoices } = this.model;
    let hasReceivableInvoices = false;
    if (invoicesTask.last?.isSuccessful) {
      hasReceivableInvoices = invoicesTask.lastSuccessful.value.receivableInvoices.length > 0;
    }
    return (
      !canReadInvoices ||
      (!invoicesTask.isRunning && !hasReceivableInvoices && !this.isFilterActive)
    );
  }

  get emptyStateOptions() {
    let {
      organization: { legalCountry },
    } = this.organizationManager;

    let emptyStates = {
      title: this.intl.t('receivable-invoices.empty-state-basic-upsell.title', { legalCountry }),
      description: this.intl.t('receivable-invoices.empty-state-basic-upsell.description'),
      cta: this.intl.t('receivable-invoices.empty-state-basic-upsell.cta'),
    };

    if (this.canFullyAccess) {
      emptyStates = {
        title: this.intl.t('receivable-invoices.empty-state.title', { legalCountry }),
        description: this.intl.t('receivable-invoices.empty-state.description', { legalCountry }),
        cta: this.intl.t('receivable-invoices.empty-state.cta'),
      };
    }

    return this.getEmptyStateOptionsFromPath(emptyStates);
  }

  getEmptyStateOptionsFromPath({ title, description, cta }) {
    return {
      title,
      subtitle: description,
      lottieSrc: `/lotties/receivable-invoices/empty-state.json`,
      button: {
        label: cta,
        callback: () => {
          this.segment.track('invoice_creation_started', {
            origin: 'empty_state',
            price_plan: this.subscriptionManager.currentPricePlan.code,
          });
          this.router.transitionTo('receivable-invoices.new');
        },
      },
    };
  }

  get localState() {
    let localState = {
      isEmpty: false,
      isError: false,
      isLoading: false,
    };

    if (this.model.invoicesTask.isRunning || this.model.settingsTask.isRunning) {
      localState.isLoading = true;
    } else if (this.model.invoicesTask.last.isError || this.model.settingsTask.last.isError) {
      localState.isError = true;
    } else if (this.model.invoicesTask.lastSuccessful?.value.meta.total_count === 0) {
      if ([STATUS.PAID, STATUS.CANCELED].includes(this.status)) {
        return;
      }
      localState.isEmpty = true;
    }

    return localState;
  }

  get receivableInvoices() {
    return this.model.invoicesTask.lastSuccessful?.value.receivableInvoices || [];
  }

  get settings() {
    return this.model.settingsTask.lastSuccessful?.value;
  }

  get showStatusFilter() {
    return (
      this.isCompletedTabActive &&
      (this.model.invoicesTask.lastSuccessful?.value.meta.total_count > 0 ||
        [STATUS.CANCELED, STATUS.PAID].includes(this.status))
    );
  }

  get tabEmptyStateOptions() {
    if (!this.status) {
      return;
    }

    switch (this.status) {
      case STATUS.DRAFT:
        return {
          title: !this.canFullyAccess
            ? this.intl.t('receivable-invoices.empty-state-basic-upsell.title')
            : this.intl.t('receivable-invoices.invoices-list.tabs.drafts.empty-state.title'),
          subtitle: !this.canFullyAccess
            ? this.intl.t('receivable-invoices.empty-state-basic-upsell.solo-plan.description')
            : this.intl.t('receivable-invoices.invoices-list.tabs.drafts.empty-state.body'),
          lottieSrc: `/lotties/receivable-invoices/empty-state-draft.json`,
        };
      case STATUS.CANCELED:
        return {
          title: this.intl.t('receivable-invoices.invoices-list.tab_canceled.empty_state.title'),
          subtitle: this.intl.t('receivable-invoices.invoices-list.tab_canceled.empty_state.text', {
            htmlSafe: true,
          }),
          lottieSrc: `/lotties/receivable-invoices/empty-state-canceled.json`,
        };
      case STATUS.PAID:
        return {
          title: this.intl.t('receivable-invoices.invoices-list.tab_paid.empty_state.title'),
          subtitle: this.intl.t('receivable-invoices.invoices-list.tab_paid.empty_state.text', {
            htmlSafe: true,
          }),
          lottieSrc: `/lotties/receivable-invoices/empty-state-paid.json`,
        };
      case STATUS.UNPAID:
        return {
          title: this.intl.t('receivable-invoices.invoices-list.tabs.pending.empty-state.title'),
          subtitle: this.intl.t('receivable-invoices.invoices-list.tabs.pending.empty-state.body'),
          lottieSrc: `/lotties/receivable-invoices/empty-state-unpaid.json`,
        };
      case STATUS.COMPLETED:
        return {
          title: this.intl.t('receivable-invoices.invoices-list.tabs.completed.empty-state.title'),
          subtitle: this.intl.t(
            'receivable-invoices.invoices-list.tabs.completed.empty-state.body'
          ),
          lottieSrc: `/lotties/receivable-invoices/empty-state-paid.json`,
        };
    }
  }

  get columnsToShow() {
    let status = ![STATUS.DRAFT, STATUS.UNPAID].includes(this.status);

    return {
      customer: true,
      issueDate: true,
      dueDate: true,
      status,
      amountDue: true,
    };
  }

  get tableCaption() {
    switch (this.status) {
      case 'draft':
        return this.intl.t('receivable-invoices.invoices-list.tabs.drafts.title');
      case 'canceled':
        return this.intl.t('receivable-invoices.invoices-list.tabs.canceled');
      case 'paid':
        return this.intl.t('receivable-invoices.invoices-list.tabs.paid');
      case 'unpaid':
        return this.intl.t('receivable-invoices.invoices-list.tabs.unpaid');
      default:
        return this.intl.t('receivable-invoices.invoices-list.tabs.all');
    }
  }

  @action changePage(page) {
    this.page = page;
  }

  @action changePerPage(perPage) {
    this.perPage = perPage;
  }

  @action handleSortBy(sortDefinition) {
    this.sortBy = sortDefinition;
  }

  @action
  onUploadPopoverClose() {
    this.receivableInvoicesUploadManager.resetState();
    this.segment.track('invoice_imported_upload-component_close-button_clicked');
  }

  @action
  onPreviewFile(file) {
    this.segment.track('invoice_imported_upload-component_invoice-item_clicked');
    this.router.transitionTo('receivable-invoices.show', file.invoiceId);
  }

  get importingPopoverCopies() {
    return {
      'in-progress': 'invoicing.importing-modal.title.in-progress',
      errors: 'invoicing.importing-modal.title.errors',
      complete: 'invoicing.importing-modal.title.complete',
    };
  }

  get shouldShowFullpageEmptyState() {
    let { NEW_DESIGN_NEW_COPIES, NEW_DESIGN_OLD_COPIES, OFF, OLD_DESIGN } = FULLPAGE_EMPTY_STATE_FF;
    let fullpageEmptyStateVariation = variation('feature--string-receivable-invoices-es-revamp');

    if (!fullpageEmptyStateVariation) {
      return false;
    }

    this.segment.track('empty-state-variation', {
      variation: fullpageEmptyStateVariation,
      legalCountry: this.organizationManager.organization.legalCountry,
    });

    switch (fullpageEmptyStateVariation) {
      case NEW_DESIGN_OLD_COPIES:
      case NEW_DESIGN_NEW_COPIES:
        this.segment.track('empty-state_shown', {
          'empty-state_feature': 'client-invoices',
          'invoicing-test_variation': fullpageEmptyStateVariation,
        });

        return true;
      case OFF:
      case OLD_DESIGN:
      default:
        return false;
    }
  }
}
