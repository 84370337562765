export default {
  "page-header": "qWZ",
  "header-breadcrumb": "qWR",
  "tabs-container": "qWQ",
  "bottom-border": "qWe",
  "tabs-separator": "qWB",
  "previous-request": "qWE",
  "body-link": "qWd",
  "border-top": "qWI"
};
