export default {
  "container": "qfZ",
  "form-container": "qfR",
  "preview-container": "qfQ",
  "pdf-preview-container": "qfe",
  "content": "qfB",
  "title": "qfE",
  "tabs-container": "qfd",
  "close-button": "qfI btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qft",
  "header": "qfM",
  "without-tabs": "qfP"
};
