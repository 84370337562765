export default {
  "page-wrapper": "qUF",
  "content-wrapper": "qUg",
  "content": "qUD",
  "period-field": "qUV",
  "format-options": "qUL",
  "format-option": "qUT",
  "format-radio": "qUz",
  "format-description": "qUa body-2"
};
