/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ROLES } from 'qonto/constants/membership';
import { LIMITS } from 'qonto/constants/subscriptions';
import { DEBOUNCE_MS } from 'qonto/constants/timers';

export default class MembersController extends Controller {
  queryParams = ['teamId', 'query'];

  @service abilities;
  @service intl;
  @service router;
  @service organizationManager;
  @service segment;
  @service subscriptionManager;

  @tracked query;
  @tracked listController = null;

  get isSoloPlan() {
    return this.subscriptionManager.getLimitByFeatureCode('additional_users') === 1;
  }

  get inviteButtonText() {
    if (this.isSoloPlan) {
      return this.intl.t('btn.invite_an_accountant');
    }

    if (this.organizationManager.membership.role === ROLES.MANAGER) {
      return this.intl.t('btn.invite_an_employee');
    }

    return this.intl.t('btn.invite_new_member');
  }

  get teams() {
    return this.organizationManager.organization.teams;
  }

  get hasMembersOrSearching() {
    let controller = this.listController;

    return (
      Boolean(this.query) ||
      Boolean(controller?.model?.paginatedMembersTask?.lastSuccessful?.value?.length) ||
      Boolean(controller?.model?.invitableMembersTask?.lastSuccessful?.value?.length)
    );
  }

  get showFilters() {
    let filtersApplied = Boolean(this.query || this.teamId);
    return (
      filtersApplied || this.model?.paginatedMembersResponseTask?.lastSuccessful?.value?.length > 0
    );
  }

  get activeMembershipsCount() {
    let { organization } = this.organizationManager;

    let activeMembershipsCount = this.abilities.can('access accountant-access')
      ? organization.membershipsCountingTowardsPlanLimitCount
      : organization.activeMembershipsCount;

    return activeMembershipsCount;
  }

  get remainingInvitationCount() {
    let limit = this.subscriptionManager.getLimitByFeatureCode('additional_users');
    return limit === null ? null : limit - this.activeMembershipsCount;
  }

  get hasAdditionalUsersHardLimit() {
    if (variation('feature--boolean-mp-epic2b')) {
      return (
        this.subscriptionManager.getLimitObjectByFeatureCode('additional_users')?.type ===
        LIMITS.HARD
      );
    } else {
      // pricePlan.userLimit !== null && additional_users not in pricePlan.features
      return (
        this.subscriptionManager.getLimitByFeatureCode('additional_users') !== null &&
        this.abilities.cannot('create paid members members')
      );
    }
  }

  get invitationsAreLimited() {
    return this.hasAdditionalUsersHardLimit && this.abilities.cannot('access accountant-access');
  }

  searchTask = restartableTask(async query => {
    await timeout(DEBOUNCE_MS);
    let origin = 'to_invite';
    if (this.activeTabIsActive) {
      origin = 'active';
    }
    if (this.inactiveTabIsActive) {
      origin = 'inactive';
    }
    this.segment.track('members_search', {
      origin,
    });
    this.router.replaceWith({ queryParams: { query: query?.trim(), page: 1 } });
  });

  @action
  trackTeamInviteSelected() {
    this.segment.track('team_invite_selected', {
      origin: 'user_access_cta',
    });
  }

  get activeTabIsActive() {
    return this.router.currentRouteName.includes('members.active');
  }

  get inactiveTabIsActive() {
    return this.router.currentRouteName.includes('members.inactive');
  }

  get toInviteTabIsActive() {
    return this.router.currentRouteName.includes('members.to-invite');
  }

  @action
  handleTeamSelect(team) {
    this.router.transitionTo({
      queryParams: {
        teamId: team?.id ?? null,
      },
    });
  }

  get selectedTeam() {
    let teamId = this.router.currentRoute?.queryParams['teamId'];
    let selectedTeam = this.teams.find(({ id }) => teamId === id);
    return selectedTeam;
  }

  @action
  trackTab(origin) {
    this.segment.track('user-access_members-tab_clicked', {
      origin,
    });
  }
}
