import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class ReimbursementsIndexRoute extends Route {
  @service abilities;
  @service router;
  @service store;
  @service organizationManager;
  @service segment;

  beforeModel() {
    if (this.abilities.cannot('navigate reimbursements approver view in request')) {
      this.router.replaceWith('reimbursements.requests');
    }

    if (variation('feature--boolean-empty-state-revamp')) {
      return this.router.replaceWith('reimbursements.pending');
    }
  }

  async model() {
    let requests = this.store.peekAll('request');
    if (requests.length === 0) {
      requests = await this.store.query('request', {
        per_page: 1,
        organization_id: this.organizationManager.organization.id,
      });
    }
    return requests.length > 0;
  }

  afterModel(hasRequests) {
    if (hasRequests) {
      return this.router.replaceWith('reimbursements.pending');
    }
    this.segment.track('activation_empty_state_displayed', { origin: 'reimbursements' });
  }
}
