import Controller from '@ember/controller';
import { service } from '@ember/service';

import { LottiePlayer } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class InvitationsShowAccountant extends Controller {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service router;
  @service organizationManager;
  @service sessionManager;
  @service userManager;
  @service abilities;
  @service sentry;
  @service toastFlashMessages;

  saveUserTask = dropTask(async (user, invite) => {
    let { email, password } = user;
    let { loginToken } = this.invite;
    user.set('loginToken', loginToken);

    await user.save();

    if (!this.sessionManager.isAuthenticated) {
      await this.userManager.setup({ email, password });
    }

    try {
      await invite.accept();

      await this.organizationManager.setupTask.perform();

      if (this.isMobile) {
        return this.router.transitionTo('invitations.show.member.success');
      } else if (this.abilities.can('access accounting-hub')) {
        this.router.transitionTo('accounting-hub');
      } else {
        this.router.transitionTo('organizations.show', invite.organization);
      }
    } catch (error) {
      let isInvitationAlreadyAccepted = error.errors?.some(
        err => err?.detail?.code === 'invalid_invite'
      );

      if (isInvitationAlreadyAccepted) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.invite_already_accepted'));
        return;
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
