/* eslint-disable require-await */
import { NotFoundError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { ErrorInfo } from 'qonto/utils/error-info';

class InvoiceFlowDataContext {
  destination = null;
  invoiceID = null;
  quoteId = null;
  supplierInvoiceId = null;
  subscription = null;
  abortFallback = null;

  constructor({ destination, invoiceID, quoteId, supplierInvoiceId, subscription, abortFallback }) {
    this.destination = destination;
    this.invoiceID = invoiceID;
    this.quoteId = quoteId;
    this.supplierInvoiceId = supplierInvoiceId;
    this.subscription = subscription;
    this.abortFallback = abortFallback;
  }
}

export default class InvoiceFlowSetup extends FlowSetup {
  @service abilities;
  @service flow;
  @service homePage;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;

  dataContext = null;

  constructor() {
    super(...arguments);
    this.dataContext = new InvoiceFlowDataContext(this.router.currentRoute.queryParams);
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');
    let cannnotAccessFlow =
      this.abilities.cannot('create receivable-invoice') ||
      this.abilities.cannot('read receivable-invoice') ||
      (this.dataContext.supplierInvoiceId ? this.abilities.cannot('create self-invoice') : false);

    if (cannnotAccessFlow) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  @action
  onComplete() {
    let { destination, invoiceID, quoteId, supplierInvoiceId, subscription } = this.dataContext;

    if (supplierInvoiceId) {
      return this.router.replaceWith('self-billing.new', { queryParams: { supplierInvoiceId } });
    }

    if (quoteId) {
      return this.router.replaceWith('receivable-invoices.new', { queryParams: { quoteId } });
    }

    if (subscription) {
      return this.router.replaceWith('invoice-subscriptions.new');
    }

    if (destination && invoiceID) {
      return this.router.replaceWith('receivable-invoices.' + destination, invoiceID);
    } else if (destination) {
      return this.router.replaceWith('receivable-invoices.' + destination);
    }

    return this.router.replaceWith('receivable-invoices.index');
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    if (stepId === 'invoice-number') {
      let result = await this.openAbortModalTask.perform();
      let shouldContinueAbort = result === 'confirm';
      if (shouldContinueAbort) {
        this._goBackToMainPage();
        try {
          let config = this.store.peekRecord(
            'receivable-invoices-settings',
            this.organizationManager.organization.id
          );
          config.destroyRecord();
        } catch (error) {
          if (ErrorInfo.for(error).shouldSendToSentry && !(error instanceof NotFoundError)) {
            this.sentry.captureException(error);
          }
        }
      }
      return shouldContinueAbort;
    } else this._goBackToMainPage();
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/confirmation', {
      title: this.intl.t('receivable-invoices.invoice-number-settings.exit-modal.title'),
      description: this.intl.t(
        'receivable-invoices.invoice-number-settings.exit-modal.description'
      ),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('receivable-invoices.invoice-number-settings.exit-modal.confirm.cta'),
    });
  });

  _goBackToMainPage() {
    if (this.flow.refererPage && !this.flow.refererPage.includes('_loading')) {
      return this.router.replaceWith(this.flow.refererPage);
    }

    return this.router.replaceWith(this.dataContext.abortFallback || 'receivable-invoices.index');
  }
}
