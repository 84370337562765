export const subscriptionClose = {
  steps: {
    introduction: {
      componentName: 'subscription/close/introduction',
      nextStepId: 'reason-category',
    },
    'reason-category': {
      componentName: 'subscription/close/reason-category',
      nextStepId: ({ closingReasonCategory }) => {
        if (closingReasonCategory?.key === 'other') {
          return 'reason-details';
        } else if (closingReasonCategory?.items?.length) {
          return 'reason';
        }
        return 'disclaimer';
      },
    },
    'reason-details': {
      componentName: 'subscription/close/reason-details',
      nextStepId: 'disclaimer',
    },
    reason: {
      componentName: 'subscription/close/reason',
      nextStepId: 'disclaimer',
    },
    disclaimer: {
      componentName: 'subscription/close/disclaimer',
      nextStepId: () => {
        return 'success';
      },
    },
    confirm: {
      componentName: 'subscription/close/confirm',
      nextStepId: 'success',
    },
    success: {
      componentName: 'subscription/close/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
