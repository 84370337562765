import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class MandatesLandingRoute extends Route {
  @service organizationManager;
  @service store;
  @service sentry;
  @service toastFlashMessages;
  @service intl;

  queryParams = {
    bankAccounts: {
      refreshModel: true,
    },
  };

  async model({ bankAccounts }) {
    let filters = bankAccounts ? { bank_account_ids: bankAccounts.split(',') } : undefined;

    // we fetch the mandates just to know if we want to redirect or not
    try {
      let mandates = await this.store.query('mandate', {
        organization_id: this.organizationManager.organization.id,
        filters,
        per_page: 1,
      });

      if (mandates.meta.total_count) {
        this.transitionTo('mandates.list');
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(
          new Error('failed to load a single mandate on the mandate.landing route', {
            cause: error,
          })
        );
      }
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  }
}
