export default {
  "page-wrapper": "qfz",
  "content-wrapper": "qfa",
  "content": "qfH",
  "period-field": "qfO",
  "format-options": "qfm",
  "format-option": "qfp",
  "format-radio": "qfx",
  "format-description": "qfw body-2"
};
