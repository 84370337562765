import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { DIRECT_DEBIT_COLLECTION_STATUS } from 'qonto/constants/direct-debit-collections';
import {
  DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS,
} from 'qonto/constants/direct-debit-subscriptions';
import { REQUEST_TYPES } from 'qonto/constants/requests';

export default class DirectDebitCollectionsRoute extends Route {
  @service homePage;
  @service store;
  @service router;
  @service sentry;
  @service abilities;
  @service organizationManager;
  @service notifierCounterManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    if (this.abilities.cannot('access directDebitCollection')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  async model() {
    this.store.unloadAll('direct-debit-collection-activation'); // important to clean up store
    let directDebitCollectionActivation = await this.store
      .createRecord('direct-debit-collection-activation')
      .fetchData();

    if (!directDebitCollectionActivation) {
      return this.homePage.replaceWithDefaultPage();
    }

    let {
      isActivatedNotEligible,
      isEligible,
      isActivated,
      hasPendingCreditorIdentifierVerification,
      hasPendingCreditorIdentifierCreation,
      collectionLimit,
      guardingDepositPercentage,
      hasGuarding,
    } = directDebitCollectionActivation;

    let isGloballyEmpty;
    if (!variation('feature--sdd-guarding-rollout')) {
      isGloballyEmpty = true;
      isEligible = false;
      isActivated = false;
    } else {
      isGloballyEmpty = await this.checkGlobalEmptiness.perform();
    }

    return {
      isActivatedNotEligible,
      isEligible,
      isActivated,
      hasPendingCreditorIdentifierVerification,
      hasPendingCreditorIdentifierCreation,
      isGloballyEmpty,
      collectionLimit,
      guardingDepositPercentage,
      hasGuarding,
    };
  }

  redirect({ isActivated, isActivatedNotEligible, isGloballyEmpty }, transition) {
    if (isGloballyEmpty) return;

    let isTransitionToChildRoute = [
      'direct-debit-collections.requests.index',
      'direct-debit-collections.requests.request',
      'direct-debit-collections.completed',
      'direct-debit-collections.processing.index',
      'direct-debit-collections.processing.payment-link',
    ].includes(transition.to.name);

    if (isActivated) {
      if (isTransitionToChildRoute) {
        this.router.replaceWith(transition.to.name);
      } else {
        this.router.replaceWith('direct-debit-collections.processing');
      }
    } else if (isActivatedNotEligible) {
      this.router.replaceWith('direct-debit-collections.completed');
    }
  }

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }

  checkGlobalEmptiness = dropTask(async () => {
    let hasRequests = false;
    if (this.abilities.can('read direct debit collection request')) {
      hasRequests = Boolean((await this.fetchSDDMultiRequestsTask.perform()).length);
    }

    let hasSubscriptions = Boolean((await this.fetchSDDSubscriptionsTask.perform()).length);

    let hasCollections = Boolean((await this.fetchSDDCollectionsTask.perform()).length);

    return !hasRequests && !hasSubscriptions && !hasCollections;
  });

  fetchSDDMultiRequestsTask = dropTask(async () => {
    return await this.store.query('request', {
      organization_id: this.organizationManager.organization.id,
      request_type: [REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION],
      page: 1,
      per_page: 1,
    });
  });

  fetchSDDSubscriptionsTask = dropTask(async () => {
    return await this.store.query('direct-debit-subscription', {
      filters: {
        status: [
          DIRECT_DEBIT_SUBSCRIPTION_STATUS.PENDING_MANDATE_SIGNATURE,
          DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED,
          DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS,
        ],
        schedule_type: [
          DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF,
          DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.WEEKLY,
          DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY,
          DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.QUARTERLY,
          DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.YEARLY,
          DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM,
        ],
      },
      page: 1,
      per_page: 1,
    });
  });

  fetchSDDCollectionsTask = dropTask(async () => {
    let allStatuses = [
      DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED,
      DIRECT_DEBIT_COLLECTION_STATUS.CANCELED,
      DIRECT_DEBIT_COLLECTION_STATUS.DECLINED,
      DIRECT_DEBIT_COLLECTION_STATUS.REFUNDED,
      DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD,
    ];

    return await this.store.query('direct-debit-collection', {
      organization_id: this.organizationManager.organization.id,
      filters: {
        status: allStatuses,
      },
      page: 1,
      per_page: 1,
    });
  });
}
