export default {
  "container": "qyH",
  "header-cell": "qyO caption-bold",
  "col-8": "qym",
  "table": "qyp",
  "cell": "qyx body-2",
  "cell-content": "qyw",
  "row": "qyN",
  "empty": "qyv",
  "quick-actions": "qyo",
  "card-infos": "qyn",
  "card-digits": "qyi",
  "empty-state": "qyA"
};
