import Controller from '@ember/controller';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class IntegrationController extends Controller {
  @service abilities;

  @reads('model') integration;

  get showDisclaimer() {
    let canOnlyReadSecretKey =
      this.abilities.can('view integration') && this.abilities.cannot('edit integration');

    return canOnlyReadSecretKey && !this.integration.secretKey;
  }

  get canRegenerate() {
    return this.abilities.can('edit integration');
  }

  regenerateTokenTask = dropTask(async () => {
    await this.integration.regenerateKey();
  });
}
