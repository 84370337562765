import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ProtectedRoute extends Route {
  @service organizationManager;
  @service refreshSessionManager;
  @service userManager;
  @service sessionManager;

  beforeModel(transition) {
    this.sessionManager.requireAuthentication(transition, 'signin');
  }

  async afterModel() {
    let { organizationManager, userManager } = this;

    let promises = [];
    if (!userManager.currentUser) {
      promises.push(userManager.setup());
    }
    if (!organizationManager.isDataLoaded) {
      promises.push(organizationManager.setupTask.perform());
    }
    await Promise.all(promises);
  }

  activate() {
    this.refreshSessionManager.startActivityTracking();
  }

  deactivate() {
    this.refreshSessionManager.cancelActivityTracking();
  }
}
