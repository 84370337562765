import Controller from '@ember/controller';

import { INTEGRATION_TYPES } from 'qonto/constants/connect';
import { ImageGallery } from 'qonto/react/components/connect/image-gallery';

export default class HubApplicationDetailsController extends Controller {
  imageGallery = ImageGallery;

  readMoreIsOpen = false;

  get integration() {
    return this.model.prismicData;
  }

  get integrationImages() {
    return this.integration.images.map(el => el.image);
  }

  get features() {
    let { features } = this.integration;
    let filledFeatures = features?.filter(({ feature_description }) =>
      feature_description.find(({ text, url }) => text || url)
    );

    return filledFeatures?.length
      ? filledFeatures.map(({ feature_description }) => feature_description)
      : null;
  }

  get isPartnership() {
    return this.integration.integration_type === INTEGRATION_TYPES.partnerships;
  }
}
