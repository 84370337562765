import Controller from '@ember/controller';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

export default class TeamsIndexController extends Controller {
  @service router;
  membersRoute = 'members';

  get canDeleteTeams() {
    return this.model.length > 1;
  }

  refreshModelTask = dropTask(async () => {
    await this.router.refresh('teams');
  });
}
