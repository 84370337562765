/* eslint-disable @qonto/no-import-roles-constants */
import { ROLES } from 'qonto/constants/membership';

export const memberEdit = {
  steps: {
    role: {
      componentName: 'member/role-selection',
      nextStepId: ({ role, showComparison }) => {
        let isManager = role?.key === ROLES.MANAGER;

        if (showComparison) {
          return 'compare';
        }

        if (isManager) {
          return 'scope';
        }

        return 'edit-role-success';
      },
    },
    compare: {
      componentName: 'member/compare-roles',
      nextStepId: ({ role, showComparison }) => {
        let isManager = role?.key === ROLES.MANAGER;

        if (showComparison) {
          return 'compare';
        }

        if (isManager) {
          return 'scope';
        }

        return 'edit-role-success';
      },
    },
    scope: {
      componentName: 'member/scope-selection',
      nextStepId: ({ member }) => {
        let { scope } = member.customPermissions;
        return scope === 'team' ? 'account-management-team' : 'account-management-organization';
      },
    },
    'account-management-team': {
      componentName: 'member/account-management/team-scope',
      nextStepId: ({ hasAllowedBankAccountsError }, stepList) => {
        let firstStepId = stepList[0].id;

        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }
        return firstStepId === 'account-management-team'
          ? 'edit-role-success'
          : 'expense-permissions';
      },
    },
    'account-management-organization': {
      componentName: 'member/account-management/organization-scope',
      nextStepId: ({ hasAllowedBankAccountsError }, stepList) => {
        let firstStepId = stepList[0].id;
        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }
        return firstStepId === 'account-management-organization'
          ? 'edit-role-success'
          : 'expense-permissions';
      },
    },
    'expense-permissions': {
      componentName: 'member/expense-permissions',
      nextStepId: (_, stepList) => {
        let firstStepId = stepList[0].id;

        if (firstStepId !== 'expense-permissions') {
          return 'other-permissions';
        }
      },
    },
    'other-permissions': {
      componentName: 'member/other-permissions',
      nextStepId: ({ hasAllowedBankAccountsError }, stepsList) => {
        let firstStepId = stepsList[0].id;

        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }

        if (firstStepId === 'role') {
          return 'edit-role-success';
        }

        return 'edit-scope-success';
      },
    },
    'edit-role-success': {
      componentName: 'member/success/edit-role',
    },
    'edit-scope-success': {
      componentName: 'member/success/edit-scope',
    },
    'edit-allowed-bank-accounts-error': {
      componentName: 'member/error/allowed-bank-accounts',
    },
  },
  options: {
    enablePersistence: true,
  },
};
