/* eslint-disable unicorn/prefer-at */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { DEFAULT_ORIGIN_URL } from 'qonto/constants/bank-account';
import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { aggregateSelectedAttributes } from 'qonto/utils/remunerated-account';

export default class AccountsController extends Controller {
  @service abilities;
  @service featuresManager;
  @service flowLinkManager;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service subscriptionManager;

  queryParams = ['scroll'];

  @tracked scroll = false;
  @tracked scrolledTop = true;

  @reads('organization.sortedBankAccounts') sortedAccounts;
  @reads('organization.authorizedBalance') currentAccountsBalance;

  get organization() {
    return this.model.organization;
  }

  get currentAccountsBalanceAmount() {
    if (
      this.currentInternalandExternalAccounts.length < 2 ||
      (this.model.currentAccounts.length &&
        !this.model.remuneratedAccounts.length &&
        !this.model.wealthAccounts.length &&
        !this.model.otherAccounts.length)
    ) {
      return null;
    } else return this.model.balances.current;
  }

  get remuneratedAccountsBalanceAmount() {
    return this.model.remuneratedAccounts.length < 2 ? null : this.model.balances.remunerated;
  }

  get wealthAccountsBalanceAmount() {
    return this.model.wealthAccounts.length < 2 ? null : this.model.balances.wealth;
  }

  get otherAccountsBalanceAmount() {
    return this.model.otherAccounts.length < 2 ? null : this.model.balances.other;
  }

  get currentInternalandExternalAccounts() {
    return this.model.currentAccounts
      .filter(({ status }) => status === 'active' || status === 'pending')
      .sort((a, b) => (b.main === a.main ? 0 : a.main ? -1 : 1));
  }

  get remuneratedAccounts() {
    let internals = [];
    let externals = [];

    this.model.remuneratedAccounts
      .filter(({ status }) => status !== 'closed')
      .map(remuneratedAccount =>
        remuneratedAccount.coreBankingSystem === 'qonto'
          ? internals.push(remuneratedAccount)
          : externals.push(remuneratedAccount)
      );

    internals = internals.map(account => {
      let remuneratedAccountDetails = this.remuneratedAccountsDetails.find(
        ({ id }) => id === account.id
      );

      return {
        account,
        remuneratedAccountDetails,
        displayProperties: aggregateSelectedAttributes(account, remuneratedAccountDetails),
      };
    });

    return { internals, externals };
  }

  get remuneratedAccountsDetails() {
    return this.model.remuneratedAccountsTask?.lastSuccessful?.value.remuneratedAccounts || [];
  }

  get currentAccounts() {
    return this.sortedAccounts.filter(({ isRemunerated }) => !isRemunerated);
  }

  get activeOrPendingCurrentAccounts() {
    return this.currentAccounts.filter(({ status }) => status === 'active' || status === 'pending');
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('multi_accounts');
  }

  get canUpsellOrTrial() {
    return this.abilities.can('upsell bank-account') || this.trialInfo;
  }

  get canTopUp() {
    return (
      this.abilities.can('resume topup onboarding') &&
      this.abilities.cannot('view get started actions onboarding')
    );
  }

  get isShowingRecommendationCard() {
    return this.currentAccounts.length <= 1 && this.organizationManager.organization.kybAccepted;
  }

  get shouldBankAccountCardDisplayStartVerification() {
    let { organization, membership } = this.organizationManager;

    return membership.shouldSubmitKyc && organization.kybPending;
  }

  @action onCreateAccount() {
    this.segment.track('accounts_new_account_clicked', { origin: DEFAULT_ORIGIN_URL });
    this.router.transitionTo('accounts.new', {
      queryParams: { origin: DEFAULT_ORIGIN_URL },
    });
  }

  @action navigateToDiscoverMultiAccounts(recommendedPlan) {
    let { role } = this.organizationManager.membership;
    this.segment.track('upsell_multi-account_clicked', {
      role,
      trial_state: TRACKING_TRIAL_STATE.NONE,
      price_plan: this.subscriptionManager.currentPricePlan.code,
    });

    if (variation('feature--boolean-optimized-upsell')) {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
        queryParams: {
          plan: recommendedPlan?.code,
          recurrence: this.subscriptionManager.currentSubscription?.recurrence,
          upsellTrigger: 'multiAccounts',
        },
      });
    } else {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'discover-multi-accounts',
        queryParams: { upsellTrigger: 'multiAccounts' },
      });
    }
  }

  @action handleCardTopupRedirection() {
    this.router.transitionTo('onboarding.topup.amount', this.organizationManager.organization.id);
  }

  @action maybeScrollToLast(element) {
    if (this.scroll) {
      // reset query param again to make the URL pretty
      this.scroll = false;

      let { children } = element;
      if (children.length > 2) {
        children[children.length - 2]?.scrollIntoView();
      }
    }
  }

  @action updateScrolledTop(value) {
    this.scrolledTop = value;
  }

  @action redirectToManageConnection(account) {
    if (!account.provider?.connection?.id) return;
    this.router.transitionTo('settings.connections.banks', {
      queryParams: { highlight: account.provider.connection.id },
    });
  }

  showRenameAccountModalTask = task(async account => {
    this.showActionsMenu = false;

    let modal;
    try {
      modal = this.modals.open('bank-accounts/account-rename-modal', {
        account,
      });
      await modal;
    } finally {
      modal.close();
    }
  });

  showCloseAccountModalTask = task(async (account, remuneratedAccountDetails) => {
    this.showActionsMenu = false;

    let modal;
    try {
      modal = this.modals.open('bank-accounts/account-close-modal', {
        bankAccount: account,
        remuneratedAccountDetails,
      });
      let { slug, accountType } = account;
      this.segment.track('bank_account_close_clicked', {
        slug,
        account_type: accountType,
      });
      await modal;
    } finally {
      modal.close();
    }
  });
}
