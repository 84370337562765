/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { MFA_METHODS, MFA_MODAL_ORIGINS } from '@qonto/qonto-sca/constants/mfa';
import { reads } from 'macro-decorators';

import { ROLES } from 'qonto/constants/membership';

export default class SettingsPersonalSecurityController extends Controller {
  @service deviceManager;
  @service modals;
  @service organizationManager;
  @service segment;

  @reads('model.user') user;
  @reads('model.devices') devices;

  /* The proper condition to be eligible for a 2FA should be user has at least 1 non-accountant
   * Since we cannot solve it with a low cost solution, for now we decided to keep the role-check
   * https://qonto.slack.com/archives/C035AQMG41Y/p1649859395120139
   */
  get isAccountant() {
    return this.organizationManager.membership.role === ROLES.REPORTING;
  }

  get isSmsOtpEnabled() {
    return this.user.mfaMethod === MFA_METHODS.SMS;
  }

  @action openOtpSetupModal() {
    this.modals.open('popup/security/mfa', {
      origin: MFA_MODAL_ORIGINS.SETTINGS,
    });
  }

  @action openChangePasswordModal() {
    this.segment.track('change_password_clicked');
    this.modals.open('update-password-modal', {
      user: this.user,
    });
  }
}
