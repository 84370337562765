import Controller from '@ember/controller';
import { service } from '@ember/service';

import { task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import LOCALE_ARRAY from 'qonto/constants/locales';

export default class SettingsPersonalLanguageController extends Controller {
  @service userManager;
  @service localeManager;
  @service toastFlashMessages;
  @service bannerFlashMessages;
  @service banners;
  @service intl;
  @service menu;

  @reads('userManager.currentUser') user;

  get localesArray() {
    if (variation('feature--portuguese-translation')) {
      return LOCALE_ARRAY;
    } else {
      return LOCALE_ARRAY.filter(locale => locale.code !== 'pt');
    }
  }

  updateUserTask = task(async () => {
    let user = this.user;
    let updatedKeys = user.changedAttributes();

    await user.save();

    if ('locale' in updatedKeys) {
      await this.localeManager.setLocale(user.locale);
      this.bannerFlashMessages.clearTopBannerMessages();
      this.banners.triggerBanners();
    }

    let message = this.intl.t('toasts.user_profile_updated');
    this.toastFlashMessages.toastInfo(message);

    if (this.menu.breadcrumbsTrail.length > 0) {
      this.menu.updateBreadcrumbs([this.intl.t('dual-nav.main.settings')]);
    }
  });
}
