import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class InsuranceHubIndexRoute extends Route {
  @service organizationManager;
  @service prismic;
  @service segment;

  async model() {
    let segments = await this.prismic.get({
      predicates: [
        this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_segment'),
        this.prismic.prismicLibrary.predicate.fulltext(
          'my.insurance_segment.legal_sectors',
          this.organizationManager.organization.legalSector
        ),
      ],
    });

    let segmentId = segments.results?.[0]?.id;

    let predicates = [
      this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_offer'),
      this.prismic.prismicLibrary.predicate.at('document.tags', [
        `market_${this.organizationManager.organization.legalCountry.toLowerCase()}`,
      ]),
    ];

    if (segmentId) {
      predicates.push(
        this.prismic.prismicLibrary.predicate.at('my.insurance_offer.segments.segment', segmentId)
      );
    }

    return await this.prismic.get({
      predicates,
      orderings: {
        field: 'my.insurance_offer.uid',
      },
      fetchLinks: ['insurance_risk.name'],
    });
  }

  afterModel() {
    this.segment.track('insurance-hub_displayed');
  }
}
