export default {
  "dropdown-icon": "qjP",
  "dropdown": "qjl",
  "download-button": "qjX",
  "connections-banner": "qjC",
  "recommendation-cards": "qjk",
  "wrapper-with-cards": "qjJ",
  "transactions-wrapper": "qjs",
  "separator": "qjF",
  "with-transaction": "qjg",
  "annual-billing-banner": "qjD",
  "mt-132": "qjV",
  "transactions-sidebar": "qjL",
  "empty-state-wrapper": "qjT"
};
