export default {
  "container": "qGW",
  "link-grey": "qGu",
  "slack-section-title": "qrS title-4 mb-8",
  "edit-channel-text": "qrc body-2 mb-16",
  "rule-section-title": "qrq title-4 mb-16",
  "rule-cards-container": "qrZ",
  "edit-slack-container": "qrR",
  "rule-card": "qrQ",
  "add-rule-container": "qre",
  "add-rule-link-button": "qrB",
  "add-rule-text": "qrE",
  "empty-state": "qrd",
  "empty-state-textblock": "qrI",
  "empty-state-title": "qrt title-2",
  "empty-state-description": "qrM body-2",
  "empty-state-illustration": "qrP",
  "rules": "qrl"
};
