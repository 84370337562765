export default {
  "container": "qhO",
  "form-container": "qhm",
  "title": "qhp",
  "title-border": "qhx",
  "tabs-container": "qhw",
  "preview-container": "qhN",
  "email-preview-container": "qhv",
  "close-button": "qho btn btn--icon-only btn--tertiary btn--large"
};
