import Controller from '@ember/controller';
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { service } from '@ember/service';

import { LottiePlayer } from 'design-system-kit';
import { variation } from 'ember-launch-darkly';

import { STATUS as RECEIVABLE_INVOICE_STATUS } from 'qonto/constants/receivable-invoice';
import {
  BASE_FLOW_STATUSES,
  BASE_INSIGHTS,
  INSIGHT_LABELS,
} from 'qonto/constants/supplier-invoice';
import { getFilterGroupExpressions } from 'qonto/utils/search-preset';

const ILLUSTRATIONS = {
  DE: '/illustrations/qonto-hub/connect_DE.svg',
  ES: '/illustrations/qonto-hub/connect_ES_IT.svg',
  FR: '/illustrations/qonto-hub/connect_FR.svg',
  IT: '/illustrations/qonto-hub/connect_ES_IT.svg',
};

export default class AccountingHubDetailsController extends Controller {
  lottiePlayer = LottiePlayer;

  @service abilities;
  @service intl;
  @service segment;
  @service sentry;

  get organizationAccountingDetails() {
    return this.model.fetchOrganizationAccountingDetailsTask.last.value;
  }

  get illustration() {
    return ILLUSTRATIONS[this.model.organization.legalCountry];
  }

  get showIntegrations() {
    return (
      this.organizationAccountingDetails.integration &&
      this.abilities.can('access integrations in accounting-hub') &&
      !this.organizationAccountingDetails.solutionInstance?.isEnabled
    );
  }

  get presetTranslations() {
    return {
      missing_receipts: this.intl.t('transactions.filters.presets.items.missing_receipts'),
      settled_last_month: this.intl.t('transactions.filters.presets.items.settled_last_month'),
      to_verify: this.intl.t('transactions.filters.presets.items.to_verify'),
    };
  }

  get insightLabels() {
    return variation('feature-invoices-prioritize-payments') ? BASE_INSIGHTS : BASE_FLOW_STATUSES;
  }

  get hasExportableSupplierInvoices() {
    let insights = this.organizationAccountingDetails.supplierInvoicesInsights;
    return [
      INSIGHT_LABELS.pendingCounter,
      INSIGHT_LABELS.inboxCounter,
      INSIGHT_LABELS.scheduledCounter,
      INSIGHT_LABELS.paidCounter,
      ...this.insightLabels,
    ].some(key => insights[key] > 0);
  }

  get hasExportableReceivableInvoices() {
    let createdInvoices = this.organizationAccountingDetails.receivableInvoicesStats?.created;
    if (!createdInvoices) {
      return false;
    }

    return [
      createdInvoices[RECEIVABLE_INVOICE_STATUS.UNPAID],
      createdInvoices[RECEIVABLE_INVOICE_STATUS.CANCELED],
      createdInvoices[RECEIVABLE_INVOICE_STATUS.PAID],
    ].some(status => status > 0);
  }

  @action
  trackAccountingLabel(preset) {
    let filterGroupsExpressions = [preset.query.filter_group];

    let filtersProperties = getFilterGroupExpressions(
      filterGroupsExpressions,
      this.model.organization.labelLists
    );

    this.segment.track('accounting-hub_view_label_clicked', { filter_types: filtersProperties });
  }

  @action
  handleRetryButtonClick() {
    getOwner(this).lookup('route:accounting-hub').refresh();
  }

  @action
  handleError(error) {
    // Here the error is a LottieError so we cannot use shouldSendToSentry
    if (Boolean(error.status) && error.status >= 400 && error.status < 500) {
      this.sentry.captureException(error);
    }
  }
}
