export default {
  "container": "qGN",
  "wrapper": "qGv",
  "grey-header-block": "qGo",
  "connect-app-header": "qGn",
  "connect-app-header-link": "qGi",
  "connect-app-header-content": "qGA",
  "connect-app-header-content-default": "qGY",
  "connect-app-header-content-left": "qGh",
  "button-block": "qGf",
  "connect-app-body": "qGK",
  "connect-app-body-left-content": "qGG",
  "connect-app-body-sidebar": "qGr"
};
