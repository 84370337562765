export const addon = {
  steps: {
    addons: {
      componentName: 'addon-change/addons',
      nextStepId: 'confirm',
    },
    confirm: {
      componentName: 'addon-change/confirm',
      nextStepId: 'success',
    },
    success: {
      componentName: 'addon-change/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
