import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';

export default class ApplicationController extends Controller {
  @service cookiesConsentManager;
  @service deviceManager;
  @service metricsManager;
  @service router;
  @service webviewManager;

  didomi = ENV.didomi;
  userStatusLastUpdated = '';

  get displayDidomi() {
    return (
      !this.webviewManager.isWebview ||
      (this.webviewManager.isWebview && !this.router.currentRouteName.includes('connect-hub'))
    );
  }

  @action onCookieConsentReady() {
    this.cookiesConsentManager.updateCookiesConsent();
    if (!this.cookiesConsentManager.shouldConsentBeCollected()) {
      this.metricsManager.activateMetricsAdapters();
    }

    this.userStatusLastUpdated = this.cookiesConsentManager.getUserStatusLastUpdated();
  }

  @action onCookieConsentUpdated() {
    this.cookiesConsentManager.updateCookiesConsent();
    this.metricsManager.activateMetricsAdapters();
  }

  @action onPreferencesClickSaveChoices() {
    if (this.userStatusLastUpdated !== this.cookiesConsentManager.getUserStatusLastUpdated()) {
      this.reloadPage();
    }
  }

  // we reload the page to download / unload scripts based on user cookie consent
  reloadPage() {
    window.location.reload();
  }
}
