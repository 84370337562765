/* eslint-disable @qonto/no-import-roles-constants */

import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { LOCAL_STORAGE_ORGANIZATION_KEY } from 'qonto/constants/accounting-hub';
import { ROLES } from 'qonto/constants/membership';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class AccountingHubRoute extends Route {
  @service router;
  @service store;
  @service abilities;
  @service userManager;
  @service organizationManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'bookkeeping');
    if (this.abilities.cannot('access accounting-hub')) {
      this.router.replaceWith('application');
    }
  }

  model() {
    let organizations = this.store
      .peekAll('organization')
      .filter(({ accessDisabled }) => !accessDisabled)
      .sort(sortByKey('name'));

    return organizations.reduce((acc, organization) => {
      let userMembershipForOrganization = organization.memberships.find(
        ({ user }) => this.userManager.currentUser.id === user.id
      );

      if (userMembershipForOrganization) {
        acc.push({ organization, role: userMembershipForOrganization.role });
      }

      return acc;
    }, []);
  }

  redirect(organizationsToRoleMap, transition) {
    let { organization_slug } = transition.to.params;

    if (organization_slug) {
      this.router.replaceWith('accounting-hub.details', organization_slug);
      return;
    }

    let organizationToDisplay = this._findOrganizationToDisplay(organizationsToRoleMap);

    if (organizationToDisplay) {
      this.router.replaceWith('accounting-hub.details', organizationToDisplay.slug);
    } else {
      let defaultOrganization = this.organizationManager.getDefaultOrganization();
      this.router.replaceWith('overview', defaultOrganization.slug);
    }
  }

  _findOrganizationToDisplay(organizationsToRoleMap) {
    let roles = [ROLES.ADMIN, ROLES.OWNER, ROLES.REPORTING];
    let localStorageSlug = safeLocalStorage.getItem(LOCAL_STORAGE_ORGANIZATION_KEY);

    if (localStorageSlug) {
      let result = organizationsToRoleMap.find(
        ({ organization, role }) => organization.slug === localStorageSlug && roles.includes(role)
      );

      if (result) {
        return result?.organization;
      }
    }

    return organizationsToRoleMap.find(({ role }) => roles.includes(role))?.organization;
  }
}
