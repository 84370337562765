import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import NewInvoiceSubscriptionRoute from 'qonto/routes/invoice-subscriptions/new/route';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class EditInvoiceSubscriptionRoute extends NewInvoiceSubscriptionRoute {
  @service store;

  async model({ id }, transition) {
    // Fetch organization avatar
    await this.organization.getAvatar();

    // Prefetch customers
    await this.store.query('customer', {
      filter: { organization_id: this.organization.id },
    });

    if (variation('feature--boolean-client-hub')) {
      await this.fetchClientsTask.perform().catch(ignoreCancelation);
    }

    // Prefetch all items
    let documentItems = await this.fetchDocumentItemsTask.perform().catch(ignoreCancelation);

    let subscription;

    // when coming from the settings modal, there might be already one recorded subscription in the store
    // instead of initializing a new one, the user will see the started one in the form
    if (
      (transition?.from?.name === 'invoicing-settings' ||
        transition?.from?.params?.name === 'sdd-activation') &&
      this.peekRecordedSubscriptions.length > 0
    ) {
      // only one newly created subscription can be expected inside the array
      subscription = this.peekRecordedSubscriptions[0];

      // the fields need to be the latest one
      subscription.header = this.isGermanOrganization ? this.settings.invoiceHeader : undefined;
      subscription.footer = this.isGermanOrganization ? this.settings.invoiceFooter : undefined;
    } else {
      subscription = await this.store.findRecord('invoice-subscription', id);
    }

    let client = this.store.peekRecord('client-hub', subscription.belongsTo('customer').id());
    if (variation('feature--boolean-client-hub') && client) {
      subscription.customer = client;
    }

    // the email needs to be the latest one
    subscription.contactEmail = this.settings.contactEmail;

    if (variation('feature--boolean-recurring-invoices-with-sdd')) {
      let sddActivation = this.fetchSddActivation();

      return {
        directDebitMandate: await subscription.directDebitCollectionMandate,
        subscriptionCustomerId: subscription.customer.id,
        sddActivation,
        subscription,
        documentItems,
        settings: this.settings,
      };
    }
    return {
      directDebitMandate: await subscription.directDebitCollectionMandate,
      subscriptionCustomerId: subscription.customer.id,
      subscription,
      documentItems,
      settings: this.settings,
    };
  }
}
