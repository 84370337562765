export default {
  "page-container": "qnn",
  "form": "qni",
  "close-button": "qnA btn btn--icon-only btn--tertiary btn--large",
  "preview": "qnY",
  "preview-padding": "qnh",
  "title": "qnf",
  "header": "qnK",
  "without-tabs": "qnG",
  "form-section": "qnr",
  "form-footer": "qnb",
  "disclaimer-text": "qny"
};
