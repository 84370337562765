import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import dayjs from 'dayjs';
import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { COMMON_CREDIT_PERIODS } from 'qonto/components/receivable-invoices/form/due-date-selector';
import CURRENCIES from 'qonto/constants/currencies';
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { MIGRATION_CUT_OFF_DATE } from 'qonto/constants/products';
import { defaultValues } from 'qonto/models/receivable-invoice/item';
import { differenceInCalendar } from 'qonto/utils/date';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class ReceivableInvoicesNewRoute extends Route {
  @service abilities;
  @service toastFlashMessages;
  @service flowLinkManager;
  @service intl;
  @service localeManager;
  @service menu;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;
  @service productsCatalog;

  activate() {
    this.menu.hide();
  }

  async beforeModel({ to, from }) {
    let organization = this.organizationManager.organization;
    let membership = this.organizationManager.membership;

    if (membership.shouldSubmitKyc && organization.kybPending) {
      return this.router.replaceWith('kyc.intro', {
        queryParams: {
          redirectRoute: 'receivable-invoices',
        },
      });
    }

    let { quoteId } = to.queryParams;
    let canReadInvoice = this.abilities.can('read receivableInvoice');

    if (canReadInvoice) {
      let { organization } = this.organizationManager;

      if (organization.legalCountry === 'IT') {
        let eInvoiceActivation = await this.store.findRecord(
          'e-invoice-activation',
          organization.id
        );
        if (!eInvoiceActivation.eInvoicingActivated) {
          this.flowLinkManager.transitionTo({
            name: 'invoice-onboarding',
            stepId: 'einvoice-activation',
            queryParams: { destination: 'new', quoteId, abortFallback: from.name },
          });
        }
      }
    }
  }

  async model(params, transition) {
    let { organization } = this.organizationManager;
    let { locale } = this.localeManager;

    let { quoteId, customerId } = params;

    let termsAndConditions,
      lastNumber = '',
      lastCreditPeriod,
      lastInvoice,
      nextNumber,
      settings;

    // load customers into memory, will use peekAll later to display,
    await this.store.query('customer', { filter: { organization_id: organization.id } });

    let documentItems = await this.fetchDocumentItemsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => this.ignoreNotFoundAndHandleError(error));

    if (variation('feature--boolean-client-hub')) {
      await this.fetchClientsTask.perform().catch(ignoreCancelation).catch(this.handleError);
    }

    try {
      settings = await this.store.findRecord('receivable-invoices-settings', organization.id);
      nextNumber =
        settings?.numberingMode === 'automatic'
          ? settings.invoiceNextNumberFormatted || settings.nextInvoiceNumber
          : '';

      settings.contactEmail = settings.contactEmail || this.organizationManager.membership.email;
    } catch (error) {
      if (
        ErrorInfo.for(error).shouldSendToSentry &&
        !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
      ) {
        this.sentry.captureException(error);
      }

      this._redirectOnError();
    }

    try {
      await this.fetchOrganizationAvatarTask.perform(organization);
    } catch (error) {
      this.handleError(error);
    }

    let item = this.store.createRecord('receivable-invoice/item', defaultValues(this));

    try {
      lastInvoice = await this.store.modelFor('receivable-invoice').last(this.store);
      termsAndConditions = lastInvoice.termsAndConditions;
      lastNumber = lastInvoice.number;
      item.vatRate = lastInvoice.items[0]?.vatRate;
      lastCreditPeriod = lastInvoice
        ? differenceInCalendar(lastInvoice.dueDate, lastInvoice.issueDate, 'day')
        : 15;
    } catch (error) {
      // we expect 404 if no last invoice
      if (
        ErrorInfo.for(error).shouldSendToSentry &&
        !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
      ) {
        this.sentry.captureException(error);
      }
    }

    let isFirstInvoice = Boolean(!lastInvoice);

    let welfareFund = this.store.createRecord('receivable-invoice/welfare-fund');
    let withholdingTax = this.store.createRecord('receivable-invoice/withholding-tax');
    let stampDutyAmount;
    let currency = CURRENCIES.default;

    let quote,
      customer,
      header,
      footer,
      performanceDate,
      items = [];
    let isFromQuote = false;

    let isGermanTemplate = !quoteId && organization.legalCountry === 'DE';

    if (customerId) {
      customer = this.store.peekRecord('client-hub', customerId);
    }

    if (quoteId) {
      quote = await this.store.findRecord('quote', quoteId);
      if (quote) {
        customer = await this.store.peekRecord('client-hub', quote.customerSnapshot.id);
        termsAndConditions = quote.termsAndConditions;
        currency = customer?.currency || quote.currency || CURRENCIES.default;
        quote.items.forEach(item => {
          let {
            title,
            description,
            quantity,
            unitPrice,
            vatRate,
            vatExemptionCode,
            discount,
            unit,
            productId,
            links,
            type,
          } = item;
          let invoiceItem = this.store.createRecord('receivable-invoice/item', {
            title,
            description,
            quantity,
            unitPrice,
            vatRate,
            vatExemptionCode,
            discount,
            unit,
            productId,
            links,
            type,
          });
          items = [...items, invoiceItem];
        });
        isFromQuote = true;
      }
      if (organization.legalCountry !== 'DE') {
        header = quote.header;
      }
    } else if (isGermanTemplate) {
      performanceDate = dayjs().format(DATE_PICKER_FIELD_FORMAT);
      header = settings.invoiceHeader;
      footer = settings.invoiceFooter;
    }

    if (organization.legalCountry === 'IT' && (lastInvoice || quote)) {
      welfareFund.rate = quote?.welfareFund?.rate || lastInvoice?.welfareFund?.rate;
      welfareFund.type = quote?.welfareFund?.type || lastInvoice?.welfareFund?.type;
      withholdingTax.rate = quote?.withholdingTax?.rate || lastInvoice?.withholdingTax?.rate;
      withholdingTax.reason = quote?.withholdingTax?.reason || lastInvoice?.withholdingTax?.reason;
      withholdingTax.type = quote?.withholdingTax?.type || lastInvoice?.withholdingTax?.type;
      stampDutyAmount = quote?.stampDutyAmount || lastInvoice?.stampDutyAmount;
      item.vatExemptionCode =
        quote?.items[0]?.vatExemptionCode || lastInvoice?.items[0]?.vatExemptionCode;
    }

    let payment;
    if (organization.legalCountry === 'IT') {
      if (lastInvoice) {
        payment = this.store.createRecord('receivable-invoice/payment', {
          conditions: lastInvoice.payment?.conditions,
          method: lastInvoice.payment?.method,
        });
      } else {
        payment = this.store.createRecord('receivable-invoice/payment', {
          conditions: 'TP02',
          method: 'MP05',
        });
      }
    } else {
      payment = this.store.createRecord('receivable-invoice/payment');
    }

    if (settings) {
      organization.vatNumber = settings.vatNumber;
      organization.taxNumber = settings.taxNumber;
      organization.companyLeadership = settings.companyLeadership;
      organization.districtCourt = settings.districtCourt;
      organization.commercialRegisterNumber = settings.commercialRegisterNumber;
    }

    let invoice = {};

    // when coming from the settings modal, there might be already one recorded invoice in the store
    // instead of initializing a new one, the user will see the started one in the form
    if (transition?.from?.name === 'invoicing-settings' && this.peekRecordedInvoices.length > 0) {
      // only one newly created invoice can be expected inside the array
      invoice = this.peekRecordedInvoices[0];

      // the fields need to be the latest one
      invoice.contactEmail = settings.contactEmail;
      invoice.header = settings.invoiceHeader;
      invoice.footer = settings.invoiceFooter;
    } else {
      let freshInvoice = {
        header,
        footer,
        lastNumber,
        number: nextNumber,
        issueDate: dayjs().format(DATE_PICKER_FIELD_FORMAT),
        performanceDate,
        dueDate: dayjs()
          .add(COMMON_CREDIT_PERIODS.includes(lastCreditPeriod) ? lastCreditPeriod : 15, 'day')
          .format(DATE_PICKER_FIELD_FORMAT),
        termsAndConditions,
        organization,
        customer,
        status: 'unpaid',
        items: (items.length && items) || [item],
        contactEmail: settings.contactEmail,
        locale,
        welfareFund,
        withholdingTax,
        payment,
        stampDutyAmount,
        currency,
        quote,
      };

      invoice = this.store.createRecord('receivable-invoice', freshInvoice);
    }

    await this.productsCatalog.fetchMigrationStatusTask
      .perform()
      .catch(ignoreCancelation)
      .catch(this.handleError);

    return {
      invoice,
      settings,
      isFirstInvoice,
      isFromQuote,
      documentItems,
    };
  }

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  fetchDocumentItemsTask = restartableTask(async () => {
    let documentItemsParams = {
      filter: {
        document_types: 'invoices,quotes,credit_notes',
        created_at_to: variation('feature-invoices-catalog-of-items')
          ? MIGRATION_CUT_OFF_DATE
          : undefined,
      },
      page: { size: 200, number: 1 },
      sort: '-total_documents,title,-unit_price',
    };
    let documentItems = await this.store.query('document-item', documentItemsParams);
    let total = documentItems.meta.total;

    while (total > documentItems.length) {
      documentItemsParams.page.number++;
      let nextDocumentItems = await this.store.query('document-item', documentItemsParams);
      documentItems = documentItems.concat(nextDocumentItems);
    }

    return documentItems;
  });

  @action
  willTransition(transition) {
    if (
      !transition.targetName.includes('receivable-invoices.new') &&
      !transition.targetName.includes('invoicing-settings')
    ) {
      let { invoice } = this.context;
      invoice.rollbackAttributes();
    }
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  get peekRecordedInvoices() {
    // peek in the store the already created but not saved invoice without an id
    return this.store
      .peekAll('receivable-invoice')
      .filter(invoice => invoice.isNew && invoice.id === null);
  }

  ignoreNotFoundAndHandleError(error) {
    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.handleError(error);
    }
  }

  @action
  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.quoteId = null;
      controller.customerId = null;
      controller.origin = null;
    }
  }

  deactivate() {
    this.menu.show();
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    return this.router.transitionTo('receivable-invoices.index');
  }
}
