import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class OverviewController extends Controller {
  queryParams = [{ bankAccounts: 'bank-accounts' }];

  @service router;
  @service abilities;
  @service segment;
  @service featuresManager;

  get createTransfer() {
    let isQontoPilotFeatureEnabled = this.featuresManager.isEnabled('qontoPilot');
    let canUseQontoPilot = this.abilities.can('use qonto-pilot');
    let canCreateTransfer = this.abilities.can('create transfer');

    if (isQontoPilotFeatureEnabled) {
      if (canUseQontoPilot) {
        return false;
      } else {
        return canCreateTransfer && !canUseQontoPilot;
      }
    } else {
      return canCreateTransfer;
    }
  }

  @action
  redirectToTransferPage() {
    this.segment.track('overview_create_transfer_clicked');
    return this.router.transitionTo('transfers.new');
  }
}
