import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { MIGRATION_CUT_OFF_DATE } from 'qonto/constants/products';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ProductsIndexRoute extends Route {
  @service store;
  @service sentry;
  @service intl;
  @service toastFlashMessages;
  @service networkManager;
  @service productsCatalog;

  queryParams = {
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
    query: { refreshModel: true },
  };

  model(params) {
    this.fetchProductsTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => this._handleError(error));

    this.fetchDocumentItemsTask.perform().catch(ignoreCancelation);

    this.productsCatalog.fetchMigrationStatusTask.perform().catch(ignoreCancelation);

    return {
      productsTask: this.fetchProductsTask,
      documentItemsTask: this.fetchDocumentItemsTask,
      migrationStatusTask: this.productsCatalog.fetchMigrationStatusTask,
    };
  }

  fetchProductsTask = restartableTask(async params => {
    let products = [];
    let { page, perPage, sortBy, query } = params;

    products = await this.store.query('product', {
      page: { number: page, size: perPage },
      sort_by: sortBy,
      query,
    });

    return {
      products,
      meta: {
        total_count: products?.meta?.total_count ?? 0,
        total_pages: products?.meta?.total_pages ?? 0,
        per_page: perPage,
        current_page: page,
      },
    };
  });

  fetchDocumentItemsTask = restartableTask(async () => {
    let documentItemsParams = {
      filter: {
        document_types: 'invoices,quotes,credit_notes',
        created_at_to: variation('feature-invoices-catalog-of-items')
          ? MIGRATION_CUT_OFF_DATE
          : undefined,
      },
      page: { size: 200, number: 1 },
      sort: '-total_documents,title,-unit_price',
    };

    let documentItems = await this.store.query('document-item', documentItemsParams);
    let total = documentItems.meta.total;

    while (total > documentItems.length) {
      documentItemsParams.page.number++;
      let nextDocumentItems = await this.store.query('document-item', documentItemsParams);
      documentItems = documentItems.concat(nextDocumentItems);
    }

    return documentItems;
  });

  resetController(controller) {
    controller.page = 1;
    controller.perPage = 25;
    controller.sortBy = 'title:asc';
    controller.query = '';
  }

  _handleError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
