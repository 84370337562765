import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { reads } from 'macro-decorators';

export default class CapitalDepositController extends Controller {
  @service capitalIncreaseManager;
  @service router;
  @service featuresManager;
  @service localeManager;
  @service organizationManager;

  @reads('featuresManager.flags.qontoNotary') isQontoNotaryEnabled;
  @reads('capitalIncreaseManager.hasIban') hasIban;
  @reads('capitalIncreaseManager.isDepositRequestSent') isDepositRequestSent;

  get isFastTrackDepositCapitalFlowType() {
    return this.organizationManager.organization.isFastTrackDepositCapitalFlowType;
  }

  @action
  handleNext() {
    this.router.transitionTo('capital.reviewed');
  }
}
