import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class BudgetsUpgradePlan extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    if (this.abilities.can('use budget')) {
      return this.router.transitionTo('budgets');
    }
  }
}
