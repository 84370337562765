export default {
  "container": "qfj",
  "form-container": "qfW",
  "title": "qfu",
  "title-border": "qKS",
  "tabs-container": "qKc",
  "preview-container": "qKq",
  "email-preview-container": "qKZ",
  "close-button": "qKR btn btn--icon-only btn--tertiary btn--large"
};
