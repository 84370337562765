export default {
  "guests": "qvW",
  "guests--upsell-screen": "qvu",
  "no-members": "qoS",
  "teams-header": "qoc",
  "empty": "qoq",
  "invite-text": "qoZ",
  "body": "qoR",
  "members": "qoQ",
  "members-list": "qoe",
  "members-title": "qoB caption-bold",
  "member": "qoE",
  "details": "qod"
};
