export default {
  "accounts": "qme",
  "header-subtitle": "qmB title-4",
  "subtitle-container": "qmE",
  "subtitle": "qmd title-3 mb-16",
  "amount": "qmI body-2",
  "list--cashbeeSavings": "qmt",
  "connections-banner": "qmM",
  "list": "qmP",
  "icon": "qml",
  "badge": "qmX",
  "discover-tile": "qmC"
};
