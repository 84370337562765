export default {
  "splash-landing": "qpo",
  "container": "qpn",
  "options": "qpi",
  "physical": "qpA",
  "card-label": "qpY",
  "physical-cta-container": "qph",
  "physical-cta": "qpf",
  "coming-soon": "qpK"
};
