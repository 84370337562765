export default {
  "details": "qiV",
  "header": "qiL",
  "avatar": "qiT mr-16",
  "header-beneficiary": "qiz",
  "header-beneficiary-activity": "qia",
  "header-beneficiary-details": "qiH",
  "header-beneficiary-details-recurring": "qiO",
  "infos": "qim",
  "infos-title": "qip",
  "infos-empty": "qix",
  "infos-list": "qiw",
  "infos-list-item": "qiN",
  "infos-list-item-left": "qiv",
  "infos-list-item-right": "qio",
  "infos-list-item-attachment": "qin",
  "infos-transactions": "qii",
  "actions": "qiA"
};
