export default {
  "container": "qYE",
  "content": "qYd",
  "logos": "qYI",
  "logo": "qYt",
  "animation": "qYM",
  "title": "qYP title-2 mb-32",
  "avatar": "qYl",
  "dropdown": "qYX",
  "disabled": "qYC",
  "organization": "qYk",
  "actions": "qYJ"
};
