import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { restartableTask } from 'ember-concurrency';

import { DEFAULT_SEARCH_INCLUDES, DEFAULT_SORT_BY } from 'qonto/constants/transactions';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class OverviewRoute extends Route {
  @service abilities;
  @service homePage;
  @service sentry;
  @service store;
  @service segment;
  @service organizationManager;
  @service periodicUpdate;

  queryParams = {
    bankAccounts: {
      refreshModel: true,
    },
  };

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'dashboard');
    if (this.abilities.cannot('view overview')) {
      return this.homePage.visitDefaultPage();
    }
  }

  async model({ bankAccounts }) {
    this.fetchTransactionsTask.perform({ bankAccounts }).catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });

    let updateProcess = await this.periodicUpdate.fetchUpdateProcess();

    return {
      updateProcess,
      bankAccounts,
      transactionsTask: this.fetchTransactionsTask,
    };
  }

  fetchTransactionsTask = restartableTask(async ({ bankAccounts } = {}) => {
    let [property, direction] = DEFAULT_SORT_BY.split(':');

    let bankAccountFilter;
    if (bankAccounts) {
      bankAccountFilter = { bank_account_ids: bankAccounts.split(',') };
    }

    let query = {
      includes: DEFAULT_SEARCH_INCLUDES,
      sort: { property, direction },
      pagination: { page: 1, per_page: 4 },
      organization_id: this.organizationManager.organization.id,
      ...bankAccountFilter,
    };

    let { transactions } = await this.store.adapterFor('transaction').search(query);

    return { transactions };
  });

  activate() {
    this.segment.track('overview_page_loaded');
  }
}
