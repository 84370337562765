export default {
  "team": "qAg",
  "no-members": "qAD",
  "teams-header": "qAV",
  "header-search": "qAL",
  "search-bar": "qAT",
  "invite": "qAz body-2",
  "invite-text": "qAa",
  "empty-illustration": "qAH",
  "empty-title": "qAO title-3",
  "empty-description": "qAm body-2",
  "pagination-footer": "qAp",
  "filter-search": "qAx",
  "fullsize": "qAw",
  "tabs-container": "qAN",
  "tabs-separator": "qAv"
};
