import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { DEFAULT_ORIGIN_URL } from 'qonto/constants/bank-account';
import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class AccountsNewController extends Controller {
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service subscriptionManager;

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('multi_accounts');
  }

  get currentAccountBadge() {
    if (this.trialInfo) {
      return {
        type: 'tryForFree',
        text: this.intl.t('promotions.system.promotional-card.free-trial.badge'),
      };
    }
    if (this.subscriptionManager.hasReachedBankAccountLimit) {
      return { type: 'upgrade', text: this.intl.t('upsell.discover.badge') };
    }
    return null;
  }

  get origin() {
    return this.router.currentRoute.queryParams?.origin || DEFAULT_ORIGIN_URL;
  }

  @action onTrialClick() {
    let { role } = this.organizationManager.membership;

    this.segment.track('upsell_multi-account_clicked', {
      role,
      trial_state: TRACKING_TRIAL_STATE.FREE_TRIAL,
      price_plan: this.subscriptionManager.currentPricePlan.code,
      origin: 'accounts_creation_page',
    });

    this.modals.open('discover/trial/confirm', {
      isFullScreenModal: true,
      trialInfo: this.trialInfo,
    });
  }

  @action
  handleClose() {
    this.transitionToRoute(this.origin);
  }

  get remuneratedInformation() {
    return this.model.remuneratedInformation;
  }
}
