import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { STATUS } from 'qonto/constants/invoice-subscriptions';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404, 401];

export default class InvoiceSubscriptionsIndexRoute extends Route {
  @service organizationManager;
  @service router;
  @service store;
  @service sentry;
  @service toastFlashMessages;
  @service intl;

  queryParams = {
    status: { refreshModel: true },
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
    highlighted: { refreshModel: false },
  };

  beforeModel({ to }) {
    if (!to.queryParams.status) {
      return this.router.replaceWith({
        queryParams: { status: `${STATUS.ACTIVE},${STATUS.SCHEDULED},${STATUS.SUSPENDED}` },
      });
    }
  }

  combinedSortParam(sortBy, defaultSortBy) {
    //If the sorting param is on the same field as the default param,
    //we don't need to add a secondary sort
    if (sortBy.replace(/^-/, '') === defaultSortBy.replace(/^-/, '')) {
      return sortBy;
    }
    return [sortBy, defaultSortBy].join(',');
  }

  model(params) {
    this.fetchSettingsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => this.handleError(error));

    this.fetchDataTask.perform(params).catch(ignoreCancelation);

    if (variation('feature--boolean-recurring-invoices-with-sdd')) {
      let sddActivation = this.fetchSddActivation();

      return {
        sddActivation,
        settingsTask: this.fetchSettingsTask,
        statsTask: this.fetchSubscriptionsStatsTask,
        subscriptionsTask: this.fetchSubscriptionsTask,
        fetchRelatedInvoicesTask: this.fetchRelatedInvoicesTask,
        fetchCustomerTask: this.fetchCustomerTask,
        refreshSidebarData: this.refreshSidebarData,
        fetchSubscriptionMandateTask: this.fetchSubscriptionMandateTask,
        fetchDataTask: this.fetchDataTask,
      };
    }

    return {
      settingsTask: this.fetchSettingsTask,
      statsTask: this.fetchSubscriptionsStatsTask,
      subscriptionsTask: this.fetchSubscriptionsTask,
      fetchRelatedInvoicesTask: this.fetchRelatedInvoicesTask,
      fetchCustomerTask: this.fetchCustomerTask,
      refreshSidebarData: this.refreshSidebarData,
      fetchDataTask: this.fetchDataTask,
      fetchSubscriptionMandateTask: this.fetchSubscriptionMandateTask,
    };
  }

  @action
  refreshSidebarData(id) {
    this.fetchRelatedInvoicesTask
      .perform(id)
      .catch(ignoreCancelation)
      .catch(this.handleRelatedInvoicesError);
    this.fetchCustomerTask.perform(id).catch(ignoreCancelation).catch(this.handleFetchError);
    this.fetchSubscriptionMandateTask
      .perform(id)
      .catch(ignoreCancelation)
      .catch(this.handleFetchError);
  }

  fetchCustomerTask = dropTask(async id => {
    return await this.store.peekRecord('invoice-subscription', id).get('customer');
  });

  fetchRelatedInvoicesTask = dropTask(async id => {
    let invoices = await this.store.query('receivable-invoice', {
      filter: {
        subscription_id: id,
      },
    });

    let lastInvoice = invoices.at(-1);

    if (lastInvoice?.directDebitEnabled) {
      let lastInvoiceSubscription = await lastInvoice.get('directDebitSubscription');
      return { invoices, lastInvoiceSubscription };
    }

    return { invoices, lastInvoiceSubscription: null };
  });

  @action
  handleRelatedInvoicesError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(
      this.intl.t('recurring-invoices.toasts.error.related-invoices')
    );
  }

  @action
  handleFetchError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }

  fetchSubscriptionsTask = dropTask(async subscriptionQueryParams => {
    let subscriptions = await this.store.query('invoice-subscription', subscriptionQueryParams);
    return {
      subscriptions: subscriptions ?? [],
      meta: {
        total_count: subscriptions?.meta.total ?? 0,
        total_pages: subscriptions?.meta.total
          ? subscriptions?.meta.total / subscriptionQueryParams.page.size
          : 1,
        per_page: subscriptionQueryParams.page.size,
        current_page: subscriptionQueryParams.page.number,
      },
    };
  });

  fetchSubscriptionMandateTask = dropTask(async id => {
    return await this.store
      .peekRecord('invoice-subscription', id)
      .get('directDebitCollectionMandate');
  });

  fetchSubscriptionsStatsTask = dropTask(async () => {
    return await this.store.adapterFor('invoice-subscription').getStats();
  });

  fetchSettingsTask = dropTask(async () => {
    let organizationId = this.organizationManager.organization.id;
    let settings = await this.store.findRecord('receivable-invoices-settings', organizationId);

    if (!settings.contactEmail) {
      settings.contactEmail = this.organizationManager.membership.email;
    }

    return settings;
  });

  fetchDataTask = dropTask(async params => {
    let { status, page, perPage, sortBy, highlighted } = params;

    let subscriptionQueryParams = {
      filter: { status },
      page: { number: page, size: perPage },
      sort: this.combinedSortParam(sortBy, 'next_invoice_date,-customers.name'),
    };

    await this.fetchSubscriptionsStatsTask.perform().catch(ignoreCancelation);
    await this.fetchSubscriptionsTask
      .perform(subscriptionQueryParams)
      .then(() => {
        if (highlighted) {
          this.fetchRelatedInvoicesTask
            .perform(highlighted)
            .catch(ignoreCancelation)
            .catch(this.handleRelatedInvoicesError);
          this.fetchCustomerTask
            .perform(highlighted)
            .catch(ignoreCancelation)
            .catch(this.handleFetchError);
          this.fetchSubscriptionMandateTask
            .perform(highlighted)
            .catch(ignoreCancelation)
            .catch(this.handleFetchError);
        }
      })
      .catch(ignoreCancelation)
      .catch(this.ignoreNotFoundAndHandleError);
  });

  fetchSddActivation() {
    let { sddActivation } = this.modelFor('invoice-subscriptions');

    return sddActivation;
  }

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }

  @action
  ignoreNotFoundAndHandleError(exception) {
    let { errors } = exception;
    if (
      errors?.some(error => {
        let isInvalidFilter = error?.source?.pointer.includes('/subscriptionfilters');
        let isInvalidSort = error?.source?.pointer.includes('/subscriptionsorts');
        return isInvalidFilter || isInvalidSort;
      })
    ) {
      return this.router.replaceWith({
        queryParams: { status: STATUS.DRAFT, sortBy: 'next_invoice_date,-customers.name' },
      });
    }

    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(exception.status)) {
      this.handleError(exception);
    }
  }
}
