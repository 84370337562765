export default {
  "container": "qfX",
  "form-container": "qfC",
  "preview-container": "qfk",
  "pdf-preview-container": "qfJ",
  "content": "qfs",
  "title": "qfF",
  "tabs-container": "qfg",
  "close-button": "qfD btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qfV",
  "header": "qfL",
  "without-tabs": "qfT"
};
