import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ONBOARDING_EVENTS_ORIGINS } from 'qonto/constants/onboarding';

export default class TopupAmountRoute extends Route {
  @service organizationManager;
  @service toastFlashMessages;
  @service segment;
  @service intl;
  @service router;

  beforeModel(transition) {
    let { topupsStatus } = this.organizationManager.membership;

    if (topupsStatus === 'completed') {
      return this.router.transitionTo('onboarding.topup.completed');
    }

    if (this.isKycCompleted(transition)) {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('on-boarding.top-up.add-amount.toasts.kyc-submitted')
      );

      this.updateKycQueryParams(transition);
    }
  }

  afterModel(model, transition) {
    let queryParams = transition?.to.queryParams;
    let origin = ONBOARDING_EVENTS_ORIGINS.includes(queryParams?.trackingEventOrigin)
      ? queryParams.trackingEventOrigin
      : 'other';

    this.segment.track('onboarding_top_up_landing', { origin });
  }

  isKycCompleted(transition) {
    let queryParams = transition?.to.queryParams;
    return queryParams && queryParams.kycCompleted;
  }

  updateKycQueryParams(transition) {
    let queryParams = transition?.to.queryParams;
    // Regarding the query parameters:
    // 1. We remove the `kycCompleted` query parameter to avoid the toast being displayed again on page refresh.
    // 2. We set the `isAfterKyc` query parameter to `true` to prevent displaying the top banner triggered by the `_kycUpdated` function.
    //   - This way, we avoid entirely hiding the top banner in this route, which might be needed in the future, and;
    //   - No changes will be needed in the `_kycUpdated` function, allowing it to work as expected in other routes.
    let newQueryParams = { ...queryParams, isAfterKyc: true, kycCompleted: undefined };
    this.router.replaceWith({ queryParams: newQueryParams });
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model);
    controller.set('_isKycCompleted', this.isKycCompleted(transition));
  }
}
