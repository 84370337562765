export default {
  "header": "qGk",
  "content": "qGJ",
  "read-only": "qGs",
  "document-collection-banner": "qGF",
  "submit-banner": "qGg",
  "review-banner": "qGD",
  "disclaimer": "qGV",
  "title": "qGL",
  "card-container": "qGT",
  "representatives-section": "qGz",
  "disabled-href": "qGa"
};
