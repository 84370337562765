import Controller from '@ember/controller';
import { service } from '@ember/service';

import { REQUEST_ORIGINS } from 'qonto/constants/requests';

export default class ReimbursementsIndexController extends Controller {
  @service abilities;
  @service intl;

  REIMBURSEMENTS_ORIGIN = REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE;

  get doesOrganizationSupportMileage() {
    return this.abilities.can('use mileages request');
  }

  get emptyStateOptions() {
    return {
      badge: { text: this.intl.t('requests.reimbursement.index.badge'), level: 'secondary' },
      title: this.intl.t('requests.reimbursement.index.title'),
      lottieSrc: '/lotties/empty-state/no-reimbursements-admin.json',
      subtitle: this.intl.t(
        this.doesOrganizationSupportMileage
          ? 'requests.reimbursement.index.subtitle.all'
          : 'requests.reimbursement.index.subtitle.expense-only'
      ),
    };
  }
}
