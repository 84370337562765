import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export class CardNewFlashDataContext {
  constructor({ card }) {
    this.card = card;
  }
}

export default class CardNewFlashFlowSetup {
  @service abilities;
  @service homePage;
  @service router;

  dataContext = null;

  constructor(owner, globalDataContext) {
    setOwner(this, owner);

    this.dataContext = new CardNewFlashDataContext({
      card: globalDataContext?.card,
    });
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('read card')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (
      this.abilities.cannot('access creation flow card') ||
      this.abilities.cannot('view flash card')
    ) {
      return this.router.replaceWith('cards');
    }
  }

  @action
  onAbort() {
    this.router.transitionTo('cards.index');
  }

  @action
  onComplete() {
    // DO NOTHING
    // (need to be set if the page is reloaded)
  }

  @action
  onSuccessToast() {
    // DO NOTHING
    // (need to be defined for secondary flows)
  }
}
