import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

export default class NewSupplierRoute extends Route {
  @service modals;
  @service intl;
  @service abilities;
  @service homePage;
  @service store;
  @service organizationManager;
  @service router;
  @service toastFlashMessages;

  supplier;
  modal;

  beforeModel() {
    if (this.abilities.cannot('update supplier')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  model() {
    this.supplier = this.store.createRecord('supplier', {
      countryCode: this.organizationManager.organization.legalCountry,
    });
    this.modal = this.modals.open(
      'suppliers/supplier-modal',
      {
        title: this.intl.t('supplier-invoices.supplier-creation.title'),
        description: this.intl.t('supplier-invoices.supplier-creation.description'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('supplier-invoices.supplier-creation.cta.add-supplier'),
        confirmTask: this.createSupplierTask,
        supplier: this.supplier,
        close: this.back,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: false,
        },
      }
    );

    return { supplier: this.supplier };
  }

  back() {}

  createSupplierTask = dropTask(async () => {
    try {
      await this.supplier.save();
      this.toastFlashMessages.toastSuccess(
        this.intl.t(
          'supplier-invoices.supplier-invoice-details.add-new-supplier.confirmation-toast'
        )
      );
      this.router.transitionTo('suppliers.index');
    } catch {
      // Validation will be handled in a separate MR
    }
  });

  deactivate() {
    this.modal.close();
  }
}
