export default {
  "container": "qAy",
  "content": "qAU",
  "logos": "qAj",
  "logo": "qAW",
  "animation": "qAu",
  "title": "qYS title-2 mb-32",
  "avatar": "qYc",
  "actions": "qYq mb-32",
  "footer": "qYZ body-2"
};
