/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';

import { dropTask, restartableTask } from 'ember-concurrency';

import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { getEmptyStateConfig } from 'qonto/constants/empty-states/transfers';
import { ROLES } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import parseConfirmResponse from 'qonto/utils/parse-confirm-response';
import { convertTransferRequestToTransfer } from 'qonto/utils/transfer-requests';

const DEFAULT_SORT_BY = 'created_at:desc';

export default class TransfersRequestsController extends Controller {
  @service abilities;
  @service errors;
  @service toastFlashMessages;
  @service emptyStates;
  @service intl;
  @service networkManager;
  @service notifierCounterManager;
  @service organizationManager;
  @service requestsManager;
  @service router;
  @service segment;
  @service sentry;
  @service store;
  @service zendeskLocalization;

  currentTransfer = null;
  beneficiaryWarning = null;
  spendLimitsWarning = null;
  spendLimits = null;

  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked sort_by = DEFAULT_SORT_BY;
  @tracked allocatedBudget = null;
  @tracked showAlreadyProcessedModal = false;

  get availableBankAccounts() {
    return this.organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts;
  }

  get currentParams() {
    return {
      page: this.page,
      per_page: this.per_page,
      sort_by: this.sort_by,
    };
  }

  get cachedRequests() {
    return this.isApprover
      ? this.requests.filter(({ status }) => status === 'pending')
      : [...this.requests];
  }

  get emptyRequesterStateButtonCallback() {
    return () => {
      this.trackTransferRequestStart();
      this.router.transitionTo('requests.landing', this.organizationManager.organization.slug, {
        queryParams: {
          origin: 'transfers',
        },
      });
    };
  }

  get isEmptyStatePreviewLayout() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get isEmptyStateInformLayout() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get isEmptyGlobally() {
    if (this.isApprover) {
      return (
        this.cachedRequests.length === 0 &&
        this.model.fetchTotalCountTask.last?.value?.meta?.total_count === 0
      );
    }
    return this.cachedRequests.length === 0;
  }

  get emptyStateRevampOptions() {
    if (this.model.fetchRequestsTask.isRunning || this.model.fetchRequestsTask.last?.isError) {
      return;
    }

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.cachedRequests.length === 0,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl),
      customInputs: {
        tab: 'requests',
        isSubAccountIsClosed: false,
      },
      abilities: {
        canCreateExternalTransfers: this.abilities.can('create transfer'),
        canRequestTransfers: this.abilities.can('create transfer request'),
        hasConnectFeature:
          this.abilities.can('access gold connect') && this.abilities.can('access silver connect'),
      },
    });
  }

  get isApprover() {
    return this.abilities.can('review transfer request');
  }

  get isCTAvisible() {
    return (
      this.isApprover ||
      (this.isRequester && (this.cachedRequests.length > 0 || this.emptyStateRevampOptions))
    );
  }

  get hasNoPendingRequests() {
    return (
      this.cachedRequests.length === 0 &&
      !this.model.fetchRequestsTask.isRunning &&
      !this.model.fetchRequestsTask.last.isError
    );
  }

  get pendingAriaLabel() {
    return this.shouldShowNotification
      ? this.intl.t('transfers.tabs.tasks.pending.aria-label')
      : this.intl.t('transfers.tabs.tasks.no-pending.aria-label');
  }

  get isEmptyApproverState() {
    // when: you are an Approver & there are no requests
    return this.hasNoPendingRequests && this.isApprover;
  }

  get isEmptyRequesterState() {
    // when: you are a Requester, you have never requested a transfer request
    return this.hasNoPendingRequests && this.isRequester;
  }

  get isEmptyEbicsApproverState() {
    return this.isEmptyApproverState && this.abilities.can('read ebics request');
  }

  get emptyEbicsSubtitle() {
    let { legalCountry } = this.organizationManager.organization;

    let linkText = htmlSafe(
      `<a
          href=${this.intl.t('transfers.requests.third-party.empty.link', {
            faqUrl: this.zendeskLocalization.getLocalizedArticle(4359616),
          })}
          target="_blank"
          rel="noopener noreferrer"
          class="body-link"
        >${this.intl.t('transfers.requests.third-party.empty.link-text')}</a>`
    );

    if (legalCountry === 'DE') {
      return this.intl.t('transfers.requests.ebics.empty.subtitle', { htmlSafe: true, linkText });
    } else {
      return this.intl.t('transfers.requests.third-party.empty.subtitle', {
        htmlSafe: true,
        linkText,
      });
    }
  }

  get isRequester() {
    let isManagerRequester =
      this.organizationManager.membership.role === ROLES.MANAGER &&
      this.abilities.cannot('review transfer request') &&
      this.abilities.can('read request');

    let isEmployeeRequester =
      this.organizationManager.membership.role === ROLES.EMPLOYEE &&
      this.abilities.cannot('review transfer request') &&
      this.abilities.can('create transfer request');

    return isManagerRequester || isEmployeeRequester;
  }

  get isSingleAccountApprover() {
    return this.isApprover && this.availableBankAccounts.length === 1;
  }

  get meta() {
    return this.requests.meta;
  }

  get requests() {
    return this.model.fetchRequestsTask.lastSuccessful?.value ?? [];
  }

  get shouldShowNotification() {
    return this.notifierCounterManager.counter?.transferRequests > 0;
  }

  get shouldSyncRequests() {
    return !this.cachedRequests.length && this.meta.total_pages > 1;
  }

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  @action
  handleSortBy(sortDefinition) {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;
  }

  @action
  handleTriggerTrackEvent(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'transfers',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });
      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
      if (this.isRequester) {
        this.trackTransferRequestStart();
      }
    }
  }

  @action
  onBankAccountSelect(request, account) {
    request.bankAccount = account;
  }

  approveTask = dropTask(async request => {
    this.segment.track('request_approved_clicked', {
      origin: 'transfers',
      method: 'sidepanel',
      outcome: request.lastStep ? 'payment' : 'approval',
    });

    if (request.requestType === 'transfer') {
      await this.confirmSingleTransferRequestTask.perform(request);

      if (this.confirmErrors.length) {
        return;
      }
    }

    await request.approveRequest();
  });

  declineTask = dropTask(async request => {
    this.segment.track('request_declined_clicked', {
      origin: 'transfers',
      method: 'sidepanel',
    });

    await request.declineRequest();
  });

  onQuickApproveTask = dropTask(async request => {
    if (request.requestType === 'multi_transfer') {
      this.segment.track('bulk_transfer_request_approved', {
        origin: 'multi_transfers',
        method: 'quick_actions',
      });
    } else {
      this.segment.track('request_approved_clicked', {
        origin: 'transfers',
        method: 'quick_actions',
        outcome: request.lastStep ? 'payment' : 'approval',
      });
    }

    await this.requestsManager.quickApproveTransferRequestTask.perform(request);

    if (this.shouldSyncRequests) {
      this.resetQueryParams();
      this.reloadData();
    }
  });

  onQuickRejectTask = dropTask(async request => {
    if (request.requestType === 'multi_transfer') {
      this.segment.track('bulk_transfer_request_declined', {
        origin: 'multi_transfers',
        method: 'quick_actions',
      });
    } else {
      this.segment.track('request_declined_clicked', {
        origin: 'transfers',
        method: 'quick_actions',
      });
    }

    await this.requestsManager.quickRejectTransferRequestTask.perform(request);

    if (this.shouldSyncRequests) {
      this.resetQueryParams();
      this.reloadData();
    }
  });

  // TODO: use request service for confirmation
  confirmSingleTransferRequestTask = dropTask(async request => {
    this.destroyCurrentTransfer();
    this.confirmErrors = [];
    this.spendLimitsWarning = null;
    this.spendLimits = null;

    this.currentTransfer = await convertTransferRequestToTransfer({
      dataStore: this.store,
      transferRequest: request,
      bankAccount: this.organizationManager.currentAccount,
    });

    try {
      let response = await this.requestsManager.confirmSingleTransferRequestTask.perform(request);
      this.setProperties(parseConfirmResponse(response, this.intl));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });

  onBankAccountSelectTask = dropTask(async (request, account) => {
    request.bankAccount = account;
    this.setProperties({ confirmWarnings: [], confirmErrors: [], beneficiaryWarning: [] });

    if (request.requestType === 'transfer') {
      await this.confirmSingleTransferRequestTask.perform(request);
    }
  });

  onCloseAlreadyProcessedModalTask = dropTask(async () => {
    this.showAlreadyProcessedModal = false;

    await this.model.fetchRequestsTask.perform();

    this.resetQueryParams();
  });

  // popup/confirmation modal is expecting a task for confirmTask argument
  onConfirmAlreadyProcessedModalTask = dropTask(async () => {
    this.showAlreadyProcessedModal = await false;

    this.router.transitionTo('tasks.past', {
      queryParams: { highlight: this.highlight },
    });
  });

  handleSelectRequestTask = restartableTask(async requestId => {
    if (!requestId) {
      this.highlight = null;
      return;
    }

    let { role } = this.organizationManager.membership;

    let request = this.requests.find(({ id }) => id === requestId);

    this.segment.track('request_details_opened', {
      origin: 'transfers',
      request_type: request.requestType,
      request_id: request.id,
      request_status: request.status,
      role,
    });

    if (request.requestType === 'multi_transfer') {
      let routeName = this.isRequester
        ? 'transfers.requests.requester-multi-transfer'
        : 'transfers.requests.multi-transfer';

      return this.router.transitionTo(routeName, request.id);
    }

    if (request.requestType === 'transfer') {
      await request.belongsTo('approvalWorkflowState').reload();
      await request.hasMany('attachments').load();
    }

    this.allocatedBudget = await this.requestsManager.fetchAllocatedBudgetTask.perform(request);

    this.highlight = request.id;

    if (this.isApprover) {
      await this.confirmSingleTransferRequestTask.perform(request);
    }
  });

  adminSettled(payload) {
    let { id } = payload.object;

    this.toastFlashMessages.toastInfo(
      this.intl.t('toasts.request_processed_refresh'),
      'transfer_create'
    );

    this.model.fetchRequestsTask.perform().catch(error => {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    });

    if (this.highlight === id) {
      this.showAlreadyProcessedModal = true;
    } else {
      this.toastFlashMessages.toastInfo(
        this.intl.t('toasts.request_processed_refresh'),
        'transfer_create'
      );
      this.model.fetchRequestsTask.perform({}).catch(ignoreCancelation);
    }
  }

  destroyCurrentTransfer() {
    let transfer = this.currentTransfer;
    // Ember Data 4.7 does not like calling `destroy()` on a store
    // instance that is already in "destroying" mode. Since we can skip the
    // cleanup in this case anyway we just return early here.
    if (this.store.isDestroying || this.store.isDestroyed) return;
    if (transfer) {
      transfer.destroy();
    }
  }

  employeeCanceled({ object }) {
    if (!this.requests.some(({ id }) => id === object.id)) {
      return;
    }

    if (this.highlight === object.id) {
      this.showAlreadyProcessedModal = true;
    } else {
      this.toastFlashMessages.toastInfo(
        this.intl.t('toasts.request_processed_refresh'),
        'transfer_create'
      );
      this.model.fetchRequestsTask.perform({}).catch(ignoreCancelation);
    }
  }

  reloadData() {
    this.model.fetchRequestsTask.perform({}).catch(ignoreCancelation);
  }

  resetQueryParams() {
    this.highlight = '';
    this.page = 1;
    this.per_page = 25;
    this.sort_by = DEFAULT_SORT_BY;
  }

  trackTransferRequestStart() {
    this.segment.track('request_started', {
      origin: 'transfers',
    });
  }
}
