/* eslint-disable  ember/no-controller-access-in-routes */

import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReimbursementsCompletedRoute extends Route {
  @service abilities;
  @service router;
  @service store;
  @service sentry;
  @service notifierCounterManager;

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
  };

  beforeModel() {
    if (
      this.abilities.cannot('use reimbursements request') &&
      !variation('feature--boolean-empty-state-revamp')
    ) {
      return this.router.replaceWith('reimbursements.upgrade-plan');
    }

    if (variation('feature--boolean-empty-state-revamp')) {
      if (
        this.abilities.cannot('review expense report request') &&
        this.abilities.cannot('review mileage request') &&
        this.abilities.cannot('update subscription')
      ) {
        return this.router.replaceWith('reimbursements.requests');
      }
    } else {
      if (
        this.abilities.cannot('review expense report request') &&
        this.abilities.cannot('review mileage request')
      ) {
        return this.router.replaceWith('reimbursements.requests');
      }
    }
  }

  model(params) {
    let controller = this.controllerFor(this.routeName);

    controller.requests = [];
    controller.memberships = this.store.peekAll('membership');

    if (
      variation('feature--boolean-empty-state-revamp') &&
      this.abilities.cannot('use expense reports request')
    ) {
      return;
    }
    this.notifierCounterManager.updateCounter();
    controller.fetchDataTask
      .perform(params)
      .then(() => {
        if (params.highlight) {
          let highlightedRequest = controller.requests.find(req => req.id === params.highlight);
          if (highlightedRequest) {
            return highlightedRequest.belongsTo('approvalWorkflowState').reload();
          }
        }
      })
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }
}
