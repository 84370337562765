export default {
  "multi-transfer": "qjx",
  "container": "qjw",
  "title": "qjN",
  "subtitle": "qjv",
  "form": "qjo",
  "form-title": "qjn",
  "files-buttons": "qji",
  "files-buttons-item": "qjA",
  "selected": "qjY"
};
