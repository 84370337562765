export const fxTransferBeneficiary = {
  steps: {
    'add-beneficiary': {
      componentName: 'transfers/fx/beneficiary/add-beneficiary',
    },
    'edit-beneficiary': {
      componentName: 'transfers/fx/beneficiary/edit-beneficiary',
    },
  },
  options: {
    enablePersistence: true,
  },
};
