import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { reads } from 'macro-decorators';

import {
  CARD_FLOWS_TRACKING_ORIGINS,
  CARD_LEVELS,
  CARD_STATUSES_ACTIVE,
} from 'qonto/constants/cards';

export class CardRenewPhysicalDataContext {
  constructor({ cardToRenewCount, holder, isCardsRenewal, organizationAddress, organizationName }) {
    this.cardToRenewCount = cardToRenewCount;
    this.holder = holder;
    this.isCardsRenewal = isCardsRenewal;
    this.organizationAddress = organizationAddress;
    this.organizationName = organizationName;
  }
}

export default class CardRenewPhysicalFlowSetup {
  @service abilities;
  @service cardsManager;
  @service flow;
  @service organizationManager;
  @service router;
  @service store;

  dataContext = null;

  @reads('organizationManager.membership') holder;
  @reads('organizationManager.organization.address') organizationAddress;
  @reads('organizationManager.organization.name') organizationName;
  @reads('cardsManager.counters.physical_waiting_to_renew') physicalCardToRenewCount;

  constructor(owner) {
    setOwner(this, owner);

    this.dataContext = new CardRenewPhysicalDataContext({
      cardToRenewCount: this.physicalCardToRenewCount ?? 0,
      holder: this.holder,
      isCardsRenewal: true,
      organizationAddress: this.organizationAddress,
      organizationName: this.organizationName,
    });
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    let physicalCards = await this.store.query('card', {
      filters: {
        holder_id: this.organizationManager.membership.id,
        card_levels: [CARD_LEVELS.STANDARD, CARD_LEVELS.PLUS, CARD_LEVELS.METAL],
        statuses: CARD_STATUSES_ACTIVE,
      },
    });

    this.dataContext.showCardCustomization = false;
    this.dataContext.flowTrackingOrigin = CARD_FLOWS_TRACKING_ORIGINS.RENEWAL;

    let renewableCards = physicalCards.filter(c => c.eligibleForRenewal && !c.renewed);
    let hasSingleCardToRenew = renewableCards.length === 1;

    let hasSingleCardToUpsell =
      renewableCards.filter(c => c.cardLevel !== CARD_LEVELS.METAL).length === 1;

    let canUpsell = this.abilities.can('upsell renewal card');
    let isEligibleForUpsell = hasSingleCardToUpsell && canUpsell;

    // requirements for the review step we display when there is only a single card to renew
    if (hasSingleCardToRenew) {
      let { estimates } = await this.cardsManager.fetchCardsCostsTask.perform(
        this.organizationManager.organization.id
      );

      // in case the user doesn't have the permission to upsell, we should display the customization step anyway
      this.dataContext.showCardCustomization = !canUpsell && renewableCards[0].isPlus;

      this.dataContext.cardOptionsPrices = await this.cardsManager.fetchCardOptionsPricesTask
        .perform()
        .catch(() => []);

      this.dataContext.hasSingleCardToRenew = true;
      this.dataContext.card = renewableCards[0];
      this.dataContext.estimates = estimates;
    }

    if (isEligibleForUpsell) {
      let cardsLimits = await this.cardsManager.fetchCardsMaxLimitsTask.perform();

      this.dataContext.isEligibleForUpsell = true;
      this.dataContext.cardsLimits = cardsLimits;
    }
  }

  @action
  onAbort() {
    this.router.transitionTo('cards.index');
  }

  @action
  onComplete() {
    this.router.transitionTo('cards.index');
  }
}
