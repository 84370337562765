export default {
  "vat-number": "qbJ body-2",
  "banner": "qbs",
  "container": "qbF",
  "organization-profile": "qbg",
  "input": "qbD",
  "medium-input": "qbV medium",
  "small-input": "qbL small",
  "actions": "qbT",
  "company-info": "qbz",
  "company-info-section": "qba",
  "company-address-section": "qbH",
  "company-country": "qbO",
  "company-billing-email": "qbm",
  "company-address": "qbp"
};
