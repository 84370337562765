import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { task } from 'ember-concurrency';

import { BANK_CONNECTIONS_PAGE_SIZE } from 'qonto/constants/connect';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ConnectionsRoute extends Route {
  @service abilities;
  @service router;
  @service sentry;
  @service store;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'connect');

    this.store.unloadAll('consentsBundle');
    this.store.unloadAll('bank-connection');
  }

  model() {
    let canViewBankConnections = this.abilities.can('view connections external-account');
    let canViewOAuth = this.abilities.can('view oauth');

    let connections = {
      appsConnectionsTask: null,
      banksConnectionsTask: null,
    };

    if (canViewOAuth) {
      this.fetchAppsConnectionsTask.perform().catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

      connections.appsConnectionsTask = this.fetchAppsConnectionsTask;
    }

    if (canViewBankConnections) {
      this.fetchBanksConnectionsTask.perform().catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

      connections.banksConnectionsTask = this.fetchBanksConnectionsTask;
    }

    return {
      canViewTabs: canViewBankConnections && canViewOAuth,
      connections,
    };
  }

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }

  fetchAppsConnectionsTask = task(async () => await this.store.findAll('consentsBundle'));
  fetchBanksConnectionsTask = task(
    async () => await this.store.query('bank-connection', { per_page: BANK_CONNECTIONS_PAGE_SIZE })
  );
}
