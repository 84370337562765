import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, requestsNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class CardsRoute extends Route {
  @service abilities;
  @service cardsManager;
  @service homePage;
  @service router;
  @service store;
  @service organizationManager;
  @service networkManager;
  @service sentry;

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'cards');
    let canReadCard = this.abilities.can('read card');

    if (!canReadCard) {
      let queryParams = transition.to.queryParams;
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }
  get isApprover() {
    return this.abilities.can('review card request');
  }

  get canAccessRequestsTab() {
    return this.abilities.can('read request');
  }

  get areCardsRequestsEmpty() {
    // no request has been made, return true
    if (!this.fetchRequestsCountTask.last) {
      return true;
    }

    let cardsRequests = this.fetchRequestsCountTask.lastSuccessful?.value?.card_requests;
    // if there is an error
    if (this.fetchRequestsCountTask.last?.isError || !cardsRequests) {
      return false;
    }

    // count is successfully fetched
    // if user is approver, return only requests with `pending` status
    // if user is not approver, return requests with `all` statuses
    let count = this.isApprover
      ? cardsRequests.pending
      : Object.values(cardsRequests).reduce((a, b) => a + b, 0);
    return count === 0;
  }

  get areCardsEmpty() {
    if (this.fetchCardsTask.last?.isError) {
      return false;
    }
    return this.fetchCardsTask.lastSuccessful?.value?.length === 0;
  }

  async model() {
    if (variation('feature--boolean-empty-state-revamp')) {
      if (this.canAccessRequestsTab) {
        await this.fetchRequestsCountTask
          .perform()
          .catch(ignoreCancelation)
          .catch(error => {
            if (ErrorInfo.for(error).shouldSendToSentry) {
              this.sentry.captureException(error);
            }
          });
      }

      await this.fetchCardsTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => {
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        });
    }

    return {
      isEmptyGlobally: this.areCardsRequestsEmpty && this.areCardsEmpty,
      isCardsEmptyGlobally: this.areCardsEmpty,
    };
  }

  async resetController(controller, isExiting) {
    if (!isExiting) return;

    await Promise.all([
      this.fetchRequestsCountTask.cancelAll({ resetState: true }),
      this.fetchCardsTask.cancelAll({ resetState: true }),
    ]);
  }

  // fetch requests count (requests/tasks tab)
  fetchRequestsCountTask = restartableTask(async () => {
    let { organization } = this.organizationManager;

    return await this.networkManager.request(
      `${apiBaseURL}/${requestsNamespace}/requests/counters`,
      {
        method: 'GET',
        data: { organization_id: organization.id, include_own_requests: true },
      }
    );
  });

  // fetch card count for all organization (my-cards, team, archived tabs)
  fetchCardsTask = restartableTask(async () => {
    let { organization } = this.organizationManager;

    return await this.store.query('card', {
      organization_id: organization.id,
    });
  });
}
