import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class AddonChangeDataContext {
  @tracked recurrence;

  constructor({ recurrence }) {
    this.recurrence = recurrence;
  }
}
export default class AddonChangeFlowSetup extends FlowSetup {
  @service organizationManager;
  @service router;
  @service flow;
  @service abilities;

  dataContext = null;

  constructor() {
    super(...arguments);

    let { queryParams } = this.router.currentRoute;
    let { dataContext } = this.flow;

    let recurrence =
      queryParams.recurrence || dataContext?.recurrence || SUBSCRIPTION_RECURRENCES.MONTHLY;

    this.dataContext = new AddonChangeDataContext({
      recurrence,
    });
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'pricing');

    if (
      !this.organizationManager.organization.hasModularPricing ||
      this.abilities.cannot('update subscription')
    ) {
      return this.router.replaceWith('settings');
    }
  }

  onAbortTask = dropTask(async () => {
    await this._redirectToMainPage();
  });

  @action
  onComplete() {
    return this._redirectToMainPage();
  }

  _redirectToMainPage() {
    let organizationSlug = this.organizationManager.organization.slug;
    this.router.replaceWith('settings.subscription.index', organizationSlug);
  }
}
