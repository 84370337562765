import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { STATUS } from 'qonto/constants/transfers';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SupplierInvoicesIndexRoute extends Route {
  @service abilities;
  @service errors;
  @service intl;
  @service notifierManager;
  @service organizationManager;
  @service router;
  @service segment;
  @service sentry;
  @service store;
  @service supplierInvoicesInsights;
  @service supplierInvoicesUploadManager;
  @service toastFlashMessages;

  queryParams = {
    status: { refreshModel: true },
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
    dueDate: { refreshModel: true },
  };

  activate() {
    this.notifierManager.on(SUPPLIER_INVOICE_EVENTS.REQUEST_CREATED, this, 'fetchAll');
    this.notifierManager.on(SUPPLIER_INVOICE_EVENTS.REQUEST_CANCELED, this, 'fetchAll');
    this.notifierManager.on(SUPPLIER_INVOICE_EVENTS.SCHEDULED, this, 'fetchAll');
    this.notifierManager.on(SUPPLIER_INVOICE_EVENTS.REQUEST_DECLINED, this, 'fetchAll');
    this.router.on('routeWillChange', this.storeTransition);
  }

  deactivate() {
    this.notifierManager.off(SUPPLIER_INVOICE_EVENTS.REQUEST_CREATED, this, 'fetchAll');
    this.notifierManager.off(SUPPLIER_INVOICE_EVENTS.REQUEST_CANCELED, this, 'fetchAll');
    this.notifierManager.off(SUPPLIER_INVOICE_EVENTS.SCHEDULED, this, 'fetchAll');
    this.notifierManager.off(SUPPLIER_INVOICE_EVENTS.REQUEST_DECLINED, this, 'fetchAll');
    this.router.off('routeWillChange', this.storeTransition);
  }

  beforeModel() {
    if (this.abilities.cannot('access supplierInvoice')) {
      return this.router.replaceWith('supplier-invoices.upgrade-plan');
    }
    this.segment.track('supplier-invoices');
  }

  model(params) {
    this.params = params;
    this.handleTransferFlow(params);
    let hasFlag = variation('feature--boolean-empty-state-revamp');

    if (!(this.abilities.cannot('access supplierInvoice') && hasFlag)) {
      this.fetchAll();
    }

    this.supplierInvoicesUploadManager.registerCallback({
      onUploadFinished: this.fetchAll,
    });

    return { supplierInvoicesTask: this.fetchSupplierInvoicesTask, refreshModel: this.fetchAll };
  }

  async resetController(controller, isExiting) {
    if (!isExiting || this.router.transition.targetName === 'supplier-invoices.show') return;

    controller.status = INVOICE_STATUSES.toReview;
    controller.page = 1;
    controller.perPage = 25;
    controller.sortBy = 'due_date:asc';

    await Promise.all([
      this.fetchSupplierInvoicesTask.cancelAll({ resetState: true }),
      this.supplierInvoicesInsights.fetchInsightsTask.cancelAll({ resetState: true }),
    ]);

    if (!this.supplierInvoicesUploadManager.hasErrors) {
      this.supplierInvoicesUploadManager.resetState();
    }
  }

  fetchSupplierInvoicesTask = restartableTask(async params => {
    let { page, perPage, sortBy, status, dueDate } = params;

    if (status === INVOICE_STATUSES.toReview) {
      status = [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay];
    } else if (status === `${INVOICE_STATUSES.paid},${INVOICE_STATUSES.archived}`) {
      status = [INVOICE_STATUSES.paid, INVOICE_STATUSES.archived];
    }

    let invoices = await this.store.query('supplier-invoice', {
      per_page: perPage,
      page,
      filter: { status, due_date: dueDate },
      sort_by: sortBy,
    });

    let membershipIds = invoices
      .map(invoice => invoice.belongsTo('initiator').id())
      .filter(Boolean);

    let requesterIds = invoices.map(({ requestTransfer }) => requestTransfer?.initiator_id);

    if (membershipIds.length) {
      let uniqueMembershipsIds = [...new Set([...membershipIds, ...requesterIds])];

      await this.store.query('membership', {
        organization_id: this.organizationManager.organization.id,
        filters: { ids: uniqueMembershipsIds },
        per_page: perPage,
      });
    }

    // fetching customPermissions used in controller for emptyStateRevampOptions
    let { membership } = this.organizationManager;
    if (membership.manager && !membership.customPermissions) {
      await membership.getRole();
    }

    return invoices;
  });

  async handleTransferFlow({ supplierInvoiceId, transferStatus }) {
    if (supplierInvoiceId && transferStatus) {
      try {
        let { status } = await this.store.findRecord('supplier-invoice', supplierInvoiceId);
        let isAfterTransfer = [INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid].includes(status);
        let isSuccess = [
          STATUS.COMPLETED,
          STATUS.PROCESSING,
          STATUS.PENDING,
          STATUS.STANDING_PROCESSING,
        ].includes(transferStatus);

        if (isAfterTransfer && isSuccess) {
          let toastMessage =
            status === INVOICE_STATUSES.paid
              ? this.intl.t('supplier-invoices.success-toast.mark-as-paid')
              : this.intl.t('supplier-invoices.transfers.scheduled');

          this.toastFlashMessages.clearToastMessages();
          this.toastFlashMessages.toastSuccess(toastMessage);
        }
      } catch (error) {
        this.errors.handleError(error);
      } finally {
        this.router.replaceWith({
          queryParams: {
            status: INVOICE_STATUSES.toReview,
            sortBy: 'due_date:asc',
            supplierInvoiceId: null,
            transferStatus: null,
          },
        });
      }
    }
  }

  @action
  fetchAll() {
    this.fetchSupplierInvoicesTask
      .perform(this.params)
      .catch(ignoreCancelation)
      .catch(error => {
        this.toastFlashMessages.clearToastMessages();
        this.errors.handleError(error);
      });

    this.supplierInvoicesInsights.fetchInsights().catch(error => this._handleInsightsError(error));
  }

  storeTransition(transition) {
    this.transition = transition;
  }

  _handleInsightsError(error) {
    if (!this.fetchSupplierInvoicesTask.last.isError) {
      this.toastFlashMessages.toastError(this.intl.t('supplier-invoices.cockpit.error-toast'));
    }

    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }
}
