import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { LottiePlayer } from 'design-system-kit';

export default class InvitationsShowMemberSuccessController extends Controller {
  lottiePlayer = LottiePlayer;

  @service webviewManager;
  @service segment;
  @service deviceManager;
  @service router;

  @action
  handleComplete() {
    this.segment.track('join_team_completed');

    if (this.deviceManager.isMobile && this.webviewManager.isWebview) {
      return this.webviewManager.onJoinTeamSucceeded();
    }

    return this.router.transitionTo('organizations.show', this.model.invite.organization);
  }
}
