import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class BankAccountsRoute extends Route {
  @service router;
  @service segment;

  redirect(model, transition) {
    this.segment.track('redirected_from_bank-accounts_to_account', {
      message: 'Members: User get redirected from old url /bank-accounts to new /accounts one',
    });

    let { queryParams } = transition.to;
    this.router.replaceWith('accounts', { queryParams });
  }
}
