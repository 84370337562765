import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import CURRENCIES from 'qonto/constants/currencies';
import { CONTRACT_STATUS, PAY_LATER_ELIGIBILITY_STATUSES } from 'qonto/constants/financing';
import {
  DISCLAIMER_TYPES,
  OPERATION_TYPES,
  SEPA_TRACKING_SETTLEMENT_CTA,
  SEPA_TRACKING_SETTLEMENT_EVENTS,
  STATUS,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { getCurrentParisDate } from 'qonto/utils/date';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { getNRCIban } from 'qonto/utils/nrc';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
  createSepaSettlementTrackingEventPayload,
} from 'qonto/utils/transfers';

class SepaTransferFlowDataContext {
  sepaTransfer = null;
  beneficiary = null;
  invoice = null;
  origin = null;
  supplierInvoiceId = null;
  repeatedTransferId = null;
  nrcOptions = null;
  isInstantFallback = false;
  confirmationResult = {};
  settlementResult = {};
  budgetName = null;
  availableCreditAmount = null;
  isPayLaterEligible = null;
  isPayLaterContractSigned = null;
  payLaterInstallments = null;
  signature = { url: '', status: '' };
  defaultOperationType = OPERATION_TYPES.SCHEDULED;
  payLaterErrors = [];
  didValidateFinancing = false;
  didInstallmentsFail = false;
  minTransferAmount = null;
  invoiceAttachmentLimits = null;

  constructor({
    sepaTransfer,
    invoice,
    origin,
    beneficiary = null,
    supplierInvoiceId,
    nrcOptions = {},
    repeatedTransferId = null,
    kycSubmitted,
  }) {
    this.sepaTransfer = sepaTransfer;
    this.invoice = invoice;
    this.origin = origin;
    this.beneficiary = beneficiary;
    this.supplierInvoiceId = supplierInvoiceId;
    this.nrcOptions = nrcOptions;
    this.repeatedTransferId = repeatedTransferId;
    this.kycSubmitted = kycSubmitted;
  }
}

export default class SepaTransferFlowSetup extends FlowSetup {
  @service abilities;
  @service attachmentsManager;
  @service beneficiariesManager;
  @service errors;
  @service flow;
  @service flowLinkManager;
  @service financing;
  @service homePage;
  @service intl;
  @service modals;
  @service organizationManager;
  @service payByInvoiceUploadManager;
  @service router;
  @service segment;
  @service store;
  @service toastFlashMessages;

  constructor(_owner, previousDataContext = {}) {
    super(...arguments);

    let { organization } = this.organizationManager;
    let { defaultAccount } = organization;
    let { params: routeParams, queryParams } = this.router.currentRoute;
    let {
      amount,
      companyType,
      origin,
      reference,
      supplierInvoiceId,
      taxBeneficiaryName,
      taxModel,
      repeatedTransferId,
    } = queryParams;
    let { beneficiary, invoice, sepaTransfer } = previousDataContext;

    let sepaTransferRecord;

    if (origin === TRANSFER_FLOW_ORIGIN.QONTO_PILOT) {
      let draftSepaTransfer = this.store
        .peekAll('transfer')
        .filter(transfer => transfer.isNew && !transfer.id);
      sepaTransferRecord = draftSepaTransfer.length ? draftSepaTransfer[0] : null;
    }

    if (!sepaTransferRecord) {
      sepaTransferRecord =
        sepaTransfer ||
        this.store.createRecord('transfer', {
          activityTag: 'other_expense',
          amountCurrency: CURRENCIES.default,
          bankAccount: defaultAccount,
          fx: false,
          organization,
        });
    }

    sepaTransferRecord.addIdempotencyKey();

    this.dataContext = new SepaTransferFlowDataContext({
      ...(beneficiary && { beneficiary }),
      sepaTransfer: sepaTransferRecord,
      invoice,
      origin,
      supplierInvoiceId,
      repeatedTransferId,
      nrcOptions: {
        amount,
        companyType,
        reference,
        taxBeneficiaryName,
        taxModel,
      },
      kycSubmitted: this.organizationManager.membership.kycSubmitted,
    });

    if (this.dataContext.origin) {
      this._prefillTransferOptions(this.dataContext.origin);
    }

    if (this.dataContext.beneficiary) {
      this._setTransferBeneficiary(this.dataContext.beneficiary);
    }

    if (routeParams.name === 'sepa-transfer') {
      this.segment.track('transfer-sepa_flow_started', {
        starting_page: routeParams.step_id,
        ...(origin && { origin }),
      });
    }
  }

  async beforeFlow({ stepId } = {}) {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    if (this.abilities.cannot('create transfer')) {
      return this.homePage.replaceWithDefaultPage();
    }

    let stepToRestart = this._getStepToRestart(stepId, this.dataContext);
    if (stepToRestart) {
      return this.flowLinkManager.transitionTo({ name: 'sepa-transfer', stepId: stepToRestart });
    }

    if (this.abilities.can('view pay later toggle in financing')) {
      try {
        let {
          eligibility,
          contractStatus,
          availableCreditAmount,
          minTransferAmount,
          invoiceAttachmentLimits,
        } = await this.financing.checkPayLaterEligibility();

        Object.assign(this.dataContext, {
          availableCreditAmount,
          isPayLaterContractSigned: contractStatus === CONTRACT_STATUS.SIGNED,
          isPayLaterEligible: eligibility === PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE,
          minTransferAmount,
          invoiceAttachmentLimits,
        });
      } catch (error) {
        if (this.errors.shouldFlash(error)) {
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      }
    }
  }

  @action
  onComplete({
    confirmationResult,
    invoice,
    origin,
    sepaTransfer,
    supplierInvoiceId,
    settlementResult,
  }) {
    let { status, declinedReason } = settlementResult;

    let isQontoBeneficiary = sepaTransfer.get('beneficiary.qontoBankAccount');
    let hasQontoBeneficiaryWarning = confirmationResult?.warnings?.includes(
      DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT
    );

    let eventName;

    if (status === STATUS.DECLINED) {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.REJECT_CLOSED;
    } else if (status === STATUS.PROCESSING) {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.LOADING_CLOSED;
    } else if (status === 'timeout') {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.TIMEOUT_CLOSED;
    } else {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED;
    }

    let payload = createSepaSettlementTrackingEventPayload({
      invoice,
      origin,
      sepaTransfer,
      settlementStatus: status,
      declinedReason,
      cta: SEPA_TRACKING_SETTLEMENT_CTA.EXIT,
      isQontoBeneficiary,
    });

    this.segment.track(eventName, payload);

    this.payByInvoiceUploadManager.resetState();

    if (
      [TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER, TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT].includes(
        origin
      ) &&
      this.flow.refererPage
    ) {
      return this.router.transitionTo(this.flow.refererPage);
    }

    if (
      (sepaTransfer.instant && [STATUS.COMPLETED, STATUS.DECLINED].includes(status)) ||
      hasQontoBeneficiaryWarning
    ) {
      return this._redirect({
        url: 'transfers.past',
        origin,
        sepaTransfer,
        supplierInvoiceId,
      });
    }

    return this._redirect({
      url: 'transfers.pending',
      origin,
      sepaTransfer,
      supplierInvoiceId,
    });
  }

  onAbortTask = dropTask(
    async ({ isInstantFallback, origin, sepaTransfer, supplierInvoiceId }, { id: stepId }) => {
      let shouldConfirmAbort = !['beneficiaries', 'invoice-upload'].includes(stepId);

      if (shouldConfirmAbort) {
        let result = await this.openAbortModalTask.perform();
        if (result !== 'confirm') return false;
      }

      this.payByInvoiceUploadManager.resetState();

      this._trackOnAbort({ isInstantFallback, origin, stepId });

      if ([TRANSFER_FLOW_ORIGIN.QONTO_PILOT].includes(origin)) {
        this.store.unloadRecord(this.dataContext.sepaTransfer);
      }

      if (
        [
          TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER,
          TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT,
          TRANSFER_FLOW_ORIGIN.OVERVIEW,
        ].includes(origin) &&
        this.flow.refererPage
      ) {
        return this.router.transitionTo(this.flow.refererPage);
      }

      this._redirect({ url: 'transfers.landing', origin, sepaTransfer, supplierInvoiceId });

      return true;
    }
  );

  openAbortModalTask = dropTask(
    async () =>
      await this.modals.open('popup/destructive', {
        title: this.intl.t('transfers.exit-flow.title'),
        description: this.intl.t('transfers.exit-flow.subtitle'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('transfers.exit-flow.confirm'),
      })
  );

  prefillNRCOptionsTask = dropTask(async sepaTransfer => {
    let { amount, companyType, reference, taxBeneficiaryName, taxModel } =
      this.dataContext.nrcOptions;

    let iban = getNRCIban(taxBeneficiaryName);
    if (iban) {
      let matchingBeneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
        this.organizationManager.organization.id,
        iban
      );
      if (matchingBeneficiary) this._setTransferBeneficiary(matchingBeneficiary);
    }

    sepaTransfer.setProperties({
      amount,
      activityTag: 'tax',
      note: `${amount}+${taxModel}+${companyType}+${reference}`,
      reference,
    });
  });

  prefillSupplierInvoiceOptionsTask = dropTask(async (sepaTransfer, supplierInvoiceId) => {
    let supplierInvoice = await this.store.findRecord('supplier-invoice', supplierInvoiceId);
    if (supplierInvoice) {
      let attachmentId = supplierInvoice.belongsTo('attachment').id();
      let attachment = await this.store.findRecord('attachment', attachmentId);
      let { iban, invoiceNumber, supplierName, totalAmount } = supplierInvoice;

      if (iban) {
        let matchingBeneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
          this.organizationManager.organization.id,
          iban
        );
        if (matchingBeneficiary?.name === supplierName) {
          this._setTransferBeneficiary(matchingBeneficiary);
        }
      }

      sepaTransfer.setProperties({
        amount: totalAmount ? totalAmount.value : 0,
        attachments: attachment ? [attachment] : undefined,
        reference: invoiceNumber ?? undefined,
      });
    }
  });

  prefillRepeatTransferTask = dropTask(async (sepaTransfer, repeatedTransferId) => {
    let repeatedTransfer = await this.store.findRecord('transfer', repeatedTransferId);
    let { reference, notifyByEmail, accountNumber, email, note, vatRate } = repeatedTransfer;
    let beneficiary = await repeatedTransfer.get('beneficiary');

    if (!beneficiary || beneficiary.hidden) {
      this.toastFlashMessages.toastError(this.intl.t('transfers.repeat.sepa.error.toast'));
      this.router.transitionTo('transfers.pending');
    } else {
      this._setTransferBeneficiary(beneficiary);
      sepaTransfer.setProperties({
        reference,
        notifyByEmail,
        accountNumber,
        email,
        note,
        vatRate,
      });
      let targetBankAccount = await repeatedTransfer.get('bankAccount');
      if (targetBankAccount.isActive) {
        sepaTransfer.set('bankAccount', repeatedTransfer.get('bankAccount'));
      }
      sepaTransfer.set('labels', repeatedTransfer.get('labels'));
    }
  });

  _prefillTransferOptions(origin) {
    let prefillTask;

    if (origin === TRANSFER_FLOW_ORIGIN.NRC) {
      prefillTask = this.prefillNRCOptionsTask.perform(this.dataContext.sepaTransfer);
    }

    if (origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES) {
      prefillTask = this.prefillSupplierInvoiceOptionsTask.perform(
        this.dataContext.sepaTransfer,
        this.dataContext.supplierInvoiceId
      );
    }

    if (origin === TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER) {
      prefillTask = this.prefillRepeatTransferTask.perform(
        this.dataContext.sepaTransfer,
        this.dataContext.repeatedTransferId
      );
    }

    prefillTask?.catch(ignoreCancelation);
  }

  _redirect({ url, origin, sepaTransfer, supplierInvoiceId }) {
    if (origin && supplierInvoiceId) {
      return this.router.transitionTo(origin, {
        queryParams: {
          transferStatus: sepaTransfer.status,
          supplierInvoiceId,
        },
      });
    }

    return this.router.transitionTo(url);
  }

  _resetTransferDetails(transfer) {
    transfer.setProperties({
      amount: 0,
      reference: null,
      notifyByEmail: false,
      email: null,
      operationType: 'scheduled',
      scheduleDate: getCurrentParisDate(),
    });
  }

  _setTransferBeneficiary(beneficiary) {
    let { invoice, sepaTransfer } = this.dataContext;

    // The reset of transfer details if the current beneficiary des not match the previous one
    // is only needed if we are not using an invoice to prefill them.
    if (!invoice) {
      let currentBeneficiaryId = sepaTransfer.get('beneficiary.id');
      if (beneficiary.id !== currentBeneficiaryId) this._resetTransferDetails(sepaTransfer);
    }

    sepaTransfer.set('beneficiary', beneficiary);

    copyBeneficiaryIntoTransfer(sepaTransfer, beneficiary, { forceCopy: true });
    copyBeneficiaryLabelsIntoTransfer(sepaTransfer, beneficiary);
    if (this.abilities.can('view vat bookkeeping')) {
      copyBeneficiaryVatIntoTransfer(sepaTransfer, beneficiary);
    }
  }

  _trackOnAbort({ isInstantFallback, origin, stepId }) {
    let trackingEventName =
      stepId === 'summary' && isInstantFallback
        ? 'transfer-sepa_fallback_summary_closed'
        : 'transfer_creation_exited';

    this.segment.track(trackingEventName, {
      ...(origin && { origin }),
    });
  }

  _getStepToRestart(stepId, dataContext) {
    let { beneficiary, invoice, origin } = dataContext;
    let isSpecialOrigin = [
      TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER,
      TRANSFER_FLOW_ORIGIN.NRC,
      TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
      TRANSFER_FLOW_ORIGIN.QONTO_PILOT,
    ].includes(origin);

    if (['details', 'additional-settings', 'summary'].includes(stepId) && !beneficiary) {
      return stepId === 'details' && isSpecialOrigin ? null : 'beneficiaries';
    }

    if (stepId === 'invoice' && !invoice) {
      return 'invoice-upload';
    }

    return null;
  }
}
