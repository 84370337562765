import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class BankAccountsNewRoute extends Route {
  @service flowLinkManager;
  @service router;
  @service segment;

  get message() {
    return `Members: User get redirected from old url /bank-accounts/new to new ${this.redirectRoute} one`;
  }

  get redirectRoute() {
    return '/accounts/new/current';
  }

  redirect(_, transition) {
    this.segment.track('redirected_from_bank-accounts.new_to_account.new', {
      message: this.message,
    });

    let { queryParams } = transition.to;
    this.router.replaceWith('accounts.new.current', { queryParams });
  }
}
