import Controller from '@ember/controller';
import { service } from '@ember/service';

import { LottiePlayer } from 'design-system-kit';
import { reads } from 'macro-decorators';

export default class InsuranceHubOfferErrorController extends Controller {
  lottiePlayer = LottiePlayer;

  @service router;

  @reads('router.currentRoute.params.uid') uid;
}
