import { formatAmountToNumber } from 'qonto/utils/amount';
import { getAllowedDecimalPlaces } from 'qonto/utils/currency';

export const internationalOut = {
  steps: {
    quote: {
      componentName: 'transfers/international-out/new/quote',
      nextStepId: 'beneficiaries',
    },
    beneficiaries: {
      componentName: 'transfers/international-out/new/beneficiaries',
      nextStepId: ({ additionalRequirements }) =>
        additionalRequirements ? 'additional-requirements' : 'transfer-requirements',
    },
    'additional-requirements': {
      componentName: 'transfers/international-out/new/additional-requirements',
      nextStepId: 'transfer-requirements',
    },
    'transfer-requirements': {
      componentName: 'transfers/international-out/new/transfer-requirements',
      nextStepId: ({ fees, quote, settings }) => {
        let totalAmount = formatAmountToNumber({
          amount: quote.sourceAmount + fees.total,
          precision: getAllowedDecimalPlaces(quote.sourceCurrency),
        });
        return totalAmount >= settings.maximumAmountWithoutAttachment ? 'attachment' : 'summary';
      },
    },
    attachment: {
      componentName: 'transfers/international-out/new/attachment',
      nextStepId: 'summary',
    },
    summary: {
      componentName: 'transfers/international-out/new/summary',
      nextStepId: 'success',
    },
    success: {
      componentName: 'transfers/international-out/new/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
