/* eslint-disable promise/catch-or-return */
import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class CapitalReviewedController extends Controller {
  @action
  handleSignCapitalDeposit() {
    this.set('showLoading', true);
    this.transitionToRoute('capital.reviewed.contract').then(() => {
      this.set('showLoading', false);
    });
  }
}
