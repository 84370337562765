import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { equal } from 'macro-decorators';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/suppliers-list';
import { LAYOUT } from 'qonto/constants/empty-states/system';
import { COUNTERPARTIES_SORT_BYS, DEFAULT_SORT_ORDER } from 'qonto/constants/sort';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { VAT_RATES } from 'qonto/constants/vat';

const DEFAULT_SORT_BY = `${COUNTERPARTIES_SORT_BYS.NAME}:${DEFAULT_SORT_ORDER}`;

export default class CounterpartiesIndexController extends Controller {
  @service abilities;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service store;
  @service emptyStates;
  @service flowLinkManager;
  @service subscriptionManager;

  @tracked highlight = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked query = '';
  @tracked activityTag = null;
  @tracked labelIds = [];
  @tracked missingVAT = false;
  @tracked missingLabels = false;
  @tracked vatRates = [];
  @tracked sortBy = DEFAULT_SORT_BY;
  @tracked showFilters = false;

  @tracked selectedVatRate = null;
  @tracked selectedLabels;

  queryParams = [
    'query',
    'highlight',
    'page',
    { activityTag: 'activity_tag' },
    { labelIds: 'label_ids' },
    { missingVAT: 'missing_vat' },
    { missingLabels: 'missing_labels' },
    { vatRates: 'vat_rates' },
    { perPage: 'per_page' },
    { sortBy: 'sort_by' },
    { showFilters: 'show_filters' },
  ];

  get counterparties() {
    return this.model.searchTask.lastSuccessful?.value || [];
  }

  get highlightableItems() {
    let { highlight, counterparties } = this;
    if (highlight) {
      let counterparty = this.store.peekRecord('beneficiary', highlight);
      if (counterparty && !counterparties.includes(counterparty)) {
        return [...counterparties, counterparty];
      }
    }
    return counterparties;
  }

  get closeSidebarId() {
    return 'close-sidebar';
  }

  @equal('organizationManager.organization.labels.length', 0) isBannerVisible;

  get isEmpty() {
    // to complete with "when no filter is applied" once the filters are handled
    return (
      !this.model.searchTask.isRunning &&
      !this.model.searchTask.last.isError &&
      !this.counterparties.length &&
      !this.query &&
      this.filtersCount === 0
    );
  }

  get filtersCount() {
    let count = 0;

    if (this.missingVAT) {
      // missing VAT counts twice as it is in two filters
      count += 2;
    }

    if (this.missingLabels && !this.missingVAT) {
      // belongs to same filter as missingVAT, so don't
      // count it twice
      count++;
    }

    if (this.activityTag) {
      count++;
    }

    if (this.labelIds.length) {
      // we need to count 1 for every label-list
      count += this._labelListsCount(this.labelIds);
    }

    if (this.vatRates.length) {
      count++;
    }

    return count;
  }

  get totalCreatedCounterparties() {
    return this.model.fetchAllSuppliers.lastSuccessful?.value?.meta?.total_count || 0;
  }

  get isInformEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get shouldShowDiscoverEmptyState() {
    return this.emptyStateRevampOptions && !this.isInformEmptyState;
  }

  get shouldShowInformEmptyState() {
    return this.emptyStateRevampOptions && this.isInformEmptyState;
  }

  @action
  makeTransferCtaCallback(origin) {
    this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    this.router.transitionTo('transfers.landing');
  }

  @action
  requestTransferCtaCallback(origin) {
    this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    this.router.transitionTo('requests.landing');
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('suppliersManagement');
  }

  get emptyStateRevampOptions() {
    if (
      !this.model.fetchAllSuppliers.isRunning &&
      !this.model.searchTask.isRunning &&
      !this.model.fetchAllSuppliers.last.isError &&
      !this.model.searchTask.last.isError
    ) {
      let isEmptyGlobally = !this.totalCreatedCounterparties;
      let isEmptyLocally = !this.counterparties.length;
      let hasActiveFilterOrSearch = Boolean(this.query || this.filtersCount !== 0);

      return this.emptyStates.getEmptyStateOptions({
        isEmptyGlobally,
        isEmptyLocally,
        hasActiveFilterOrSearch,
        config: getEmptyStateConfig(this.intl, {
          makeTransferCtaCallback: this.makeTransferCtaCallback,
          requestTransferCtaCallback: this.requestTransferCtaCallback,
        }),
        abilities: {
          canAccessBeneficiaries: this.abilities.can('access beneficiary'),
          canCreateBeneficiaries: this.abilities.can('create beneficiary'),
          canCreateTransfer: this.abilities.can('create transfer'),
          canRequestTransfer: this.abilities.can('create transfer request'),
          hasRequestsFeature: this.abilities.can('use request'),
        },
      });
    }
  }

  get vatRatesCategories() {
    let { organization } = this.organizationManager;
    let vatRates = organization.vatRatesCategories.filter(vatRate => vatRate !== VAT_RATES.OTHER);

    return [VAT_RATES.NOT_AUTOMATIC, ...vatRates];
  }

  resetQueryParams() {
    this.setProperties({
      page: 1,
      query: null,
      highlight: null,
      activityTag: null,
      labelIds: [],
      missingVAT: false,
      missingLabels: false,
      vatRates: [],
      sortBy: DEFAULT_SORT_BY,
      showFilters: false,
    });
  }
  _labelListsCount(labelIds) {
    let { labels } = this.organizationManager.organization;
    let labelListIds = labels.filter(l => labelIds.includes(l.id)).map(l => l.labelList.id);

    return new Set(labelListIds).size;
  }

  @action
  showDiscoverModal() {
    this.modals.open('counterparties/discover-modal', {
      isFullScreenModal: true,
    });
  }

  @action
  updatePage(value) {
    this.page = value;
  }

  @action
  updatePerPage(value) {
    this.setProperties({
      page: 1,
      perPage: value,
    });
  }

  @action
  updateSortBy(sortBy) {
    this.setProperties({
      highlight: null,
      page: 1,
      sortBy,
    });
  }

  @action
  updateFilters(filters) {
    this.setProperties({ ...filters, page: 1 });
  }

  @action
  clearFilters() {
    this.resetQueryParams();
  }

  @action
  toggleExtendedFilters() {
    this.showFilters = !this.showFilters;
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }

  @action
  afterHighlight(item) {
    this.selectedVatRate = item.vatRate;
    this.selectedLabels = item.labels;
  }

  @action
  updateCounterpartyAttributes({ vatRate, labels }) {
    this.selectedVatRate = vatRate;
    this.selectedLabels = labels;
  }

  updateQueryTask = restartableTask(async value => {
    await timeout(DEBOUNCE_MS);

    this.page = 1;
    this.query = value;
  });

  saveCounterpartyTask = dropTask(async (counterpartyChanges, closeModal) => {
    try {
      let highlightedItem = this.store.peekRecord('beneficiary', this.highlight);
      let { labels, vatRate } = counterpartyChanges;
      highlightedItem.setProperties({ labels, vatRate });
      await highlightedItem.saveCounterparty();

      await closeModal?.();
      // Show success toast message
      let message = this.intl.t('toasts.counterparty.updated');
      this.toastFlashMessages.toastSuccess(message);
      await this.model.searchTask.perform();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      // Show error toast message
      let message = this.intl.t('toasts.counterparty.failed');
      this.toastFlashMessages.toastError(message);
    }
  });

  deleteCounterpartyTask = dropTask(async (counterparty, closeModal) => {
    try {
      // Delete counterparty
      await counterparty.destroyRecord();
      await closeModal();
      this.highlight = '';
      // Show success toast message
      let message = this.intl.t('toasts.counterparty.deleted');
      this.toastFlashMessages.toastSuccess(message);
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      // Show error toast message
      let message = this.intl.t('toasts.counterparty.failed');
      this.toastFlashMessages.toastError(message);
    }
  });
}
