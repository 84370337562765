import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import CURRENCIES from 'qonto/constants/currencies';
import {
  CONTRACT_STATUS,
  LOCAL_STORAGE_PAY_LATER_MAIN_ACCOUNT_NOT_SELECTED_KEY,
  PAY_LATER_ELIGIBILITY_STATUSES,
} from 'qonto/constants/financing';
import {
  OPERATION_TYPES,
  SEPA_TRACKING_SETTLEMENT_CTA,
  SEPA_TRACKING_SETTLEMENT_EVENTS,
  STATUS,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
  createSepaSettlementTrackingEventPayload,
} from 'qonto/utils/transfers';

class SepaPayLaterTransferFlowDataContext {
  sepaTransfer = null;
  beneficiary = null;
  invoice = null;
  origin = null;
  supplierInvoiceId = null;
  isInstantFallback = false;
  confirmationResult = {};
  settlementResult = {};
  budgetName = null;
  availableCreditAmount = null;
  isPayLaterEligible = false;
  isPayLaterContractSigned = false;
  payLaterInstallments = null;
  defaultOperationType = OPERATION_TYPES.PAY_LATER;
  validationError = null;
  invoiceAttachmentLimits = null;
  minTransferAmount = null;
  isDedicatedFlow = true;
  signature = { url: '', status: '' };
  isMainAccountNotSelected = false;
  didValidateFinancing = false;

  constructor({ sepaTransfer, invoice, origin, beneficiary = null }) {
    this.sepaTransfer = sepaTransfer;
    this.invoice = invoice;
    this.origin = origin;
    this.beneficiary = beneficiary;
  }
}

export default class SepaPayLaterTransferFlowSetup extends FlowSetup {
  @service abilities;
  @service errors;
  @service flow;
  @service financing;
  @service homePage;
  @service intl;
  @service modals;
  @service organizationManager;
  @service payByInvoiceUploadManager;
  @service router;
  @service segment;
  @service store;
  @service toastFlashMessages;

  constructor(_owner, previousDataContext = {}) {
    super(...arguments);

    let { organization } = this.organizationManager;
    let { mainAccount } = organization;
    let { queryParams } = this.router.currentRoute;
    let { origin } = queryParams;
    let { beneficiary, invoice, sepaTransfer } = previousDataContext;

    let sepaTransferRecord =
      sepaTransfer ||
      this.store.createRecord('transfer', {
        activityTag: 'other_expense',
        amountCurrency: CURRENCIES.default,
        bankAccount: mainAccount,
        fx: false,
        organization,
        operationType: OPERATION_TYPES.PAY_LATER,
      });

    sepaTransferRecord.addIdempotencyKey();

    this.dataContext = new SepaPayLaterTransferFlowDataContext({
      sepaTransfer: sepaTransferRecord,
      beneficiary,
      invoice,
      origin,
    });

    let isMainAccountNotSelectedValue = safeLocalStorage.getItem(
      LOCAL_STORAGE_PAY_LATER_MAIN_ACCOUNT_NOT_SELECTED_KEY
    );

    if (isMainAccountNotSelectedValue) {
      this.dataContext.isMainAccountNotSelected = true;

      safeLocalStorage.removeItem(LOCAL_STORAGE_PAY_LATER_MAIN_ACCOUNT_NOT_SELECTED_KEY);
    }

    if (beneficiary) {
      sepaTransferRecord.set('beneficiary', beneficiary);

      copyBeneficiaryIntoTransfer(sepaTransferRecord, beneficiary, { forceCopy: true });
      copyBeneficiaryLabelsIntoTransfer(sepaTransferRecord, beneficiary);
      if (this.abilities.can('view vat bookkeeping')) {
        copyBeneficiaryVatIntoTransfer(sepaTransferRecord, beneficiary);
      }
    }

    this.segment.track('transfer-sepa_flow_started', {
      ...(origin && { origin }),
    });
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'financing');

    if (this.abilities.cannot('create pay later transfer in financing')) {
      return this.homePage.replaceWithDefaultPage();
    }

    try {
      let {
        eligibility,
        contractStatus,
        availableCreditAmount,
        invoiceAttachmentLimits,
        minTransferAmount,
      } = await this.financing.checkPayLaterEligibility();

      if (
        eligibility !== PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE ||
        Number(availableCreditAmount?.value) < Number(minTransferAmount?.value)
      ) {
        return this.homePage.replaceWithDefaultPage();
      }

      this.dataContext = {
        ...this.dataContext,
        availableCreditAmount,
        isPayLaterContractSigned: contractStatus === CONTRACT_STATUS.SIGNED,
        isPayLaterEligible: eligibility === PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE,
        invoiceAttachmentLimits,
        minTransferAmount,
      };
    } catch {
      return this.homePage.replaceWithDefaultPage();
    }

    let { queryParams } = this.router.currentRoute;
    let { invoiceId, transferId } = queryParams;

    if (invoiceId) {
      this.dataContext.invoice = this.store.peekRecord('supplier-invoice', invoiceId);
    }

    if (transferId) {
      this.usePreviousTransfer(transferId);
    }

    // clear query parameters
    this.router.replaceWith({ queryParams: { ...queryParams, invoiceId: null, transferId: null } });
  }

  @action
  onComplete({ invoice, origin, sepaTransfer, settlementResult }) {
    if (settlementResult) {
      let { status, declinedReason } = settlementResult;
      let isQontoBeneficiary = sepaTransfer.get('beneficiary.qontoBankAccount');

      let eventName;

      if (status === STATUS.DECLINED) {
        eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.REJECT_CLOSED;
      } else if (status === STATUS.PROCESSING) {
        eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.LOADING_CLOSED;
      } else if (status === 'timeout') {
        eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.TIMEOUT_CLOSED;
      } else {
        eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED;
      }

      let payload = createSepaSettlementTrackingEventPayload({
        invoice,
        origin,
        sepaTransfer,
        settlementStatus: status,
        declinedReason,
        cta: SEPA_TRACKING_SETTLEMENT_CTA.EXIT,
        isQontoBeneficiary,
      });

      this.segment.track(eventName, payload);
    }

    this.payByInvoiceUploadManager.resetState();
    this.handleRedirect(origin);
  }

  onAbortTask = dropTask(async ({ origin }, { id: stepId }) => {
    if (stepId !== 'invoice-upload') {
      let result = await this.openAbortModalTask.perform();
      if (result !== 'confirm') return false;
    }

    this.payByInvoiceUploadManager.resetState();

    this.segment.track('transfer_creation_exited', { origin });

    this.handleRedirect(origin);

    return true;
  });

  openAbortModalTask = dropTask(
    async () =>
      await this.modals.open('popup/destructive', {
        title: this.intl.t('transfers.exit-flow.title'),
        description: this.intl.t('transfers.exit-flow.subtitle'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('transfers.exit-flow.confirm'),
      })
  );

  handleRedirect(origin) {
    let redirectToRoute;

    switch (origin) {
      case TRANSFER_FLOW_ORIGIN.OVERVIEW:
        redirectToRoute = 'overview.index';
        break;
      case TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE:
        redirectToRoute = 'transfers.landing';
        break;
      default:
        redirectToRoute = TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT;
    }
    this.router.replaceWith(redirectToRoute);
  }

  usePreviousTransfer(id) {
    let transferRecord = this.store.peekAll('transfer').find(t => t.idempotencyKey === id);

    if (!transferRecord) return;

    let { organization } = this.organizationManager;
    let { mainAccount } = organization;
    let { sepaTransfer } = this.dataContext;

    transferRecord.operationType = sepaTransfer.operationType;
    transferRecord.bankAccount = mainAccount;

    this.dataContext.sepaTransfer = transferRecord;
  }
}
