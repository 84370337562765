import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class ReimbursementsUpgradePlan extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    if (
      this.abilities.can('use reimbursements request') ||
      variation('feature--boolean-empty-state-revamp')
    ) {
      return this.router.replaceWith('reimbursements');
    }
  }
}
