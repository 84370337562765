export default {
  "page-wrapper": "qxy",
  "container": "qxU",
  "spinner-container": "qxj",
  "left-panel": "qxW",
  "form": "qxu",
  "left-content": "qwS",
  "form-footer": "qwc",
  "delete-button": "qwq",
  "header": "qwZ",
  "preview-container": "qwR",
  "email-preview": "qwQ",
  "close-button": "qwe btn btn--icon-only btn--tertiary btn--large"
};
