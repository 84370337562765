import * as Sentry from '@sentry/ember';
import window from 'ember-window-mock';

import { NETWORK_ERROR_MESSAGES } from 'qonto/utils/error-info';

import config from './config/environment';

const UUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;

const IGNORE_ERRORS = [
  'The adapter operation was aborted',
  'Non-Error exception captured with keys',
  'Attempted to handle event `deleteRecord`', // ember-data internal
  'Attempted to handle event `pushedData`', // idem
  'POST https://api.qonto.com/v1/transfers returned a 403 Payload', // OTP error
  "Cannot read property '_avast_submit' of undefined", // Avast extension error https://github.com/getsentry/sentry/issues/9331
  'Out of stack space', // Edge error
  "Identifier 'change_ua' has already been declared",
  `can't redefine non-configurable property "userAgent"`, // Firefox related error caused by third party script or plugin
  'Failed to initialize NumberFormat since used feature is not supported in the linked ICU version', // Safari 14.1 has limited support for formatNumber formatting options and fails to initialize it
];

const THIRD_PARTY_URLS = [
  'https://api.segment.io/v1/',
  'https://ariane.abtasty.com',
  'https://api.amplitude.com',
];

const UNPROCESSABLE_STATUSES = [400, 422];

let isUnloading = false;

// heuristics to detect if the Sentry exception is a network error
export function isNetworkErrorException(exception) {
  return exception.type === 'TypeError' && NETWORK_ERROR_MESSAGES.has(exception.value);
}

export function hasOnlyThirdPartyErrors(breadcrumbs) {
  // there need to be breadcrumbs for us to be able to detect third party errors
  if (!breadcrumbs || breadcrumbs.length === 0) {
    return false;
  }

  // filter breadcrumbs down to all errors
  let errorBreadcrumbs = breadcrumbs.filter(breadcrumb => {
    return breadcrumb.level === 'error';
  });

  // don't filter if there are no error breadcrumbs
  if (!errorBreadcrumbs.length) {
    return false;
  }

  // we only want to filter if there are errors
  // AND all the errors are HTTP errors
  // AND all error URLs (sub)match with THIRD_PARTY_URLS.
  return errorBreadcrumbs.every(breadcrumb => {
    let { type, category, data } = breadcrumb;

    return (
      type === 'http' &&
      (category === 'fetch' || category === 'xhr') &&
      THIRD_PARTY_URLS.some(url => data?.url?.includes(url))
    );
  });
}

export function hasOnlyUnhandledThirdPartyErrorExceptions(event) {
  return event.exception?.values?.every(exception => {
    return (
      exception?.mechanism?.handled === false &&
      isNetworkErrorException(exception) &&
      hasOnlyThirdPartyErrors(event.breadcrumbs)
    );
  });
}

export function getServerError({ errors }) {
  if (!errors?.length) return;

  let [serverError] = errors;

  if (!serverError) return;
  let info = typeof serverError?.detail === 'object' ? serverError.detail : serverError;

  if (!info) return;

  let { detail: type, code: value } = info || {};

  if (!type && !value) return;

  return { type, value };
}

function isUnprocessableError({ status }) {
  return UNPROCESSABLE_STATUSES.includes(status);
}

function setUnloading() {
  isUnloading = true;
}

export function beforeSend(event, hint) {
  // when we send a new error to Sentry, the original error is contained within cause
  let error = hint.originalException?.cause || hint.originalException;

  if (error) {
    // ignore cancelation errors from the Ember router of ember-concurrency
    if (error.name === 'TaskCancelation' || error.name === 'TransitionAborted') {
      return null;
    }

    // ignore errors that contains strings from the IGNORE_ERRORS array
    if (error.message && IGNORE_ERRORS.some(it => error.message.includes(it))) {
      return null;
    }

    // A lot of the "TypeError: Failed to fetch" issues on Sentry are caused by misbehaving Chrome extensions.
    // The two most common ones load JS code from `/scripts/js` or `/scripts/jt`, so here we filter out any
    // errors that contain these paths in their stacktrace. Technically the `allowUrls` option above should
    // already filter them out, but for some reason that does not appear to work in this case.
    if (error.stack?.includes('/scripts/js') || error.stack?.includes('/scripts/jt')) {
      return null;
    }

    // filter certain network errors from unhandled exceptions (ideally just third party errors),
    // but only if all exceptions are unhandled third party network errors.
    if (hasOnlyUnhandledThirdPartyErrorExceptions(event)) {
      return null;
    }

    // ignore ChunkLoadErrors, these are network errors. Actionable statistics regarding these exist in Cloudfront.
    if (error.name === 'ChunkLoadError') {
      return null;
    }

    // filter out Network Errors, LD and 401 errors after the page is closing.
    if (
      (isUnloading &&
        ['NetworkError', 'LaunchDarklyFlagFetchError', 'AbortError'].includes(error.name)) ||
      (isUnloading && error.status === 401)
    ) {
      return null;
    }

    // filter out UnhandledRejection errors that have no information
    // Fix suggested here: https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-828834651
    if (
      event !== undefined &&
      event.exception !== undefined &&
      event.exception.values !== undefined &&
      event.exception.values.length === 1
    ) {
      let e = event.exception.values[0];
      if (
        e.type === 'UnhandledRejection' &&
        e.value?.includes?.('Non-Error promise rejection captured with value:')
      ) {
        return null;
      }
    }

    event.fingerprint = ['{{ default }}'];

    /**
      if the exception has an adapter assigned, add it to the fingerprint
      to prevent Sentry grouping issues by adapter
    */
    if (error.adapter) {
      event.fingerprint.push(String(error.adapter));
    }

    /**
     * We override the exception type and value with the server error
     * for "unprocessable" errors (400, 422) to avoid grouping issues
     */
    let overrides = getServerError(error);

    if (overrides && isUnprocessableError(error)) {
      event.exception.values[0] = { ...event.exception.values[0], ...overrides };
      event.fingerprint.push(overrides.value);
    }

    /**
     * if the exception is a NetworkManagerError group by URL
     */
    if (error.name === 'NetworkManagerError') {
      try {
        let url = new URL(error.url);
        let pathname = url.pathname.replace(UUID_REGEX, 'UUID');
        event.fingerprint.push(`${url.protocol}//${url.hostname}${pathname}`);
      } catch {
        // ignore
      }
    }
  }

  event.tags = {
    ...event.tags,
    missingOriginalException: !error,
  };

  return event;
}

export function init() {
  Sentry.init({
    ...config.sentry,

    allowUrls: [
      // Production:
      /^https:\/\/app\.qonto\.com/,
      // Staging:
      /^https:\/\/[^/]+.staging\d*\.qonto\.co/,
    ],

    ignoreErrors: [
      /ResizeObserver loop completed with undelivered notifications/i,
      /ResizeObserver loop limit exceeded/i,
    ],

    beforeSend,
  });

  window.addEventListener('beforeunload', setUnloading);
  window.addEventListener('unload', setUnloading);
}
