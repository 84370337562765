import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class ConnectionsAppsRoute extends Route {
  @service abilities;
  @service router;
  @service store;
  @service sentry;

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }

  beforeModel() {
    if (!this.abilities.can('view oauth')) {
      if (this.abilities.can('view connections external-account')) {
        this.router.transitionTo('settings.connections.banks');
      } else {
        this.router.transitionTo('application');
      }
    }
  }

  model() {
    return {
      appsConnectionsTask: this.modelFor('settings.connections').connections.appsConnectionsTask,
      banksConnectionsTask: this.modelFor('settings.connections').connections.banksConnectionsTask,
      canViewTabs: this.modelFor('settings.connections').canViewTabs,
    };
  }
}
