import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class FxTransferBeneficiaryFlowDataContext {
  beneficiary = null;
}

export default class FxTransferBeneficiaryFlowSetup extends FlowSetup {
  @service abilities;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service store;
  @service toastFlashMessages;

  dataContext = null;

  constructor() {
    super(...arguments);

    this.dataContext = new FxTransferBeneficiaryFlowDataContext();
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    if (
      this.abilities.cannot('create beneficiary') ||
      this.organizationManager.organization.hasInternationalOutFeature
    ) {
      return this.router.replaceWith('transfers.landing');
    }
  }

  onComplete() {}

  @action
  onSuccessToast(dataContext) {
    this._trackBeneficiarySelection(dataContext);

    if (dataContext.beneficiaryToEdit) {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('transfers.beneficiaries.edit-modal.success')
      );
    } else {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('transfers.beneficiaries.add-modal.success')
      );
    }
  }

  onAbortTask = dropTask(async dataContext => {
    let { origin, supplierInvoiceId, fxTransfer } = dataContext;

    let result = await this.openAbortModalTask.perform();

    if (result === 'cancel') return false;

    this.segment.track('transfer_creation_exited', {
      ...(origin && { origin }),
    });

    this._redirect({ url: 'transfers.landing', origin, fxTransfer, supplierInvoiceId });

    return true;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.exit-flow.title'),
      description: this.intl.t('transfers.exit-flow.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('transfers.exit-flow.confirm'),
    });
  });

  beforeRestoreTask = dropTask(async ({ fxTransfer, beneficiaryToEdit }) => {
    if (beneficiaryToEdit) {
      await this.store.findRecord('beneficiary', beneficiaryToEdit.id);
    }

    let { attachments, organization } = fxTransfer.relationships;

    if (attachments) {
      await this.store.query('attachment', {
        organization_id: organization.id,
        filters: { ids: attachments.map(({ id }) => id) },
        per_page: 500,
      });
    }
  });

  _redirect({ url, origin, fxTransfer, supplierInvoiceId, options }) {
    if (origin && supplierInvoiceId) {
      return this.router.transitionTo(origin, {
        queryParams: {
          transferStatus: fxTransfer.status,
          supplierInvoiceId,
        },
      });
    } else {
      return options ? this.router.transitionTo(url, options) : this.router.transitionTo(url);
    }
  }

  _trackBeneficiarySelection({ beneficiaryToEdit, origin }) {
    let isBeneficiaryEdited = Boolean(beneficiaryToEdit);

    this.segment.track('transfer-swift_beneficiary_selected', {
      ...(!isBeneficiaryEdited && { new_beneficiary: !isBeneficiaryEdited }),
      ...(isBeneficiaryEdited && { edited_beneficiary: isBeneficiaryEdited }),
      ...(origin && { origin }),
    });
  }
}
