import { NotFoundError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import dayjs from 'dayjs';
import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { COMMON_CREDIT_PERIODS } from 'qonto/components/receivable-invoices/form/due-date-selector';
import CURRENCIES from 'qonto/constants/currencies';
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { MIGRATION_CUT_OFF_DATE } from 'qonto/constants/products';
import { differenceInCalendar } from 'qonto/utils/date';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class ReceivableInvoicesDuplicateRoute extends Route {
  @service abilities;
  @service toastFlashMessages;
  @service flowLinkManager;
  @service intl;
  @service localeManager;
  @service menu;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;

  @reads('organizationManager.organization.activeSortedAccounts') activeAccounts;

  activate() {
    this.menu.hide();
  }

  async beforeModel({ routeInfos }) {
    let id = routeInfos.reduce((acc, { params }) => {
      return { ...acc, ...params };
    }, {}).id;
    let canReadInvoice = this.abilities.can('read receivableInvoice');

    if (canReadInvoice) {
      let { organization } = this.organizationManager;
      if (organization.legalCountry === 'IT') {
        let eInvoiceActivation = await this.store.findRecord(
          'e-invoice-activation',
          organization.id
        );
        if (!eInvoiceActivation.eInvoicingActivated) {
          this.flowLinkManager.transitionTo({
            name: 'invoice-onboarding',
            stepId: 'einvoice-activation',
            queryParams: { destination: 'duplicate', invoiceID: id },
          });
        }
      }
    }
  }

  async model({ id }, transition) {
    let { organization } = this.organizationManager;

    let settings;

    try {
      settings = await this.store.findRecord('receivable-invoices-settings', organization.id);
    } catch (error) {
      if (error instanceof NotFoundError) {
        return;
      } else {
        this._redirectOnError();
        return;
      }
    }

    let documentItems = await this.fetchDocumentItemsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => this.ignoreNotFoundAndHandleError(error));

    try {
      await this.fetchOrganizationAvatarTask.perform(organization);
    } catch (error) {
      this.handleError(error);
    }

    let invoice = await this.store.findRecord('receivable-invoice', id);

    let {
      beneficiaryName,
      bic,
      contactEmail,
      iban,
      termsAndConditions,
      welfareFund,
      withholdingTax,
      payment,
      stampDutyAmount,
      items,
      status,
      header,
      footer,
      currency,
      organizationSnapshot,
    } = invoice;

    let { locale } = this.localeManager;

    let issueDate = dayjs().format(DATE_PICKER_FIELD_FORMAT);
    let performanceDate =
      organization.legalCountry === 'DE' ? dayjs().format(DATE_PICKER_FIELD_FORMAT) : null;
    let creditPeriod = differenceInCalendar(invoice.dueDate, invoice.issueDate, 'day');
    let daysToAdd = COMMON_CREDIT_PERIODS.includes(creditPeriod) ? creditPeriod : 15;
    let dueDate = dayjs().add(daysToAdd, 'day').format(DATE_PICKER_FIELD_FORMAT);

    // load customers into memory, will use peekAll later to display
    // they will be used for the customer selector possible values
    await this.store.query('customer', {
      filter: { organization_id: organization.id },
    });

    if (variation('feature--boolean-client-hub')) {
      await this.fetchClientsTask.perform().catch(ignoreCancelation).catch(this.handleError);
    }

    let customerRecord;
    if (invoice.belongsTo('customer').id()) {
      if (variation('feature--boolean-client-hub')) {
        customerRecord = this.store.peekRecord('client-hub', invoice.belongsTo('customer').id());
      } else {
        customerRecord = this.store.peekRecord('customer', invoice.belongsTo('customer').id());
      }
    }

    let duplicatedCurrency = customerRecord?.currency || currency || CURRENCIES.default;

    let lastInvoice, lastNumber;
    try {
      lastInvoice = await this.store.modelFor('receivable-invoice').last(this.store);
      lastNumber = lastInvoice.number;
    } catch (error) {
      // we expect 404 if no last invoice
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 404) {
        this.sentry.captureException(error);
      }
    }
    let duplicatedWelfareFund = this.store.createRecord('receivable-invoice/welfare-fund', {
      type: welfareFund?.type,
      rate: welfareFund?.rate,
    });

    let duplicatedWithholdingTax = this.store.createRecord('receivable-invoice/withholding-tax', {
      type: withholdingTax?.type,
      rate: withholdingTax?.rate,
      reason: withholdingTax?.reason,
    });

    let duplicatedPayment = this.store.createRecord('receivable-invoice/payment', {
      conditions: payment?.conditions,
      method: payment?.method,
    });

    let bankAccount = this.activeAccounts.find(account => account.iban === iban);

    let duplicatedItems = [];

    items.forEach(item => {
      let {
        title,
        description,
        quantity,
        unitPrice,
        vatRate,
        vatExemptionCode,
        discount,
        unit,
        productId,
        links,
        type,
      } = item;
      duplicatedItems.push(
        this.store.createRecord('receivable-invoice/item', {
          title,
          description,
          quantity,
          unitPrice,
          vatRate,
          vatExemptionCode,
          discount,
          unit,
          productId,
          links,
          type,
        })
      );
    });

    if (!settings.contactEmail) {
      settings.contactEmail = this.organizationManager.membership.email;
    }
    contactEmail = settings.contactEmail;

    let nextNumber =
      settings?.numberingMode === 'automatic'
        ? settings.invoiceNextNumberFormatted || settings.nextInvoiceNumber
        : '';

    let duplicatedInvoice = {};

    // when coming from the settings modal, there might be already one recorded invoice in the store
    // instead of initializing a new one, the user will see the started one in the form
    if (transition?.from?.name === 'invoicing-settings' && this.peekRecordedInvoices.length > 0) {
      // only one newly created invoice can be expected inside the array
      duplicatedInvoice = this.peekRecordedInvoices[0];
      // the email needs to be the latest one
      duplicatedInvoice.contactEmail = settings.contactEmail;
    } else {
      let duplicatedFreshInvoice = {
        number: nextNumber,
        bankAccount,
        beneficiaryName,
        bic,
        customer: customerRecord,
        lastNumber,
        stampDutyAmount,
        issueDate,
        dueDate,
        performanceDate,
        organization,
        iban,
        items: duplicatedItems,
        locale,
        termsAndConditions,
        contactEmail,
        header,
        footer,
        welfareFund: duplicatedWelfareFund,
        withholdingTax: duplicatedWithholdingTax,
        payment: duplicatedPayment,
        organizationSnapshot,
        currency: duplicatedCurrency,
      };

      duplicatedInvoice = this.store.createRecord('receivable-invoice', duplicatedFreshInvoice);
    }

    duplicatedInvoice.organization.vatNumber = settings.vatNumber;

    if (organization.legalCountry === 'DE') {
      duplicatedInvoice.header = settings.invoiceHeader;
      duplicatedInvoice.footer = settings.invoiceFooter;
      duplicatedInvoice.organization.taxNumber = settings.taxNumber;
      duplicatedInvoice.organization.companyLeadership = settings.companyLeadership;
      duplicatedInvoice.organization.districtCourt = settings.districtCourt;
      duplicatedInvoice.organization.commercialRegisterNumber = settings.commercialRegisterNumber;
    }

    let toastMessage =
      status === 'draft'
        ? this.intl.t('receivable-invoices.duplicate-draft.toast-success')
        : this.intl.t('receivable-invoices.duplicate-invoice.toast-success');

    this.toastFlashMessages.toastInfo(toastMessage);

    let isFirstInvoice = Boolean(!lastInvoice);

    return { invoice: duplicatedInvoice, settings, isFirstInvoice, documentItems };
  }

  get peekRecordedInvoices() {
    // peek in the store the already created but not saved invoice without an id
    return this.store
      .peekAll('receivable-invoice')
      .filter(invoice => invoice.isNew && invoice.id === null);
  }

  fetchDocumentItemsTask = restartableTask(async () => {
    let documentItemsParams = {
      filter: {
        document_types: 'invoices,quotes,credit_notes',
        created_at_to: variation('feature-invoices-catalog-of-items')
          ? MIGRATION_CUT_OFF_DATE
          : undefined,
      },
      page: { size: 200, number: 1 },
      sort: '-total_documents,title,-unit_price',
    };
    let documentItems = await this.store.query('document-item', documentItemsParams);
    let total = documentItems.meta.total;

    while (total > documentItems.length) {
      documentItemsParams.page.number++;
      let nextDocumentItems = await this.store.query('document-item', documentItemsParams);
      documentItems = documentItems.concat(nextDocumentItems);
    }

    return documentItems;
  });

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  }

  ignoreNotFoundAndHandleError(error) {
    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.handleError(error);
    }
  }

  deactivate() {
    this.menu.show();
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    return this.router.transitionTo('receivable-invoices.index');
  }

  resetController() {
    super.resetController(...arguments);
  }
}
