/* eslint-disable @qonto/no-import-roles-constants, ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask, task, timeout } from 'ember-concurrency';

import { APPROVAL_WORKFLOW_STATUSES } from 'qonto/constants/approval-workflow';
import { REQUEST_EVENTS } from 'qonto/constants/listeners';
import { ROLES } from 'qonto/constants/membership';
import { REQUEST_TYPES } from 'qonto/constants/requests';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class TransfersRequestsRoute extends Route {
  @service abilities;
  @service router;
  @service notifierManager;
  @service store;
  @service organizationManager;
  @service featuresManager;
  @service requestsManager;
  @service sentry;

  activate() {
    let controller = this.controllerFor(this.routeName);

    // TODO: would be nice to have reloadData, employeeCanceled and adminSettled as tasks and improved naming
    if (controller.isApprover) {
      this.notifierManager.on(REQUEST_EVENTS.EMPLOYEE_CREATED, controller, 'reloadData');
      this.notifierManager.on(REQUEST_EVENTS.EMPLOYEE_CANCELED, controller, 'employeeCanceled');
    } else {
      this.notifierManager.on(REQUEST_EVENTS.ADMIN_APPROVED, controller, 'adminSettled');
      this.notifierManager.on(REQUEST_EVENTS.ADMIN_DECLINED, controller, 'adminSettled');
    }
  }

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
  };

  get isApprover() {
    return this.abilities.can('review transfer request');
  }

  get isRequester() {
    let isManagerRequester =
      this.organizationManager.membership.role === ROLES.MANAGER &&
      this.abilities.cannot('review transfer request') &&
      this.abilities.can('read request');

    let isEmployeeRequester =
      this.organizationManager.membership.role === ROLES.EMPLOYEE &&
      this.abilities.cannot('review transfer request') &&
      this.abilities.can('create transfer request');

    return isManagerRequester || isEmployeeRequester;
  }

  beforeModel() {
    let isNotApproverOrRequester = !this.isApprover && !this.isRequester;

    if (isNotApproverOrRequester) {
      return this.router.replaceWith('transfers.pending');
    }
  }

  model(params) {
    let controller = this.controllerFor(this.routeName);

    this.fetchRequestsTask
      .perform(params, false)
      .then(() => {
        if (params.highlight) {
          let request = controller.requests.find(req => req.id === params.highlight);
          if (request) {
            return request.belongsTo('approvalWorkflowState').reload();
          }
        }
      })
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    if (this.abilities.can('read transfer')) {
      this.fetchTotalCountTask.perform().catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
    }

    return {
      fetchRequestsTask: this.fetchRequestsTask,
      fetchTotalCountTask: this.fetchTotalCountTask,
    };
  }

  fetchTotalCountTask = restartableTask(async () => {
    return await this.fetchTask.perform({ per_page: 1, status: null, bankAccounts: null });
  });

  fetchTask = task(
    async ({
      highlight,
      complete,
      page,
      per_page,
      sort_by,
      bankAccounts,
      status = this.statusFilters,
    }) => {
      let params = {
        highlight,
        complete,
        page,
        per_page,
        sort_by,
        filters: { status },
      };

      if (bankAccounts) {
        params.filters.bank_account_ids = bankAccounts.split(',');
      }

      let nonNullParams = filterParams(params);
      return await this.fetchTransfer(nonNullParams);
    }
  );

  fetchTransfer({ page, per_page, sort_by, filters }) {
    let organizationId = this.organizationManager.organization.id;

    let includes = ['beneficiaries', 'memberships'];

    let requiredParams = { filters, includes, organization_id: organizationId };
    let optionalParams = { page, per_page, sort_by };
    let mergedParams = { ...requiredParams, ...optionalParams };
    return this.store.query('transfer', mergedParams);
  }

  async setupController(controller, model) {
    super.setupController(controller, model);

    let { membership } = this.organizationManager;
    let { isRequester, highlight, requests } = controller;

    if (isRequester && membership.role === ROLES.MANAGER) {
      await membership.getSpendLimits();
    }

    if (isRequester && highlight) {
      let request = requests.find(({ id }) => id === highlight);

      if (request?.requestType === REQUEST_TYPES.TRANSFER) {
        await this.requestsManager.confirmRequestTransferTask.perform(request);
      }

      controller.allocatedBudget =
        await this.requestsManager.fetchAllocatedBudgetTask.perform(request);
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.fetchTotalCountTask.cancelAll({ resetState: true });
      controller.resetQueryParams();
    }
  }

  deactivate() {
    let controller = this.controllerFor(this.routeName);
    controller.destroyCurrentTransfer();

    this.notifierManager.off(REQUEST_EVENTS.EMPLOYEE_CREATED, controller, 'reloadData');
    this.notifierManager.off(REQUEST_EVENTS.EMPLOYEE_CANCELED, controller, 'employeeCanceled');
    this.notifierManager.off(REQUEST_EVENTS.ADMIN_DECLINED, controller, 'adminSettled');
    this.notifierManager.off(REQUEST_EVENTS.ADMIN_APPROVED, controller, 'adminSettled');
  }

  fetchRequestsTask = restartableTask(async (queryParams = {}) => {
    await timeout(DEBOUNCE_MS);

    let { page, per_page, sort_by } = filterParams(queryParams);
    let query = {
      includes: ['memberships'],
      organization_id: this.organizationManager.organization.id,
      request_type: ['transfer', 'multi_transfer'],
      page,
      per_page,
      sort_by,
    };

    // approver
    if (this.abilities.can('review transfer request')) {
      query.status = ['pending'];
      if (this.featuresManager.isEnabled('approvalWorkflows')) {
        query.approval_workflow_status = APPROVAL_WORKFLOW_STATUSES.AWAITS_MY_APPROVAL;
      }
    }

    let requests = await this.store.query('request', query);

    requests.forEach(request => {
      if (!request.bankAccount) {
        request.bankAccount = this.organizationManager.organization.defaultAccount;
      }
    });

    return requests;
  });
}
