export default {
  "container": "qbu",
  "security-method": "qyS",
  "icon": "qyc",
  "body": "qyq",
  "status": "qyZ body-2",
  "device": "qyR",
  "device-icon": "qyQ",
  "subtitle": "qye body-2",
  "current": "qyB"
};
