import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { BANK_CONNECTION_OUTCOME } from 'qonto/constants/connect';

export default class ConnectionsBanksRoute extends Route {
  @service abilities;
  @service router;
  @service store;
  @service sentry;
  @service intl;
  @service toastFlashMessages;

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }

  beforeModel() {
    if (!this.abilities.can('view connections external-account')) {
      if (this.abilities.can('view oauth')) {
        this.router.transitionTo('settings.connections.apps');
      } else {
        this.router.transitionTo('application');
      }
    }
  }

  model({ outcome }) {
    switch (outcome) {
      case BANK_CONNECTION_OUTCOME.SUCCESS:
        this.toastFlashMessages.toastSuccess(
          this.intl.t('settings.connections.reconnect-bank.toast.success')
        );
        break;

      case BANK_CONNECTION_OUTCOME.ERROR:
        this.toastFlashMessages.toastError(
          this.intl.t('settings.connections.reconnect-bank.toast.error')
        );
        break;
    }

    return {
      banksConnectionsTask: this.modelFor('settings.connections').connections.banksConnectionsTask,
      appsConnectionsTask: this.modelFor('settings.connections').connections.appsConnectionsTask,
      canViewTabs: this.modelFor('settings.connections').canViewTabs,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('outcome', null);
    }
  }
}
