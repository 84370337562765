import Controller from '@ember/controller';
import { service } from '@ember/service';

const ROUTES = {
  APPS: 'settings.connections.apps',
  BANKS: 'settings.connections.banks',
};

export default class ConnectionsController extends Controller {
  @service router;

  get tabs() {
    return {
      apps: {
        route: ROUTES.APPS,
        active: this.router.currentRouteName === ROUTES.APPS,
      },
      banks: {
        route: ROUTES.BANKS,
        active: this.router.currentRouteName === ROUTES.BANKS,
      },
    };
  }
}
