import { ORIGIN } from 'qonto/constants/cards';

export const cardNewPhysical = {
  steps: {
    'choose-card': {
      componentName: 'cards/choose-card-physical',
      nextStepId: ({ showCardCustomization, hasMultipleActiveAccounts, origin }) => {
        if (showCardCustomization) {
          return 'customization';
        }
        if (hasMultipleActiveAccounts) {
          return 'bank-account';
        }
        return origin === ORIGIN.MEMBER_INVITATION ? 'options' : 'holder';
      },
    },
    customization: {
      componentName: 'cards/customization-new',
      nextStepId: ({ hasMultipleActiveAccounts, origin }) => {
        if (hasMultipleActiveAccounts) {
          return 'bank-account';
        }

        return origin === ORIGIN.MEMBER_INVITATION ? 'options' : 'holder';
      },
    },
    'bank-account': {
      componentName: 'cards/bank-account',
      nextStepId: ({ origin }) => (origin === ORIGIN.MEMBER_INVITATION ? 'options' : 'holder'),
    },
    holder: {
      componentName: 'cards/holder',
      nextStepId: 'options',
    },
    options: {
      componentName: 'cards/options',
      nextStepId: 'restrictions',
    },
    restrictions: {
      componentName: 'cards/restrictions',
      nextStepId: 'payment-limits',
    },
    'payment-limits': {
      componentName: 'cards/payment-limits',
      nextStepId: ({ card }) => (card.atmOption ? 'withdrawal-limits' : 'delivery-address'),
    },
    'withdrawal-limits': {
      componentName: 'cards/withdrawal-limits',
      nextStepId: 'delivery-address',
    },
    'delivery-address': {
      componentName: 'cards/delivery-address',
      nextStepId: ({ isUserCardHolder }) => (isUserCardHolder ? 'set-pin' : 'review'),
    },
    'set-pin': {
      componentName: 'cards/set-pin',
      nextStepId: 'confirm-pin',
    },
    'confirm-pin': {
      componentName: 'cards/confirm-pin',
      nextStepId: 'review',
    },
    review: {
      componentName: 'cards/review',
      nextStepId: ({ isUserCardHolder, kycSubmitted, origin }) => {
        if (isUserCardHolder && !kycSubmitted) {
          return 'kyc-intro';
        }

        return origin === ORIGIN.MEMBER_INVITATION ? 'success-invitee' : 'success';
      },
    },
    'kyc-intro': {
      componentName: 'cards/physical-card-kyc-intro',
    },
    success: {
      componentName: 'cards/success-new-physical',
    },
    'success-invitee': {
      componentName: 'cards/success-invitee-physical-one',
    },
  },
  options: {
    enablePersistence: false,
  },
};
