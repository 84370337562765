import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/invoice-subscriptions';
import { TRACKING_ORIGINS, TYPES } from 'qonto/constants/empty-states/system';
import {
  LOST_ELIGIBILITY_BANNER_LOCAL_STORAGE_KEY,
  STATUS,
  TAB,
} from 'qonto/constants/invoice-subscriptions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InvoiceSubscriptionsIndexController extends Controller {
  @service organizationManager;
  @service modals;
  @service segment;
  @service intl;
  @service emptyStates;
  @service router;
  @service flowLinkManager;
  @service subscriptionManager;
  @service abilities;
  @service invoiceSubscriptionManager;

  queryParams = ['status', 'page', 'perPage', 'highlighted', 'sortBy'];
  @tracked status = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked highlighted = null;
  @tracked sortBy = 'next_invoice_date,-customers.name';
  @tracked isBannerVisible = !this.isBannerDismissed;

  get settings() {
    return this.model.settingsTask.lastSuccessful?.value;
  }

  get isBannerDismissed() {
    return safeLocalStorage.getItem(LOST_ELIGIBILITY_BANNER_LOCAL_STORAGE_KEY);
  }

  get displayGlobalEmptyState() {
    return (
      !this.hasError &&
      !this.isLoading &&
      !this.isEmptyLocally &&
      (this.model.statsTask.lastSuccessful?.value.created.total === 0 ||
        this.abilities.cannot('use invoice-subscription'))
    );
  }

  @action
  emptyStateActivationCTA(origin) {
    this.emptyStates.trackCta(this.emptyStateOptions, origin);
    this.router.transitionTo('invoice-subscriptions.new');
  }

  @action
  onEmptyStateCTAClick() {
    return this.emptyStateOptions.onClick(TRACKING_ORIGINS.PRIMARY);
  }

  get isLoading() {
    return this.model.subscriptionsTask.isRunning || this.model.statsTask.isRunning;
  }

  get hasError() {
    return this.model.subscriptionsTask.last?.isError || this.model.statsTask.last?.isError;
  }

  get emptyStateCtaText() {
    if (this.emptyStateOptions?.code?.startsWith(TYPES.UPSELL)) {
      return this.intl.t('empty-states.system.upsell.u1.cta');
    } else if (this.emptyStateOptions?.code?.startsWith(TYPES.TRY)) {
      return this.intl.t('empty-states.system.try.t1.cta');
    } else {
      return this.intl.t('recurring-invoices.list.primary-cta');
    }
  }

  @action
  retryFetchData() {
    let params = {
      status: this.status,
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
      highlighted: this.highlighted,
    };

    this.model.fetchDataTask.perform(params).catch(ignoreCancelation);
  }

  @action handleSortBy(sortDefinition) {
    this.sortBy = sortDefinition;
  }

  @action
  emptyStateUpsellCTA(origin) {
    this.emptyStates.trackCta(this.emptyStateOptions, origin);
    this.flowLinkManager.transitionTo({ name: 'subscription-change', stepId: 'plans' });
  }

  get isCurrentFilterEmpty() {
    let currentSelection = this.status?.split(',');
    if (currentSelection.length > 1) {
      return false;
    }
    let subscriptions = this.model.statsTask.lastSuccessful?.value?.created;
    if (!subscriptions) return false;

    let { total } = subscriptions;

    if (!total) return true;

    return subscriptions[currentSelection[0]] === 0 && total > 0;
  }

  @action
  trackNewSubscriptionBtn() {
    if (this.isCurrentFilterEmpty || !this.isEmptyLocally) {
      this.segment.track('recurring-invoices_cta_clicked', {
        empty_state: false,
      });
    } else {
      this.segment.track('recurring-invoices_cta_clicked', {
        cta_type: 'create',
        empty_state: true,
        origin: 'empty_state_primary',
        'empty-state_type': 'inform',
      });
    }
  }

  @action
  emptyStateFreeTrialCTA(origin) {
    this.emptyStates.trackCta(this.emptyStateOptions, origin);
    this.modals.open('discover/trial/confirm', {
      isFullScreenModal: true,
      trialInfo: this.trialInfo,
    });
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('recurringInvoices');
  }

  get isEmptyLocally() {
    if (this.abilities.cannot('use invoice-subscription')) return false;
    let subscriptions = this.model.statsTask.lastSuccessful?.value?.created;
    if (!subscriptions) return false;

    let { total, finished, canceled } = subscriptions;

    if (!total) return false;

    let currentSelection = this.status?.split(',');

    if (currentSelection.length > 1) {
      if (this.currentTab === TAB.PROCESSING) {
        return total - (finished + canceled) === 0;
      }

      return finished + canceled === 0;
    }

    return subscriptions[currentSelection[0]] === 0;
  }

  get isEmptyState() {
    return (
      this.isEmptyLocally ||
      this.model.statsTask.lastSuccessful?.value.created.total === 0 ||
      this.abilities.cannot('use invoice-subscription')
    );
  }

  get emptyStateOptions() {
    if (!this.isLoading && this.isEmptyState) {
      let options = this.emptyStates.getEmptyStateOptions({
        isOrgEligibleForFeature: this.abilities.can('use invoice-subscription'),
        isEmptyGlobally:
          this.model.statsTask.lastSuccessful?.value.created.total === 0 ||
          this.abilities.cannot('use invoice-subscription'),
        isEmptyLocally: this.isEmptyLocally,
        hasActiveFilterOrSearch: this.status?.split(',').length === 1,
        tab: this.currentTab,
        config: getEmptyStateConfig(this.intl, {
          ctaCallback: {
            upsell: this.emptyStateUpsellCTA,
            trial: this.emptyStateFreeTrialCTA,
            activate: this.emptyStateActivationCTA,
          },
          trialMonths: this.trialInfo?.monthDuration,
          currentTab: this.currentTab,
        }),
      });
      this.invoiceSubscriptionManager.setEmptyStateCode(options?.code);
      return options;
    }
  }

  get currentTab() {
    if ([STATUS.CANCELED, STATUS.FINISHED].includes(this.status?.split(',')[0])) {
      return TAB.COMPLETED;
    }
    return TAB.PROCESSING;
  }

  get showLostEligibilityWarningBanner() {
    return (
      this.model.sddActivation?.isActivatedNotEligible &&
      !this.displayGlobalEmptyState &&
      this.isBannerVisible
    );
  }

  @action
  handleShowItem(subscription) {
    this.segment.track('recurring-invoices_row_clicked', { status: subscription.status });
    this.highlighted = subscription.id;
    this.model.refreshSidebarData(subscription.id);
  }

  @action
  closeSidepanel() {
    this.highlighted = null;
  }

  @action changePage(page) {
    this.page = page;
  }

  @action changePerPage(perPage) {
    this.perPage = perPage;
  }

  @action onCloseWarningBanner() {
    safeLocalStorage.setItem(LOST_ELIGIBILITY_BANNER_LOCAL_STORAGE_KEY, true);
    this.isBannerVisible = false;
  }

  get filterValue() {
    if (this.status?.split(',').length > 1) {
      return;
    }

    return this.filterOptions[this.currentTab].find(element => element.code === this.status);
  }

  get filterOptions() {
    return {
      [TAB.PROCESSING]: [
        { code: STATUS.SCHEDULED, value: this.intl.t('recurring-invoices.statuses.scheduled') },
        { code: STATUS.ACTIVE, value: this.intl.t('recurring-invoices.statuses.active') },
        { code: STATUS.SUSPENDED, value: this.intl.t('recurring-invoices.statuses.suspended') },
      ],
      [TAB.COMPLETED]: [
        { code: STATUS.FINISHED, value: this.intl.t('recurring-invoices.statuses.completed') },
        { code: STATUS.CANCELED, value: this.intl.t('recurring-invoices.statuses.canceled') },
      ],
    };
  }

  get currentTabOptions() {
    return this.filterOptions[this.currentTab];
  }

  @action
  updateFilter(selected) {
    if (!selected) {
      this.status = this.filterOptions[this.currentTab].map(element => element.code).join(',');
      return;
    }
    this.status = selected.code;
  }

  get subscriptions() {
    return this.model.subscriptionsTask.lastSuccessful?.value.subscriptions || [];
  }

  get showFilter() {
    return this.subscriptions?.length > 0 || this.status?.split(',').length === 1;
  }
}
