import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { apiBaseURL, oauthNamespace } from 'qonto/constants/hosts';
import { LOCAL_STORAGE_ORGANIZATION_KEY } from 'qonto/constants/oauth';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import { markHandledBySentry } from 'qonto/utils/handled-by-sentry';

const SENTRY_IGNORE_HTTP_STATUS = 410;

export default class OauthConsentRoute extends Route {
  @service('sentry') sentry;
  @service organizationManager;
  @service networkManager;

  async model(params) {
    let { consent_challenge: consentChallenge } = params;
    let organizationId = safeLocalStorage.getItem(LOCAL_STORAGE_ORGANIZATION_KEY);

    if (!consentChallenge || !organizationId) {
      return this.replaceWith('/404');
    }

    try {
      return await this.networkManager.request(
        `${apiBaseURL}/${oauthNamespace}/oauth/consents?consent_challenge=${consentChallenge}`,
        { headers: { 'X-Qonto-Organization-ID': organizationId } }
      );
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== SENTRY_IGNORE_HTTP_STATUS) {
        this.sentry.captureException(error);
      }
      markHandledBySentry(error);
      throw error;
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.organizationId = safeLocalStorage.getItem(LOCAL_STORAGE_ORGANIZATION_KEY);
    safeLocalStorage.removeItem(LOCAL_STORAGE_ORGANIZATION_KEY);
  }
}
