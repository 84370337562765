import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { apiBaseURL, oauthNamespace } from 'qonto/constants/hosts';
// eslint-disable-next-line @qonto/no-import-roles-constants
import { ROLES } from 'qonto/constants/membership';

export default class OauthIndexRoute extends Route {
  @service networkManager;
  @service organizationManager;
  @service store;
  @service userManager;

  async model(params) {
    let { login_challenge: loginChallenge } = params;

    if (!loginChallenge) {
      return this.replaceWith('/404');
    }

    let login = await this.networkManager.request(
      `${apiBaseURL}/${oauthNamespace}/oauth/login_requests?login_challenge=${loginChallenge}`
    );

    let organizationsRecord = this.store
      .peekAll('organization')
      .filter(({ accessDisabled }) => !accessDisabled);

    let userMembershipsIds = this.userManager.currentUser.memberships.map(({ id }) => id);

    let organizations = await Promise.all(
      organizationsRecord.map(async organization => {
        let organizationMembership = organization.memberships.find(({ id }) =>
          userMembershipsIds.includes(id)
        );

        let membership = await this.store.findRecord('membership', organizationMembership.id);

        // We need to directly check the permissions on membership here
        // as we iterate our current membership through multiple organizations
        organization.disabled = !membership.permissions?.oauth?.rules.some(
          rule => rule.action === 'login'
        );

        return organization;
      })
    );

    return { login, organizations };
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    let organizations = this.store
      .peekAll('organization')
      .filter(({ accessDisabled }) => !accessDisabled);

    let userMembershipsIds = this.userManager.currentUser.memberships.map(({ id }) => id);

    controller.organizations = organizations.map(organization => {
      let organizationMembership = organization.memberships.find(({ id }) =>
        userMembershipsIds.includes(id)
      );

      organization.disabled =
        organizationMembership.role === ROLES.MANAGER ||
        organizationMembership.role === ROLES.EMPLOYEE;

      return organization;
    });

    if (this.organizationManager.organizations.length === 1) {
      controller.organization = this.organizationManager.organizations[0];
    }
  }
}
