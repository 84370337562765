export default {
  "container": "qhi",
  "form-container": "qhA",
  "preview-container": "qhY",
  "credit-note-preview-container": "qhh",
  "credit-note-preview": "qhf",
  "content": "qhK",
  "title": "qhG",
  "tabs-container": "qhr",
  "close-button": "qhb btn btn--icon-only btn--tertiary btn--large",
  "header": "qhy",
  "without-tabs": "qhU"
};
