export default {
  "title": "qbA title-3",
  "personal-profile-container": "qbY",
  "personal-profile-section": "qbh",
  "member-info-title": "qbf",
  "kyc-complete-profile": "qbK",
  "info-fields": "qbG",
  "field": "qbr",
  "address-fields": "qbb",
  "field-span": "qby",
  "input": "qbU",
  "cancel": "qbj"
};
