import { action } from '@ember/object';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';

import { getEmptyStateConfig } from '../../../constants/empty-states/transfers';
import BaseTransfersController from '../base-controller';

export default class PendingTransfersController extends BaseTransfersController {
  @service abilities;
  @service emptyStates;
  @service intl;
  @service organizationManager;
  @service notifierCounterManager;

  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  get shouldShowNotification() {
    return this.notifierCounterManager.counter?.transferRequests > 0;
  }

  get pendingAriaLabel() {
    return this.shouldShowNotification
      ? this.intl.t('transfers.tabs.tasks.pending.aria-label')
      : this.intl.t('transfers.tabs.tasks.no-pending.aria-label');
  }

  get selectedBankAccount() {
    let { accounts } = this.organizationManager;
    return (
      accounts.find(({ id }) => id === this.bankAccounts) || this.organizationManager.currentAccount
    );
  }

  @action
  handleTracking(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'transfers',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });
      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  @action
  handleHeaderTracking(origin) {
    if (variation('feature--boolean-empty-state-revamp')) {
      this.handleTracking(origin);
    } else {
      this.handleTriggerTrackEvent();
    }
  }

  get shouldShowTabs() {
    return !this.selectedBankAccount?.isClosed;
  }

  get isEmptyGlobally() {
    let { transferRequestsCompleted = 0, transferRequests = 0 } =
      this.notifierCounterManager.counter || {};
    return (
      transferRequestsCompleted + transferRequests === 0 &&
      this.model.fetchTotalCountTask.last?.value?.meta?.total_count === 0
    );
  }

  get isEmptyLocally() {
    return this.model.fetchTransfersTask.last?.value?.meta?.total_count === 0;
  }

  get isEmptyStatePreviewLayout() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get isEmptyStateInformLayout() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get emptyStateRevampOptions() {
    if (
      !variation('feature--boolean-empty-state-revamp') ||
      this.model.fetchTransfersTask.isRunning ||
      this.model.fetchTransfersTask.last?.isError ||
      this.model.fetchTotalCountTask.isRunning ||
      this.model.fetchTotalCountTask.last?.isError
    ) {
      return;
    }

    let isApprover = this.abilities.can('create transfer');
    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.isEmptyLocally,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl),
      customInputs: {
        tab: 'processing',
        isSubAccountIsClosed: Boolean(this.selectedBankAccount?.isClosed),
      },
      abilities: {
        canCreateExternalTransfers: isApprover,
        canRequestTransfers: this.abilities.can('create transfer request'),
      },
    });
  }

  get emptyStateOptions() {
    if (this.model.fetchTransfersTask.isRunning || this.model.fetchTransfersTask.last.isError) {
      return false;
    }

    let isEmpty = this.transfers.length === 0;

    if (isEmpty) {
      if (this.selectedBankAccount?.isClosed) {
        let button = {
          label: this.intl.t('empty-states.shared.closed-account.see-all-accounts.button'),
          callback: () => {
            this.router.transitionTo('transfers.pending', {
              queryParams: { 'bank-accounts': null },
            });
          },
        };
        return {
          title: this.intl.t('empty-states.transfers.closed-account.title'),
          subtitle: this.intl.t('empty-states.transfers.closed-account.subtitle'),
          lottieSrc: '/lotties/empty-state/no-past-transfers.json',
          button: this.organizationManager.organization.hasMultipleActiveCurrentRemuneratedAccounts
            ? button
            : null,
        };
      }

      return {
        title: this.intl.t('transfers.processing.empty.title'),
        subtitle: this.intl.t('transfers.processing.empty.subtitle'),
        lottieSrc: '/lotties/empty-state/no-pending-transfers.json',
      };
    }

    return false;
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }
}
