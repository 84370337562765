import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { ORIGIN, TRACKING_ORIGIN } from 'qonto/constants/cards';
import CardNewAbstractFlowSetup from 'qonto/routes/flows/setup/cards/new/abstract';
import { ErrorInfo } from 'qonto/utils/error-info';

export class CardNewPhysicalDataContext {
  budgetName = null;

  constructor({ card, kycSubmitted, origin }) {
    this.card = card;
    this.isCardUnderCreation = true;
    this.kycSubmitted = kycSubmitted;
    this.origin = origin;
  }
}

export default class CardNewPhysicalFlowSetup extends CardNewAbstractFlowSetup {
  @service abilities;
  @service cardsManager;
  @service homePage;
  @service intl;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;
  @service toastFlashMessages;

  constructor(owner) {
    super();
    setOwner(this, owner);

    let { defaultAccount } = this.organization;

    let { inviteeMembershipId, origin } = this.router.currentRoute.queryParams;

    let card = this.store.createRecord('card', {
      bankAccount: defaultAccount,
      organization: this.organization,
    });

    if (inviteeMembershipId) {
      card.holder = this.store.peekRecord('membership', inviteeMembershipId);
    }

    card.addIdempotencyKey();

    this.dataContext = new CardNewPhysicalDataContext({
      card,
      kycSubmitted: this.organizationManager.membership.kycSubmitted,
      origin: origin || ORIGIN.CREATION_REGULAR,
    });
  }

  get showMetalCardDetailsModal() {
    return Boolean(this.router.currentRoute.queryParams['show-details']);
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('read card')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (this.abilities.cannot('access creation flow card')) {
      return this.router.replaceWith('cards');
    }

    try {
      let { card } = this.dataContext;
      let { estimates, hasOneCardsLeft, isEstimateLoaded } =
        await this.cardsManager.fetchCardsCostsTask.perform(this.organization.id);

      let { defaultAccount } = this.organization;

      if (card?.holder?.id) {
        this.dataContext.cardsLimits = await this.cardsManager.fetchCardsMaxLimitsTask.perform(
          card.holder.id
        );
      } else {
        this.dataContext.cardsLimits = await this.cardsManager.fetchCardsMaxLimitsTask.perform();
      }
      this.dataContext.cardOptionsPrices = await this.cardsManager.fetchCardOptionsPricesTask
        .perform()
        .catch(() => []);
      this.dataContext.estimates = estimates;
      this.dataContext.hasMultipleActiveAccounts = this.organization.hasMultipleActiveAccounts;
      this.dataContext.hasOneCardsLeft = hasOneCardsLeft;
      this.dataContext.isEstimateLoaded = isEstimateLoaded;
      this.dataContext.mainAccount = defaultAccount;
      this.dataContext.organization = this.organization;
      this.dataContext.shouldAnimateCards = false;
      this.dataContext.showCardCustomization = false;
      this.dataContext.showMetalCardDetailsModal = this.showMetalCardDetailsModal;
      this.dataContext.flowTrackingOrigin = TRACKING_ORIGIN[this.dataContext.origin];
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this._cleanupCard();

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return this.router.transitionTo('cards.physical-or-virtual');
    }
  }

  @action
  onComplete({ card, cardId, origin }) {
    if (origin === ORIGIN.MEMBER_INVITATION) {
      return this.router.replaceWith('members.active');
    }

    this.router.transitionTo('cards.index', { queryParams: { highlight: card.id || cardId } });
  }

  _cleanupCard() {
    let { card } = this.dataContext;
    if (card.isNew) {
      card.unloadRecord();
    }

    this.cardsManager.fetchCardsMaxLimitsTask.cancelAll();
  }
}
