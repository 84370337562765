import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { TrackedArray } from 'tracked-built-ins';

import { apiBaseURL } from 'qonto/constants/hosts';
import { isSoloEntity } from 'qonto/constants/legal-entities';
import { isNoBackRoute } from 'qonto/utils/route/is-no-back-route';

const MAX_SIZE = 30 * 1e6; // 30 MB
const UPLOAD_URL = `${apiBaseURL}/v1/documents`;
const BYLAW_DOC_TYPE = 'bylaw';

export default class UploadPeriodicUpdateDocumentsController extends Controller {
  @service segment;
  @service intl;
  @service store;
  @service organizationManager;
  @service subscriptionManager;
  @service router;
  @service networkManager;
  @service sentry;
  @service toastFlashMessages;
  @service modals;

  uploadUrl = UPLOAD_URL;
  maxFileSize = MAX_SIZE;
  uploadOptions = { callEndpoint: false };

  @tracked
  financialStatementFiles = new TrackedArray();

  @tracked
  bylawsFiles = new TrackedArray();

  financialStatementErrors = new TrackedArray();
  bylawsErrors = new TrackedArray();

  financialStatementUploadFile;
  bylawsUploadFile;

  get isSoloEntity() {
    return isSoloEntity(this.organizationManager.organization.legalForm);
  }

  get shouldUploadBylaws() {
    return Boolean(
      this.model.documentCollectionProcess?.requiredDocuments.find(
        rd => rd.docType === BYLAW_DOC_TYPE
      )
    );
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      file
        .readAsDataURL()
        .then(url => {
          resolve(url);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  @action
  handleCloseRedirections() {
    if (isNoBackRoute(this.model.fromRouteName)) {
      this.router.transitionTo('overview.index');
    } else {
      window.history.back();
    }
  }

  @action
  handleFinancialStatementUploaded(file, document) {
    this.financialStatementErrors.length = 0;
    this.financialStatementFiles.push(document);
    this.financialStatementUploadFile = file;
  }

  @action
  handleBylawsUploaded(file, document) {
    this.bylawsErrors.length = 0;
    this.bylawsFiles.push(document);
    this.bylawsUploadFile = file;
  }

  @action
  handleRemoveFinancialStatementFile() {
    this.financialStatementFiles.length = 0;
    this.financialStatementUploadFile = null;
  }

  @action
  handleRemoveBylawsFile() {
    this.bylawsFiles.length = 0;
    this.bylawsUploadFile = null;
  }

  validateInputs() {
    let missingFinancialStatementMessage = this.intl.t(
      this.isSoloEntity ? 'errors.missing_income_statement' : 'errors.missing_financial_statement'
    );
    let missingBylawsMessage = this.intl.t('errors.missing_bylaws');

    if (this.financialStatementFiles.length === 0) {
      this.financialStatementErrors.length = 0;
      this.financialStatementErrors.push({ message: missingFinancialStatementMessage });
    }

    if (this.shouldUploadBylaws && this.bylawsFiles.length === 0) {
      this.bylawsErrors.length = 0;
      this.bylawsErrors.push({ message: missingBylawsMessage });
    }
  }

  async uploadDocument({ docType, subjectId, subjectType, requiredDocumentId, file, fileBase64 }) {
    return await this.networkManager.request(UPLOAD_URL, {
      method: 'POST',
      data: {
        document: {
          doc_type: docType,
          subject_id: subjectId,
          subject_type: subjectType,
          required_document_id: requiredDocumentId,
          files: [
            {
              file_name: file.name,
              file_url: fileBase64,
              file_content_type: file.type,
              file_size: file.size,
            },
          ],
        },
      },
    });
  }

  handleCloseTask = dropTask(async () => {
    if (this.financialStatementUploadFile || (this.shouldUploadBylaws && this.bylawsUploadFile)) {
      let result = await this.modals.open('popup/destructive', {
        title: this.intl.t('transfers.exit-flow.title'),
        description: this.intl.t('transfers.exit-flow.subtitle'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('transfers.exit-flow.confirm'),
      });

      if (result !== 'confirm') {
        return;
      }
    }

    this.handleCloseRedirections();
  });

  handleSubmitTask = dropTask(
    waitFor(async () => {
      this.validateInputs();

      if (this.financialStatementErrors.length > 0 || this.bylawsErrors.length > 0) {
        return;
      }

      try {
        let financialStatementBase64 = await this.getBase64(this.financialStatementUploadFile);
        let financialStatementRequiredDocument =
          this.model.documentCollectionProcess.requiredDocuments.find(
            rd => rd.docType === 'financial_statement'
          );

        let bylawsBase64, bylawsRequiredDocument;

        if (this.shouldUploadBylaws) {
          bylawsBase64 = await this.getBase64(this.bylawsUploadFile);
          bylawsRequiredDocument = this.model.documentCollectionProcess.requiredDocuments.find(
            rd => rd.docType === BYLAW_DOC_TYPE
          );
        }

        let financialStatementPromise = this.uploadDocument({
          docType: 'financial_statement',
          subjectId: this.organizationManager.organization.id,
          subjectType: 'Organization',
          requiredDocumentId: financialStatementRequiredDocument.id,
          file: this.financialStatementUploadFile,
          fileBase64: financialStatementBase64,
        });

        let financialStatementPayload, bylawsPayload;

        if (this.shouldUploadBylaws) {
          let bylawsPromise = this.uploadDocument({
            docType: BYLAW_DOC_TYPE,
            subjectId: this.organizationManager.organization.id,
            subjectType: 'Organization',
            requiredDocumentId: bylawsRequiredDocument.id,
            file: this.bylawsUploadFile,
            fileBase64: bylawsBase64,
          });

          [financialStatementPayload, bylawsPayload] = await Promise.all([
            financialStatementPromise,
            bylawsPromise,
          ]);
        } else {
          financialStatementPayload = await financialStatementPromise;
        }

        this.store.pushPayload('document', financialStatementPayload);
        if (bylawsPayload) {
          this.store.pushPayload('document', bylawsPayload);
        }

        this.segment.track('overview_documents_uploaded');
        this.toastFlashMessages.toastSuccess(
          this.intl.t('organizations.profile.company-profile.toast-sent-for-review')
        );
        this.handleCloseRedirections();
      } catch (error) {
        this.toastFlashMessages.toastError(this.intl.t('errors.server-error'));
        this.sentry.captureException(error);
      }
    })
  );
}
