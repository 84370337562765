import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import window from 'ember-window-mock';

import { LOCALIZED_TYPEFORM_URLS } from 'qonto/constants/direct-debit-collections';
import { getEmptyStateConfig } from 'qonto/constants/empty-states/direct-debit-collections';
import { TRACKING_ORIGINS } from 'qonto/constants/empty-states/system';

export default class DirectDebitCollectionsController extends Controller {
  @service organizationManager;
  @service subscriptionManager;
  @service segment;
  @service intl;
  @service flowLinkManager;
  @service emptyStates;
  @service abilities;
  @service directDebitCollectionsManager;

  @tracked isSideDrawerRendered = Boolean(sessionStorage.getItem('bucketSlug'));
  @tracked isSideDrawerVisible = Boolean(sessionStorage.getItem('bucketSlug'));

  sideDrawerId = 'direct-debit-collections';
  TRACKING_ORIGINS = TRACKING_ORIGINS;

  @action
  onInterestedClick() {
    this.emptyStates.trackCta(this.emptyStateOptions, TRACKING_ORIGINS.PRIMARY);

    window.open(LOCALIZED_TYPEFORM_URLS[this.intl.locale], '_blank', 'noreferrer');
  }

  @action
  onClickSetupCollection(origin) {
    this.directDebitCollectionsManager.handleRedirectionOnSetupCollection({
      redirectRoute: 'direct-debit-collections.index',
    });
    this.emptyStates.trackCta(this.emptyStateOptions, origin);
  }

  @action
  onClickActivation(origin) {
    this.flowLinkManager.transitionTo({
      name: 'sdd-activation',
      stepId: 'intro',
    });

    this.emptyStates.trackCta(this.emptyStateOptions, origin);
  }

  @action
  onEbicsClick() {
    this.displaySideDrawer();

    this.emptyStates.trackCta(this.emptyStateOptions, TRACKING_ORIGINS.SECONDARY);
  }

  @action
  hideSideDrawer() {
    this.isSideDrawerVisible = false;
    sessionStorage.removeItem('bucketSlug');
    this.segment.track('ICI_sidedrawer_closed', { page: this.sideDrawerId });
  }

  @action
  displaySideDrawer() {
    this.isSideDrawerRendered = true;
    setTimeout(() => {
      this.isSideDrawerVisible = true;
      this.segment.track('ICI_trigger_clicked', { page: this.sideDrawerId });
    }, 0);
  }

  #track(eventName) {
    let { membership, organization } = this.organizationManager;

    this.segment.track(eventName, {
      price_plan: this.subscriptionManager.currentPricePlan.code,
      role: membership.role,
      country: organization.legalCountry,
      org_id: organization.id,
    });
  }

  get canViewEbicsCta() {
    return this.abilities.can('access ebics directDebitCollection');
  }

  get emptyStateOptions() {
    let { isEligible, isActivated } = this.model;

    if (isActivated) {
      return this.emptyStates.getEmptyStateOptions({
        isOrgEligibleForFeature: isActivated,
        isEmptyGlobally: true,
        isEmptyLocally: true,
        hasActiveFilterOrSearch: false,
        config: getEmptyStateConfig(this.intl, {
          ctaCallback: this.onClickSetupCollection,
          secondaryCtaCallback: this.onEbicsClick,
          isActivated,
          canViewEbicsCta: this.canViewEbicsCta,
        }),
        isQuotaLimited: false,
      });
    }

    //options for both not_eligile and eligible empty states
    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: isEligible,
      isEmptyGlobally: true,
      isEmptyLocally: true,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl, {
        ctaCallback: isEligible ? this.onClickActivation : this.onInterestedClick,
      }),
      isQuotaLimited: false,
    });
  }
}
