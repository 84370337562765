import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { dropTask, task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class AccountsStatementsController extends Controller {
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service modals;
  @service segment;
  @service sentry;

  queryParams = ['page', 'per_page', { bankAccounts: 'bank-accounts' }];

  @tracked page = 1;
  @tracked per_page = 25;
  @tracked bankAccounts = '';

  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  get localState() {
    if (this.model.statementsTask.isRunning) {
      return {
        empty: false,
        error: false,
        isLoading: true,
      };
    }
    return {
      empty: this.allStatements?.length === 0,
      error: this.model.statementsTask.last.isError,
      isLoading: false,
    };
  }

  get emptyStateOptions() {
    if (this.model.statementsTask.isRunning || this.model.statementsTask.last.isError) {
      return false;
    }
    if (this.allStatements?.length === 0 && !this.bankAccounts) {
      return {
        title: this.intl.t('empty-states.search.statements.title'),
        subtitle: this.intl.t('empty-states.search.statements.subtitle'),
        illuSrc: '/illustrations/statements/no-statements.svg',
      };
    }
  }

  get activeAccounts() {
    return this.organizationManager.organization.activeAccounts;
  }

  get statements() {
    return this.model.statementsTask.last?.value || [];
  }

  get allStatements() {
    let statements = [...this.statements];
    let isFirstDayOfMonth = dayjs().isSame(dayjs().startOf('month'), 'day');
    if (isFirstDayOfMonth && this.page === 1) {
      // add a placeholder if the statement is not generated for the last month
      let selectedAccounts = this.bankAccounts
        ? this.activeAccounts.filter(({ id }) => this.bankAccounts.split(',').includes(id))
        : this.activeAccounts;

      selectedAccounts.forEach(account => {
        let hasLastMonthStatement = statements.some(({ year, month }) => {
          let statementDate = new Date(year, month, 1);
          let lastMonth = dayjs().subtract(1, 'month').toDate();
          let isSameMonth = dayjs(statementDate).isSame(lastMonth, 'month');
          return isSameMonth;
        });

        if (!hasLastMonthStatement) {
          let date = dateToken({
            date: new Date(),
            locale: this.intl.locale,
            token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
          });

          let upcomingStatement = {
            name: `${date} - ${account.name}`,
            bankAccount: { name: account.name },
          };

          statements.unshift(upcomingStatement);
        }
      });
    }

    return statements;
  }

  fetchHistoricalStatementsTask = task(async () => {
    try {
      let { organization } = this.organizationManager;
      return await organization.getOldStatements({ per_page: 60 });
    } catch (error) {
      if (error.status === 422) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  openHistoricalStatementsModalTask = dropTask(async () => {
    this.segment.track('account_statement_view_historical_data_button_clicked');
    await this.modals.open('account-statements-popup', {
      title: this.intl.t('statements.archived.modal.title'),
      loadStatementsTask: this.fetchHistoricalStatementsTask,
    });
  });

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }
}
