/* eslint-disable @qonto/no-redirect-in-unrelated-hooks, require-await */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { task } from 'ember-concurrency';

import { SOLUTION_INSTANCE_EVENTS } from 'qonto/constants/listeners';
import SOLUTION_INSTANCE_STATUS from 'qonto/constants/solution-instance';

export default class ConnectHubHubApplicationSetupRoute extends Route {
  @service menu;
  @service notifierManager;
  @service organizationManager;
  @service router;
  @service store;
  @service webviewManager;

  detailsRouteName = 'settings.connect-hub.applications.hub-application.details';
  hubApplicationRouteName = 'settings.connect-hub.applications.hub-application';

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  resetController(controller) {
    controller.closePopup();
  }

  async _handleSolutionCreated({ solution_instance_id: id }) {
    let solutionInstance = this._getSolutionInstanceById(id);
    if (solutionInstance) {
      solutionInstance.status = SOLUTION_INSTANCE_STATUS.CREATED;
      await solutionInstance.getWizardURL();
    }
  }

  _handleSolutionEnabled({ solution_instance_id: id }) {
    let solutionInstance = this._getSolutionInstanceById(id);
    if (solutionInstance) {
      solutionInstance.status = SOLUTION_INSTANCE_STATUS.ENABLED;
    }
  }

  async _handleSolutionFailed({ solution_instance_id: id }) {
    let solutionInstance = this._getSolutionInstanceById(id);
    solutionInstance.status = SOLUTION_INSTANCE_STATUS.FAILED;
  }

  _setListeners() {
    this.notifierManager.on(SOLUTION_INSTANCE_EVENTS.CREATED, this, '_handleSolutionCreated');
    this.notifierManager.on(SOLUTION_INSTANCE_EVENTS.ENABLED, this, '_handleSolutionEnabled');
    this.notifierManager.on(SOLUTION_INSTANCE_EVENTS.FAILED, this, '_handleSolutionFailed');
  }

  _removeListeners() {
    this.notifierManager.off(SOLUTION_INSTANCE_EVENTS.CREATED, this, '_handleSolutionCreated');
    this.notifierManager.off(SOLUTION_INSTANCE_EVENTS.ENABLED, this, '_handleSolutionEnabled');
    this.notifierManager.off(SOLUTION_INSTANCE_EVENTS.FAILED, this, '_handleSolutionFailed');
  }

  _getSolutionInstanceById(solutionInstanceId) {
    return this.store.peekRecord('solution-instance', solutionInstanceId);
  }

  beforeModel(params) {
    this.fromTransition = params.from;
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('fromTransition', this.fromTransition);
  }

  async model() {
    let { applicationSlug, organization, prismicData } = this.modelFor(
      this.hubApplicationRouteName
    );
    let solutionId = prismicData.tray_solution_id;

    if (!solutionId) {
      return this.router.replaceWith(this.detailsRouteName);
    }

    this._setListeners();

    let solutionInstance = await this.initSolutionTask.perform(solutionId);

    if (
      this.webviewManager.isWebview &&
      solutionInstance.status === SOLUTION_INSTANCE_STATUS.ENABLED
    ) {
      this.webviewManager._callBridgeMethod('onConnectionSuccess');
    }

    return {
      applicationSlug,
      initSolutionTask: this.initSolutionTask,
      prismicData,
      organization,
      solutionInstance,
    };
  }

  deactivate() {
    this._removeListeners();
    this.menu.show();
  }

  initSolutionTask = task(async solutionId => {
    let organization = this.organizationManager.organization;
    let solutionInstances = await this.store.query('solution-instance', {
      organization_id: organization.id,
      solution_id: solutionId,
    });
    let solutionInstance = solutionInstances?.[0];

    if (!solutionInstance) {
      solutionInstance = this.store.createRecord('solution-instance', {
        solutionId,
        organization,
      });
      await solutionInstance.save();
    }
    return solutionInstance;
  });
}
