import Controller from '@ember/controller';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';

import { reads } from 'macro-decorators';

export default class MandatesLandingController extends Controller {
  queryParams = [{ bankAccounts: 'bank-accounts' }];
  bankAccounts = '';

  @service intl;
  @service organizationManager;
  @service zendeskLocalization;

  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  get selectedBankAccount() {
    let { accounts } = this.organizationManager;
    return (
      accounts.find(({ id }) => id === this.bankAccounts) || this.organizationManager.currentAccount
    );
  }

  get emptyStateSubtitle() {
    let learnMoreLink = `<a href=${this.intl.t('mandates.landing.faq_link', {
      faqUrl: this.zendeskLocalization.getLocalizedArticle(4359570),
    })} target="_blank" rel="noopener noreferrer" class="body-link" >${this.intl.t(
      'empty-states.mandates.subtitle-link.hc-article'
    )}</a>`;
    return htmlSafe(`${this.intl.t('empty-states.mandates.subtitle')} ${learnMoreLink}`);
  }

  get emptyStateOptions() {
    let accountIsClosed = this.selectedBankAccount?.isClosed;
    let title = accountIsClosed
      ? this.intl.t('empty-states.mandates.closed-account.title')
      : this.intl.t('empty-states.mandates.title');
    let subtitle = accountIsClosed
      ? this.intl.t('empty-states.mandates.closed-account.subtitle')
      : this.emptyStateSubtitle;
    return {
      title,
      subtitle,
      lottieSrc: '/lotties/empty-state/no-sdd.json',
    };
  }
}
