import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import CURRENCIES from 'qonto/constants/currencies';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import emberize from 'qonto/utils/emberize';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
} from 'qonto/utils/transfers';

class FxTransferFlowDataContext {
  beneficiary = null;
  fxTransfer = null;
  origin = null;
  supplierInvoiceId = null;
  confirmationResult = null;
  isMarketClosed = false;
  closedMarketReason = null;
  paymentPurposeCodes = null;
  minFxAmount = null;
  budgetName = null;

  constructor({ beneficiary = null, fxTransfer, origin, supplierInvoiceId, kycSubmitted }) {
    this.beneficiary = beneficiary;
    this.fxTransfer = fxTransfer;
    this.origin = origin;
    this.supplierInvoiceId = supplierInvoiceId;
    this.kycSubmitted = kycSubmitted;
  }
}

export default class FxTransferFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service store;

  dataContext = null;

  constructor(_owner, previousDataContext = {}) {
    super(...arguments);

    let { organization } = this.organizationManager;
    let { mainAccount } = organization;
    let { origin, supplierInvoiceId } = this.router.currentRoute.queryParams;
    let { beneficiary, fxTransfer } = previousDataContext;

    let fxTransferRecord =
      fxTransfer ||
      this.store.createRecord('transfer', {
        bankAccount: mainAccount,
        organization,
        localAmountCurrency: this.router.currentRoute.queryParams.currency || CURRENCIES.defaultFX,
        fx: true,
        operationType: 'fx_scheduled',
        activityTag: 'other_expense',
      });

    fxTransferRecord.addIdempotencyKey();

    this.dataContext = new FxTransferFlowDataContext({
      ...(beneficiary && { beneficiary }),
      fxTransfer: fxTransferRecord,
      origin: origin || previousDataContext.origin,
      supplierInvoiceId: supplierInvoiceId || previousDataContext.supplierInvoiceId,
      kycSubmitted: this.organizationManager.membership.kycSubmitted,
    });

    if (this.dataContext.origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES) {
      this.prefillSupplierInvoiceOptionsTask
        .perform(this.dataContext.fxTransfer, this.dataContext.supplierInvoiceId)
        .catch(ignoreCancelation);
    }

    if (this.dataContext.beneficiary) {
      this._setTransferBeneficiary(this.dataContext.beneficiary);
    }
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    if (this.abilities.cannot('create transfer')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (this.organizationManager.organization.hasInternationalOutFeature) {
      return this.router.replaceWith('transfers.landing');
    }

    let market = await this.store.adapterFor('transfer').fxMarket();
    this.dataContext.fxTransfer.set('market', emberize(market));
  }

  beforeRestoreTask = dropTask(async ({ fxTransfer }) => {
    let { id: fxTransferId } = fxTransfer;
    let promises = [];

    if (fxTransferId) {
      promises.push(this.store.findRecord('transfer', fxTransferId));
    }

    let { attachments, organization, beneficiary } = fxTransfer.relationships;

    if (attachments) {
      promises.push(
        this.store.query('attachment', {
          organization_id: organization.id,
          filters: { ids: attachments.map(({ id }) => id) },
          per_page: 500,
        })
      );
    }

    if (beneficiary) {
      promises.push(this.store.findRecord('beneficiary', beneficiary.id));
    }

    await Promise.all(promises);
  });

  @action
  onComplete(dataContext) {
    let { origin, supplierInvoiceId, fxTransfer, isMarketClosed, closedMarketReason } = dataContext;

    if (isMarketClosed) {
      this.segment.track('transfer_creation_exited', {
        market_closure_reason: closedMarketReason,
        ...(origin && { origin }),
      });
    } else {
      this.segment.track('transfer-swift_success_closed', {
        transfer_success_redirection: 'exit',
        ...(origin && { origin }),
      });
    }

    return this._redirect({
      url: isMarketClosed ? 'transfers.landing' : 'transfers.pending',
      origin,
      fxTransfer,
      supplierInvoiceId,
    });
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    let { origin, supplierInvoiceId, fxTransfer } = this.dataContext;

    if (stepId !== 'beneficiaries') {
      let result = await this.openAbortModalTask.perform();

      if (result !== 'confirm') {
        return false;
      }
    }

    this.segment.track('transfer_creation_exited', {
      ...(origin && { origin }),
    });

    this._redirect({ url: 'transfers.landing', origin, fxTransfer, supplierInvoiceId });

    return true;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.exit-flow.title'),
      description: this.intl.t('transfers.exit-flow.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('transfers.exit-flow.confirm'),
    });
  });

  prefillSupplierInvoiceOptionsTask = dropTask(async (fxTransfer, supplierInvoiceId) => {
    let supplierInvoice = await this.store.findRecord('supplier-invoice', supplierInvoiceId);

    if (supplierInvoice) {
      let attachmentId = supplierInvoice.belongsTo('attachment').id();
      let attachment = await this.store.findRecord('attachment', attachmentId);

      let transferOptions = {
        attachments: attachment ? [attachment] : undefined,
      };

      fxTransfer.setProperties(transferOptions);
    }
  });

  _redirect({ url, origin, fxTransfer, supplierInvoiceId, options }) {
    if (origin && supplierInvoiceId) {
      return this.router.transitionTo(origin, {
        queryParams: {
          transferStatus: fxTransfer.status,
          supplierInvoiceId,
        },
      });
    } else {
      return options ? this.router.transitionTo(url, options) : this.router.transitionTo(url);
    }
  }

  _resetTransferDetails(transfer) {
    transfer.setProperties({
      localAmount: 0,
      reference: null,
      fxPaymentPurpose: null,
      notifyByEmail: false,
      email: null,
    });
  }

  _setTransferBeneficiary(beneficiary) {
    let { fxTransfer } = this.dataContext;

    let currentBeneficiaryId = fxTransfer.get('beneficiary.id');
    if (beneficiary.id !== currentBeneficiaryId) this._resetTransferDetails(fxTransfer);

    fxTransfer.set('beneficiary', beneficiary);

    copyBeneficiaryIntoTransfer(fxTransfer, beneficiary, {
      forceCopy: true,
    });
    copyBeneficiaryLabelsIntoTransfer(fxTransfer, beneficiary);
  }
}
