export default {
  "container": "qfi",
  "form-container": "qfA",
  "preview-container": "qfY",
  "pdf-preview-container": "qfh",
  "content": "qff",
  "title": "qfK",
  "tabs-container": "qfG",
  "close-button": "qfr btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qfb",
  "header": "qfy",
  "without-tabs": "qfU"
};
