import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, remindersConfigurationNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export function emailTemplateForOperator(emailTemplates, operator) {
  let template = (emailTemplates || []).find(
    ({ operator: templateOperator }) => templateOperator === operator
  );
  if (template) {
    let { emailTitle, emailBody } = template;
    return { emailTitle, emailBody };
  }
}

export default class ClientRemindersConfigurationRoute extends Route {
  @service abilities;
  @service router;
  @service sentry;
  @service store;
  @service networkManager;
  @service intl;
  @service toastFlashMessages;
  @service organizationManager;

  beforeModel() {
    super.beforeModel(...arguments);

    let isFFActive = variation('feature--boolean-automated-reminders');
    let canWrite = this.abilities.can('write reminders-configuration');

    if (!isFFActive || !canWrite) {
      this.router.transitionTo('clients');
    }
  }

  model(params, transition) {
    let client = this.modelFor('clients.client').client;

    this.fetchRemindersConfigurationTask
      .perform(client)
      .catch(ignoreCancelation)
      .catch(error => this.error(error, transition));

    return {
      client,
      fetchRemindersConfigurationTask: this.fetchRemindersConfigurationTask,
      fetchEmailTemplatesTask: this.fetchEmailTemplatesTask,
    };
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);
    controller.displayErrors = false;
    controller.focusedRuleIndex = 0;
    controller.collapsedRules = [];

    if (['clients.index'].includes(transition.from?.name)) {
      controller.origin = transition.from;
    } else {
      controller.origin = {
        name: 'clients.index',
        queryParams: {},
        paramNames: [],
      };
    }
  }

  fetchRemindersConfigurationTask = dropTask(async client => {
    let { locale: clientLocale } = client;
    let emailTemplates = await this.fetchEmailTemplatesTask.perform(clientLocale);

    let model = await this.store.queryRecord('reminders-configuration', {
      'filter[client_id]': client.id,
    });
    if (!model) {
      model = this.store.createRecord('reminders-configuration', {
        client,
        sendTo: client.email,
        organization: this.organizationManager.organization,
      });

      let rule = this.store.createRecord('reminders-configuration/rule', {
        remindersConfiguration: model,
      });
      let template = emailTemplateForOperator(emailTemplates, rule.operator);
      if (template) {
        Object.assign(rule, template);
      }
    }
    let initialState = model.serialize();
    return { model, initialState };
  });

  fetchEmailTemplatesTask = dropTask(async language => {
    try {
      let res = await this.networkManager.request(
        `${apiBaseURL}/${remindersConfigurationNamespace}/receivable_invoices/reminders/email_templates?locale=${language}`,
        { method: 'GET' }
      );

      return this.formatEmailTemplates(res.templates);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      return [];
    }
  });

  formatEmailTemplates(arr) {
    return arr.map(item => {
      return {
        ...item,
        emailTitle: item.email_title,
        emailBody: item.email_body,
      };
    });
  }

  @action
  error(error, transition) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('client-list.toasts.error.related-reminders'));
    let origin = transition.from || { name: 'clients.index', queryParams: {} };
    this.router.replaceWith(origin.name, { queryParams: origin.queryParams });
  }
}
