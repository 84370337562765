import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class MandatesRoute extends Route {
  @service store;
  @service organizationManager;
  @service sentry;

  async model() {
    if (variation('feature--boolean-empty-state-revamp')) {
      await this.fetchAllMandatesTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => {
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        });
    }

    return {
      fetchAllMandatesTask: this.fetchAllMandatesTask,
    };
  }

  // fetch all mandates without filters
  fetchAllMandatesTask = restartableTask(async () => {
    return await this.store.query('mandate', {
      organization_id: this.organizationManager.organization.id,
      per_page: 1,
    });
  });

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');
  }
}
