import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { all, dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import { labelOverTimeAggregations, monthlyAggregation } from 'qonto/constants/cash-flow';
import { TablesLayout } from 'qonto/react/components/cash-flow/components/tables-layout';
import { ErrorInfo } from 'qonto/utils/error-info';
import buildQuery from 'qonto/utils/statistics';

const MAX_PERIOD_LENGTH = 12;
const DISPLAYED_MONTHS = 6;

export default class CashFlowIndexController extends Controller {
  tablesLayout = TablesLayout;
  @service store;
  @service organizationManager;
  @service sentry;

  @reads('organizationManager.organization.id') organizationId;

  @tracked indexOffset = 0;
  @tracked displayedMonths = DISPLAYED_MONTHS;
  @tracked chartStatistics = [];
  @tracked chartComparisonStatistics = [];

  get adapter() {
    return this.store.adapterFor('transaction');
  }

  get isChartLoading() {
    return this.getStatisticsTask.isRunning;
  }

  get isChartError() {
    return this.getStatisticsTask.last?.isError;
  }

  get isTableLoading() {
    return (
      this.getLabelCashflowStatisticsTask.isRunning ||
      this.getUnlabeledCashflowStatisticsTask.isRunning
    );
  }

  get isTableError() {
    return (
      this.getLabelCashflowStatisticsTask.last?.isError ||
      this.getUnlabeledCashflowStatisticsTask.last?.isError
    );
  }

  get isLoading() {
    return this.isChartLoading || this.isTableLoading;
  }

  get isError() {
    return this.isChartError || this.isTableError;
  }

  get labelStatistics() {
    return this.getLabelCashflowStatisticsTask.last?.value;
  }

  get balanceStatistics() {
    return this.chartStatistics.length === 3
      ? this.chartStatistics[2]
      : { data: { display_at_monthly: [] } };
  }

  get unlabeledStatistics() {
    return this.getUnlabeledCashflowStatisticsTask.last?.value;
  }

  @action onNextMonth() {
    if (this.indexOffset >= MAX_PERIOD_LENGTH - this.displayedMonths) return;

    this.indexOffset = this.indexOffset + 1;
  }

  @action onPreviousMonth() {
    if (this.indexOffset === 0) return;

    this.indexOffset = this.indexOffset - 1;
  }

  @action onResetOffset() {
    this.indexOffset = 0;
  }

  @action onUndefinedValues(data) {
    this.sentry.captureException(
      new Error('[Cash Flow] Some labels were undefined', {
        cause: 'undefined label values',
        data,
      })
    );
  }

  queryStatisticsTask = dropTask(
    async (aggregations, basePeriod, bankAccounts, comparisonPeriod) => {
      try {
        await this.getStatisticsTask
          .linked()
          .perform(aggregations, basePeriod, bankAccounts, comparisonPeriod);

        let currentDateIndex = this.balanceStatistics.data.display_at_monthly.findIndex(
          ({ start_date, end_date }) => {
            return dayjs().isBetween(dayjs(start_date), dayjs(end_date), 'day', '[]');
          }
        );

        if (currentDateIndex >= DISPLAYED_MONTHS - 1) {
          this.indexOffset = currentDateIndex - (DISPLAYED_MONTHS - 1);
        }

        await this.getLabelCashflowStatisticsTask.linked().perform(basePeriod, bankAccounts);
        await this.getUnlabeledCashflowStatisticsTask.linked().perform(basePeriod, bankAccounts);
      } catch (error) {
        this.captureError(error);
      }
    }
  );

  getStatisticsTask = dropTask(async (aggregations, basePeriod, bankAccounts, comparisonPeriod) => {
    let isPeriod = period => period?.interval || period?.startDate || period?.endDate;

    let getQuery = (period, side) =>
      buildQuery(this.organizationId, aggregations, period, side, bankAccounts);

    let getStatistics = period => [
      this.adapter.getCashflow(getQuery(period, 'credit')),
      this.adapter.getCashflow(getQuery(period, 'debit')),
      this.adapter.getBalance(getQuery(period)),
    ];

    this.chartStatistics = await all(getStatistics(basePeriod));

    if (isPeriod(comparisonPeriod)) {
      this.chartComparisonStatistics = await all(getStatistics(comparisonPeriod));
    }
  });

  getLabelCashflowStatisticsTask = dropTask(async (period, bankAccounts) => {
    try {
      let getLabelsQuery = side =>
        this.adapter.getCashflow(
          buildQuery(this.organizationId, labelOverTimeAggregations, period, side, bankAccounts)
        );

      return await all([getLabelsQuery('credit'), getLabelsQuery('debit')]);
    } catch (error) {
      this.captureError(error);
    }
  });

  getUnlabeledCashflowStatisticsTask = dropTask(async (period, bankAccounts) => {
    try {
      let getUnlabeledQuery = side =>
        this.adapter.getCashflow(
          buildQuery(this.organizationId, monthlyAggregation, period, side, bankAccounts, {
            property: 'label_list_ids',
            operator: 'not_in',
            values: this.organizationManager.organization.labelLists.map(list => list.id),
          })
        );

      return await all([getUnlabeledQuery('credit'), getUnlabeledQuery('debit')]);
    } catch (error) {
      this.captureError(error);
    }
  });

  captureError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(errorInfo.error);
    }
  }

  reload() {
    window.location.reload();
  }
}
