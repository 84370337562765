import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { LottiePlayer } from 'design-system-kit';

export default class SettingsPricePlanDetailsController extends Controller {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service router;

  get currentMonthYear() {
    return dateToken({
      date: new Date(),
      locale: this.intl.locale,
      token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
    });
  }

  @action
  reloadSubscription() {
    this.router.refresh('settings.price-plan.details');
  }
}
