export default {
  "wrapper": "qnU",
  "header": "qnj",
  "main": "qnW",
  "frequency": "qnu body-1",
  "radiogroup": "qiS",
  "divider": "qic",
  "option-wrapper": "qiq",
  "option-details-wrapper": "qiZ",
  "option-label": "qiR",
  "option-name": "qiQ",
  "option-details-text": "qie",
  "spinner-wrapper": "qiB",
  "spinner": "qiE"
};
